<template>
    <div class="select-bubble" v-if="isTalentPool">
        <div style="margin-left:21px;">
            <span class="title">
                最近接触的人：
            </span>
            <Bubble
                v-for="item in bubbleParam[tableType].recentlyContactedPerson"
                :count="item.count"
                group="Label"
                :isSelect="item.isSelect"
                :hashCode="item.hashCode"
                :disable="item.count <= 0"
                @click="handleBubbleClick"
                :enableTooltip="item.enableTooltip"
                :key="item.code"
                :adaptiveSize="true"
            >
                {{ item.label }}
                <span v-if="item.enableTooltip" slot="tooltipContent">
                    {{ item.toolTip}}
                </span>
            </Bubble>
        </div>

        <div class="bubble-margin-top">
            <div class="inline-block" style="margin-left:21px;">
                <span class="title">
                    待处理候选人：
                </span>
                <Bubble
                    v-for="item in bubbleParam[tableType].pendingCandidate"
                    :count="item.count"
                    group="Label"
                    :isSelect="item.isSelect"
                    :hashCode="item.hashCode"
                    :disable="item.count <= 0"
                    @click="handleBubbleClick"
                    type="red"
                    :enableTooltip="item.enableTooltip"
                    :key="item.code"
                    :adaptiveSize="true"
                >
                    {{ item.label }}
                    <span v-if="item.enableTooltip" slot="tooltipContent">
                        {{ item.toolTip}}
                    </span>
                </Bubble>
            </div>
            <div class="inline-block" style="margin-left:21px;">
                <span class="title">
                    有求职意向的：
                </span>
                <Bubble
                    v-for="item in bubbleParam[tableType].jobSearchIntention"
                    :count="item.count"
                    group="Label"
                    :isSelect="item.isSelect"
                    :hashCode="item.hashCode"
                    :disable="item.count <= 0"
                    @click="handleBubbleClick"
                    type="blue"
                    :enableTooltip="item.enableTooltip"
                    :key="item.code"
                    :adaptiveSize="true"
                >
                    {{ item.label }}
                    <span v-if="item.enableTooltip" slot="tooltipContent">
                        {{ item.toolTip}}
                    </span>
                </Bubble>
            </div>
        </div>
    </div>
    <div class="select-bubble" v-else>
        <!-- <div class="flex-box"> </div>-->
            <div class="inline-block" style="margin-left:21px;">
                <span class="title">
                    我的接触：
                </span>

                <Bubble
                    v-for="item in bubbleParam[tableType].myContact"
                    :count="item.count"
                    group="Label"
                    :isSelect="item.isSelect"
                    :hashCode="item.hashCode"
                    :disable="item.count <= 0"
                    @click="handleBubbleClick"
                    :enableTooltip="item.enableTooltip"
                    :key="item.code"
                    :adaptiveSize="true"
                >
                    {{ item.label }}
                    <span v-if="item.enableTooltip" slot="tooltipContent">
                        {{ item.toolTip}}
                    </span>
                </Bubble>
            </div>
            
             <!-- class="bubble-margin-left" -->
            <div class="inline-block" style="margin-left:21px;">
                <span class="title">
                    他人接触：
                </span>
                <Bubble
                    v-for="item in bubbleParam[tableType].otherContact"
                    :count="item.count"
                    group="Label"
                    :isSelect="item.isSelect"
                    :hashCode="item.hashCode"
                    :disable="item.count <= 0"
                    @click="handleBubbleClick"
                    type="blue"
                    :enableTooltip="item.enableTooltip"
                    :key="item.code"
                    :adaptiveSize="true"
                >
                    {{ item.label }}
                    <span v-if="item.enableTooltip" slot="tooltipContent">
                        {{ item.toolTip}}
                    </span>
                </Bubble>
            </div>

             <!-- class="bubble-margin-top bubble-center" -->
            <div class="inline-block" style="margin-left:21px;">
                <span class="title">
                    我的推荐：
                </span>
                <Bubble
                    v-for="item in bubbleParam[tableType].myRecommend"
                    :count="item.count"
                    group="Label"
                    :isSelect="item.isSelect"
                    :hashCode="item.hashCode"
                    :disable="item.count <= 0"
                    @click="handleBubbleClick"
                    :enableTooltip="item.enableTooltip"
                    type="red"
                    :key="item.code"
                    :adaptiveSize="true"
                >
                    {{ item.label }}
                    <span v-if="item.enableTooltip" slot="tooltipContent">
                        {{ item.toolTip}}
                    </span>
                </Bubble>
            </div>
        

        <!-- <div class="bubble-margin-top bubble-center">
            <span class="title">
                我的推荐：
            </span>
            <Bubble
                v-for="item in bubbleParam[tableType].myRecommend"
                :count="item.count"
                group="Label"
                :isSelect="item.isSelect"
                :hashCode="item.hashCode"
                :disable="item.count <= 0"
                @click="handleBubbleClick"
                :enableTooltip="item.enableTooltip"
                type="red"
                :key="item.code"
                :adaptiveSize="true"
            >
                {{ item.label }}
                <span v-if="item.enableTooltip" slot="tooltipContent">
                    {{ item.toolTip}}
                </span>
            </Bubble>
        </div> -->

    </div>
</template>

<script>
    import Bubble from '#/component/common/bubble.vue'
    import eventBus from '#/js/util/event-bus.js';
    import eventNameJson from '#/js/config/eventName.json';
    import bubbleParamJson from '#/js/config/selectBubble.json'

    const eventName = eventNameJson.talentPoolTable;

    export default {
        name: "selectBubble",
        componentName: "selectBubble",
        components: {
            Bubble,
        },
        data() {
            return {
                activeItem: {},
                bubbleParam: bubbleParamJson,
                bubbleResult: {
                    Label: ''
                }
            }
        },
        props: {
            date: {
                startDate: '',
                endDate: ''
            },
            tableType:String,
        },
        computed:{
            isTalentPool(){
                return this.tableType == 'talentPool';
            }
        },
        created() {
            let bubbleParamArray = [];
            for (let key in this.bubbleParam[this.tableType]) {
                bubbleParamArray = bubbleParamArray.concat(this.bubbleParam[this.tableType][key]);
            }
            this.bubbleParamHashMap = this.createHashMap(bubbleParamArray);
            for(let key in this.bubbleParamHashMap) {
                this.bubbleParamHashMap[key].isSelect = false;
            }

            this.$on(`${eventName.bubbleCategory.reset}`, () => {
                this.clearResult();
            })
            this.$on('setBubbleGroupedCount', this.init)
        },
        methods: {
            init(params) {
                params.bubble.map(item => {
                    if(this.bubbleParamHashMap[item.filterType]){
                        this.bubbleParamHashMap[item.filterType].count = item.count;
                    }
                })
            },
            getBubbleCount() {

            },
            clearResult() {
                this.bubbleResult = {
                    Label: ''
                };
                for (let key in this.bubbleParamHashMap) {
                    this.bubbleParamHashMap[key].isSelect = false;
                }
            },
            createHashMap(arr) {
                let Map = {};
                for (let key in arr) {
                    let orderKey = `${arr[key].code}`;
                    Map[orderKey] = arr[key];
                    arr[key].hashCode = orderKey;
                }
                return Map;
            },
            handleBubbleClick(group, hashCode, isSelect) {
                let eventName = this.tableType === 'talentPool' ? 'TalentPoolBubbleClick' : 'TrackingListBubbleClick';
                for (let key in this.bubbleParamHashMap) {
                    if (key !== hashCode) {
                        this.bubbleParamHashMap[key].isSelect = false;
                    } else {
                        this.bubbleParamHashMap[key].isSelect = isSelect;
                    }
                }

                if (isSelect) {
                    this.bubbleResult[group] = this.bubbleParamHashMap[hashCode].hashCode;
                    _tracker.track(eventName, JSON.stringify({
                        filterType: this.bubbleParamHashMap[hashCode].code
                    }))

                    // TODO 待定是否更改为事件广播
                    return this.$emit('handleAddLabel', 'bubble', {
                        text: this.bubbleParamHashMap[hashCode].title + "： " + this.bubbleParamHashMap[hashCode].label,
                        code: this.bubbleParamHashMap[hashCode].code
                    }, true);
                } else {
                    _tracker.track(eventName, JSON.stringify({
                        filterType: ''
                    }))

                    return this.$emit('handleCleanLabel', 'bubble');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title {
        font-weight: bold;
        color: #444;
    }

    .select-bubble {
        width: 100%;
        // display: table;
        // margin: 0 auto;
    }

    .flex-box {
        display: flex;
        justify-content: space-around;
    }
    .bubble-margin-top{
        margin-top: 10px;
    }
    .bubble-margin-left{
        margin-left: 40px;
    }
    .bubble-center{
        text-align: center;
    }
    .inline-block{
        display: inline-block;
    }
</style>
