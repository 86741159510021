<template>
    <span class="call-phone-btn" @click="handleCall">
        <span class="calling-gif" v-if="isCalling"></span>
        <font-icon class="table-body-icon-big" :class="{'disabled':disabled}" href="#icon-talent_phone"  v-else></font-icon>
    </span>
</template>
<script>
export default {
    name:'callPhoneBtn',
    props:{
        item: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            isCalling: false,
            disabled: false
        }
    },
    created() {
        if(!this.item.isFirmResume && !this.$store.state.user.userInfo.isCFUser){
            this.disabled = true;
        };
    },
    methods: {
        handleCall(){
            if(this.isCalling || this.disabled){
                return;
            }
            this.$emit('handleCall', this.item);
            this.isCalling = true;
            setTimeout(() => {
                this.isCalling = false;
            }, 10000);
        }
    },
}
</script>
<style lang="scss" scoped>
.call-phone-btn{
    .table-body-icon-big{
        cursor: pointer;
        &.disabled{
            cursor: default;
        }
    }
    .calling-gif{
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        background: url('~@src/assets/images/calling.gif') center no-repeat;
    }
}
</style>

