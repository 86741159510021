<template>
    <div class="talent-pool-table">
        <!-- talentPool表格 -->
        <el-table
            lazy
            border
            height="700"
            row-key="rowKey"
            style="width:100%"
            ref="multipleTable"
            tooltip-effect="light"
            :load="load"
            :data="tableData"
            v-loading="tableDataLoading"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            @selection-change="handleSelectionChange"
        >
            <el-table-column
                fixed
                width="42"
                align="center"
                type="selection"
                header-align="center"
            ></el-table-column>
            <el-table-column
                fixed
                width="120"
                align="left"
                header-align="left"
                label="最近操作时间"
                class-name="table-header-dropdown-wrapper"
            >
                <template slot="header">
                    <el-dropdown trigger="click" class="table-header-dropdown"  @command="handleOperateTitle">
                        <span class="el-dropdown-link">{{operateType == 0 ? '最近操作时间' : '综合排序'}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                        <el-dropdown-menu slot="dropdown" class="table-header-dropdown-menu" :visible-arrow="false" :offset="20">
                            <el-dropdown-item :class="operateType == 0 ? 'el-dropdown-item-selected' : ''" command="latestLogTime">最近操作时间</el-dropdown-item>
                            <el-dropdown-item :class="operateType == 1 ? 'el-dropdown-item-selected' : ''" command="composite">综合排序</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
                <template slot-scope="scope">
                    <operation-info :time="scope.row.resumeListItem.latestLogTime" :resume-id="scope.row.resumeListItem.resumeId" v-if="scope.row.subIndex == -1"></operation-info>
                </template>
            </el-table-column>
            <el-table-column
                fixed
                label="姓名"
                width="185"
                header-align="left"
                class-name="name-cell"
            >
                <template slot="header">
                    <span class="table-header-tip-cell">
                        姓名
                        <el-popover
                            :offset="18"
                            width="430"
                            trigger="hover"
                            :visible-arrow="false"
                            placement="bottom-start"
                            popper-class="talentpool-name-popover talentpool-popover"
                        >
                            <div class="popover-card">
                                <div class="card-tip">
                                    <span class="tip-icon">
                                        <font-icon class="table-body-icon" href="#icon-talent_si"></font-icon>
                                    </span>
                                    个人库简历
                                </div>
                                <div class="card-tip">
                                    <span class="tip-icon">
                                        <font-icon class="table-body-icon" href="#icon-talent_qi"></font-icon>
                                    </span>
                                    企业库简历
                                </div>
                                <div class="card-tip">
                                    <span class="tip-icon">
                                        <svg class="icon table-body-icon table-body-icon-tx" aria-hidden="true">
                                            <use xlink:href="#icon-talent_tx" class="tx-size"></use>
                                        </svg>
                                    </span>
                                    添加了事项提醒<span class="color-primary">（未完成）</span>
                                </div>
                                <div class="card-tip" v-if="isCFUser">
                                    <span class="tip-icon">
                                        <font-icon class="table-body-icon" href="#icon-talent_zhan"></font-icon>
                                    </span>
                                    系统判断当前个人库简历不在企业库时，显示【占】的标签
                                </div>
                                <div class="card-tip occupy-flex" v-if="isCFUser">
                                    <span class="tip-icon tip-occupy">
                                        <font-icon class="table-body-icon" href="#icon-talent_zhan_mh"></font-icon>
                                    </span>
                                    <span>
                                        系统判断当前个人库简历更新鲜更完整时，可以更新覆盖企业库简历，显示【占标签】
                                    </span>
                                </div>
                            </div>
                            <span slot="reference" class="icon-question">
                                <font-icon class="table-header-tip-icon question-hover" href="#icon-talent_ql"></font-icon>
                                <font-icon class="table-header-tip-icon question-no-hover" href="#icon-talent_qd"></font-icon>
                            </span>
                        </el-popover>
                    </span>
                </template>
                <template slot-scope="scope">
                    <span v-if="scope.row.resumeListItem.isJobHunting">
                        <font-icon class="table-body-icon icon-job-hunting" href="#icon-talent_qiu"></font-icon>
                    </span>
                    <el-tooltip
                        effect="light"
                        :content="scope.row.resumeListItem.realName"
                        placement="top"
                        :open-delay="popoverDelay"
                        :disabled="scope.row.resumeListItem.realName.length <= 3"
                    >
                        <a
                            class="candidate-link"
                            target="_blank"
                            href="javascript:void(0)"
                            @click.prevent="handleJumpDetail(scope.row)"
                        >
                            {{scope.row.resumeListItem.realName}}
                        </a>
                    </el-tooltip>
                    <span v-if="scope.row.resumeListItem.isFirmResume">
                        <font-icon class="table-body-icon tip-margin" href="#icon-talent_qi"></font-icon>
                    </span>
                    <span v-if="!scope.row.resumeListItem.isFirmResume">
                        <font-icon class="table-body-icon tip-margin" href="#icon-talent_si"></font-icon>
                    </span>
                    <span v-if="isCFUser">
                        <font-icon v-if="scope.row.resumeListItem.resumeGrabType ==1" class="table-body-icon tip-margin" href="#icon-talent_zhan"></font-icon>
                        <font-icon v-if="scope.row.resumeListItem.resumeGrabType ==2" class="table-body-icon tip-margin" href="#icon-talent_zhan_mh"></font-icon>
                    </span>
                    <span v-if="scope.row.resumeListItem.resumeReminders.length>0" @mouseenter="getReminder(scope.row)">
                        <reminder-info class="tip-margin" :reminders="scope.row.resumeListItem.resumeReminders"></reminder-info>
                    </span>
                    <resume-operation :tableType="tableType" class="tip-margin" :type="0" :resume="scope.row"></resume-operation>
                </template>
            </el-table-column>
            <el-table-column
                v-for="(item, index) in titleStore.slice(2)"
                :key="index"
                header-align="left"
                :label="tableTitleMap[item].label"
                :min-width="tableTitleMap[item].minWidth"
                :class-name="tableTitleMap[item].customClass"
                :show-overflow-tooltip="tableTitleMap[item].showOverflowTooltip"
            >
                <template slot="header" slot-scope="{ column }">
                    <!-- <template v-if="tableTitleMap[item].isSimpleHeaderSelect">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="true"
                            :title="tableTitleMap[item].label"
                            :label="tableTitleMap[item].headerSelectLabel"
                            :enableSearch="tableTitleMap[item].headerSelectEnableSearch"
                            :selectItem="headerFilter[tableTitleMap[item].headerFilterName]"
                            @selectChange="selectDataChange"
                        ></select-popover>
                    </template> 
                    v-else
                    -->
                    <template v-if="tableTitleMap[item].isHeaderSelect">
                        <el-dropdown
                            trigger="click"
                            class="table-header-dropdown"
                            @command="handleAddTrackingListSource"
                            placement="bottom"
                            v-if="tableData.length > 0"
                        >
                            <span class="el-dropdown-link">
                                加入渠道
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="table-header-dropdown-menu" :visible-arrow="false">
                                <el-dropdown-item
                                    :class="addTrackingListSource == 0 ? 'el-dropdown-item-selected' : ''"
                                    command="bothSource">
                                    全部
                                </el-dropdown-item>
                                <el-dropdown-item
                                    :class="addTrackingListSource == 1 ? 'el-dropdown-item-selected' : ''"
                                    command="firmSource">
                                    企业库
                                </el-dropdown-item>
                                <el-dropdown-item
                                    :class="addTrackingListSource == 2 ? 'el-dropdown-item-selected' : ''"
                                    command="personalSource">
                                    个人库
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <span v-else>加入渠道</span>
                    </template>
                    <template v-else>{{column.label}}</template>
                </template>
                <template slot-scope="scope">
                    <template v-if="tableTitleMap[item].isSimpleText">
                        <!-- {{scope.row.resumeListItem["resumesCompletions"] || '—'}} -->
                        {{scope.row.resumeListItem[tableTitleMap[item].name] || '—'}}
                    </template>
                    <template v-else>
                        <template v-if="tableTitleMap[item].name == 'communicationCount'">
                            <communication-job
                                ref="communicationJob"
                                :count="scope.row.resumeListItem.communicationCount"
                                :resume-id="scope.row.resumeListItem.resumeId"
                                @add-communication="resumeOperation('addCommunication', {resume: scope.row})"
                                @add-schedule="(communication) => resumeOperation('addSchedule', {resume: scope.row, communicationInfo: communication})"
                                @update-item="handleUpdateItem(scope.row)"
                                v-if="scope.row.resumeListItem.isFirmResume"
                            ></communication-job>
                            <span v-else>—</span>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'recomendationCount'">
                            <recommend-info
                                :isMyRecommend="true"
                                :count="scope.row.resumeListItem.recomendationCount"
                                :isFirm="scope.row.resumeListItem.isFirmResume"
                                :candidate-id="scope.row.resumeListItem.candidateId"
                                :resume-id="scope.row.resumeListItem.resumeId"
                            ></recommend-info>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'mobile'">
                            <span class="empty-bar" v-if="((scope.row.resumeListItem.contactStatus && scope.row.resumeListItem.contactStatus.mobileStatus === 3)||!scope.row.resumeListItem.contactStatus) && scope.row.resumeListItem.mobile === ''">—</span>
                            <el-popover
                                v-else
                                placement="bottom-start"
                                trigger="hover"
                                popper-class="talentpool-phone-popover talentpool-popover"
                                :visible-arrow="false"
                                :open-delay="popoverDelay"
                            >
                                <template v-if="scope.row.resumeListItem.contactStatus">
                                    <span v-if="scope.row.resumeListItem.contactStatus.mobileStatus === 2" class="phone-popover-content">{{isAdministrator ? '候选人正在入职保证期中，联系方式已被上锁，您可以进行解锁' : '候选人正在入职保证期中，联系方式已被上锁'}}</span>
                                    <span v-if="scope.row.resumeListItem.contactStatus.mobileStatus === 3"  class="phone-popover-content">点击拨打：{{scope.row.resumeListItem.mobile}}</span>
                                    <span v-if="scope.row.resumeListItem.contactStatus.mobileStatus === 4" class="phone-popover-content">号码失效：<span class="line-through">{{scope.row.resumeListItem.mobile}}</span></span>
                                    <span v-if="scope.row.resumeListItem.contactStatus.mobileStatus === 1" class="phone-popover-content">点击查看手机</span>
                                </template>
                                <template v-else>
                                    <span class="phone-popover-content">点击拨打{{scope.row.resumeListItem.mobile}}</span>
                                </template>

                                <span slot="reference" @click.prevent="handleMobile(scope.row,scope.$index)">
                                    <template v-if="scope.row.resumeListItem.contactStatus">
                                        <font-icon v-if="scope.row.resumeListItem.contactStatus.mobileStatus === 2" :class="{'cursor-pointer':isAdministrator}" class="table-body-icon-big" href="#icon-talent_locked"></font-icon>
                                        <call-phone-btn
                                            :ref="scope.row.resumeListItem.contactStatus ? 'callPhoneBtn_' + scope.$index : ''"
                                            v-if="scope.row.resumeListItem.contactStatus.mobileStatus === 3"
                                            @handleCall="handleCallPhoneDialog"
                                            :item="scope.row.resumeListItem"
                                        ></call-phone-btn>
                                        <font-icon v-if="scope.row.resumeListItem.contactStatus.mobileStatus === 4" class="table-body-icon-big" href="#icon-talent_shixiao"></font-icon>
                                        <font-icon v-if="scope.row.resumeListItem.contactStatus.mobileStatus === 1" class="table-body-icon-big cursor-pointer" href="#icon-talent_closeeye"></font-icon>
                                    </template>
                                    <template v-else>
                                        <call-phone-btn
                                            :ref="scope.row.resumeListItem.contactStatus ? '' : 'callPhoneBtn_' + scope.$index"
                                            @handleCall="handleCallPhoneDialog"
                                            :item="scope.row.resumeListItem"
                                        ></call-phone-btn>
                                    </template>
                                </span>
                            </el-popover>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'email'">
                            <span class="empty-bar" v-if="((scope.row.resumeListItem.contactStatus && scope.row.resumeListItem.contactStatus.emailStatus === 3)||!scope.row.resumeListItem.contactStatus) && scope.row.resumeListItem.email === ''">—</span>
                            <el-popover
                                v-else
                                trigger="hover"
                                :visible-arrow="false"
                                placement="bottom-start"
                                :open-delay="popoverDelay"
                                popper-class="talentpool-email-popover talentpool-popover"
                            >
                                <template v-if="scope.row.resumeListItem.contactStatus">
                                    <span v-if="scope.row.resumeListItem.contactStatus.emailStatus === 2" class="email-popover-content">{{isAdministrator ? '候选人正在入职保证期中，联系方式已被上锁，您可以进行解锁' : '候选人正在入职保证期中，联系方式已被上锁'}}</span>
                                    <span v-if="scope.row.resumeListItem.contactStatus.emailStatus === 3"  class="email-popover-content">{{scope.row.resumeListItem.email}}</span>
                                    <span v-if="scope.row.resumeListItem.contactStatus.emailStatus === 4" class="email-popover-content line-through">{{scope.row.resumeListItem.email}}</span>
                                    <span v-if="scope.row.resumeListItem.contactStatus.emailStatus === 1" class="email-popover-content">点击查看邮箱</span>
                                </template>
                                <template v-else>
                                    <span class="email-popover-content">{{scope.row.resumeListItem.email}}</span>
                                </template>
                                <span slot="reference" @click="handleMail(scope.row,scope.$index)">
                                    <template v-if="scope.row.resumeListItem.contactStatus">
                                        <font-icon v-if="scope.row.resumeListItem.contactStatus.emailStatus === 2" :class="{'cursor-pointer':isAdministrator}" class="table-body-icon-big" href="#icon-talent_locked"></font-icon>
                                        <font-icon v-if="scope.row.resumeListItem.contactStatus.emailStatus === 3" class="table-body-icon-big" href="#icon-talent_mail"></font-icon>
                                        <font-icon v-if="scope.row.resumeListItem.contactStatus.emailStatus === 4" class="table-body-icon-big" href="#icon-talent_shixiao"></font-icon>
                                        <font-icon v-if="scope.row.resumeListItem.contactStatus.emailStatus === 1" class="table-body-icon-big cursor-pointer" href="#icon-talent_closeeye"></font-icon>
                                    </template>

                                    <template v-else>
                                        <font-icon class="table-body-icon-big" href="#icon-talent_mail"></font-icon>
                                    </template>
                                </span>
                            </el-popover>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'inquiryInfo'">
                            <inquiry-log
                                :resume="scope.row"
                                @update-item="handleUpdateItem"
                                v-if="scope.row.resumeListItem.inquiryLogsCount>0"
                            ></inquiry-log>
                            <span class="cursor-pointer" v-else @click="resumeOperation('addVisit', {resume: scope.row})">
                                <font-icon class="table-body-icon-big icon-inquiry" href="#icon-talent_addinquiry"></font-icon>
                                <span class="color-warn">&nbsp;添加</span>
                            </span>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'label'">
                            <add-itap-popover
                                :tagData="scope.row"
                                :isResume="true"
                                v-if="scope.row.resumeListItem.tags.length > 0"
                            ></add-itap-popover>
                            <span class="cursor-pointer" v-else @click="resumeOperation('addItap', {resume: scope.row})">
                                <font-icon class="table-body-icon-big icon-inquiry" href="#icon-talent_lable"></font-icon>
                                <span class="color-warn">&nbsp;添加</span>
                            </span>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'yearOfExperience'">
                            <div class="table-slot table-slot-content">
                                {{ scope.row.resumeListItem.yearOfExperience | yearValue }}
                            </div>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'degree'">
                            <span>{{scope.row.resumeListItem.degree | degreeValue}}</span>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'recommendInfo'">
                            <recommend-info
                                :isMyRecommend="false"
                                :count="scope.row.resumeListItem.recomendationCount"
                                :isFirm="scope.row.resumeListItem.isFirmResume"
                                :candidate-id="scope.row.resumeListItem.candidateId"
                                :resume-id="scope.row.resumeListItem.resumeId"
                            ></recommend-info>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'source'">
                            <span>{{setSourceFrom(scope.row.resumeListItem)}}</span>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'uploader'">
                            <avatar
                                :userId="scope.row.resumeListItem.creatorId ? scope.row.resumeListItem.creatorId : defaultCreatorId"
                                :src="scope.row.resumeListItem.avatarUrl ? scope.row.resumeListItem.avatarUrl : defaultCreatorAvatar"
                                :enableLink="false"
                                :isTalentPool="true"
                                size="sm"
                                :shareData="getShareData(scope.row.resumeListItem)"
                            ></avatar>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'attachments'">
                            <attachments
                                :resume="scope.row"
                                @update-item="handleUpdateItem"
                            ></attachments>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'addFrom'">
                            <el-tooltip
                                effect="light"
                                placement="top"
                                :open-delay="popoverDelay">
                                <span slot="content">
                                    {{scope.row.resumeListItem.addTrackingListSourceDetail}}{{scope.row.resumeListItem.addTrackingListSourceDetail.indexOf('FloatingList') === -1 ? '手动加入' : '自动加入'}}
                                </span>
                                <span>{{scope.row.resumeListItem.addTrackingListSource}}</span>
                            </el-tooltip>
                        </template>
                        <template v-if="tableTitleMap[item].name == 'recommendReason'">
                            <recommend-reason-popover :resume="scope.row" @update-item="handleUpdateItem" v-if="scope.row.resumeListItem.tempRecommendReason"></recommend-reason-popover>
                            <span class="cursor-pointer" v-else @click="resumeOperation('editRecommendReason', {resume: scope.row})">
                                <font-icon class="table-body-icon-big icon-inquiry" href="#icon-talent_honer_red"></font-icon>
                                <span class="color-warn">&nbsp;添加</span>
                            </span>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <span class="empty-data">
                        暂无搜索数据，可尝试扩大搜索时间范围
                        <!-- <i class="icon-font icon-talent_shouzhi"></i>
                        <el-dropdown trigger="click" @command="handleCommand">
                            <span class="dropdown-title">
                                <font-icon class="table-body-icon table-body-icon-calendar" href="#icon-calendar"></font-icon>{{timeLimit}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="timelimit-dropdown-menu">
                                <el-dropdown-item v-for="item in timeLimits" :key="item.name" :class="{'bubble-date-selected':timeLimit==item.name}" :command="item">{{item.name}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown> -->
                    </span>
                </div>
            </template>
            <template slot="append" v-if="timeLimitShow">
                <span class="empty-data">
                    搜索数据太少，可尝试扩大搜索时间范围
                    <!-- <i class="icon-font icon-talent_shouzhi"></i>
                    <el-dropdown trigger="click" @command="handleCommand">
                        <span class="dropdown-title">
                            <font-icon class="table-body-icon table-body-icon-calendar" href="#icon-calendar"></font-icon>{{timeLimit}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" class="timelimit-dropdown-menu">
                            <el-dropdown-item v-for="item in timeLimits" :key="item.name" :class="{'bubble-date-selected':timeLimit==item.name}" :command="item">{{item.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown> -->
                </span>
            </template>
        </el-table>

        <!-- footer -->
        <div class="work-table-footer">
            <div class="footer-left">
                <div class="footer-select">
                    <input hidden v-model="allSelect" type="checkbox">
                    <span class="all-select-checkbox" @click="handleAllSelect"></span>
                    <span>全选</span>
                    <span class="select-count">已选择 <span class="color-orange">{{multipleSelection.length}}</span> 位候选人</span>
                </div>
                <batch-operation 
                    :selectList="multipleSelection" 
                    @batch-operation-done="handleOperationDone"
                ></batch-operation>
            </div>
            <el-pagination :current-page="page.current" :page-sizes="[20, 30, 50]" :page-size="page.size" class="el-pagination-workTable" layout="total, sizes, prev, pager, next, slot" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
                <span class="pagination-text">
                    <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                    <span @click="handlePagerJump">跳转</span>
                </span>
            </el-pagination>
        </div>
        <phone-call 
            ref="phoneCallDialog" 
            :call-to="phoneCall.callTo" 
            :object-id="phoneCall.candidateId" 
            :tbdResumeId="phoneCall.resumeId" 
            sourceFrom="talentPool" 
            @cancelPhoneCall="handleCancelPhoneCall"
        >
            <!-- <button class="free-call" slot-scope="slotProps">
                <i class="icon-call" v-show="!slotProps.onCallStatu"></i>
                <span v-text="slotProps.onCallStatuText"></span>
                <span class="animate-dot" v-show="slotProps.isWaiting">···</span>
            </button> -->
        </phone-call>
        <mail-dialog ref="mailDialog"></mail-dialog>
    </div>
</template>

<script>
import CommunicationJob from '#/views/tracking-list/component/communicationJob.vue'
import RecommendInfo from '../component/recommendInfo.vue'
import ResumeOperation from '../component/resumeOperation.vue';
import BatchOperation from '../component/batchOperation.vue';
import ReminderInfo from '../component/reminderInfo';
import Avatar from '#/component/common/avatar.vue';
import CallPhoneBtn from '../component/callPhoneBtn.vue';
import PhoneCall from '#/component/common/phone-call-new.vue';
import MailDialog from '#/component/common/dialog/mail-dialog.vue';
import Attachments from '../component/attachments';
import AddItapPopover from '#/component/common/addItapPopover.vue';
import SelectPopover from '#/component/common/select-popover.vue';
import RecommendReasonPopover from '../component/recommendReasonPopover.vue';
import OperationInfo from '../component/operationInfo.vue';
import InquiryLog from '../component/inquiryLog.vue';
import talentPoolService from '#/js/service/talentPoolService.js';
import degreeKeyValue from '#/js/config/degreeKeyValue.json'

import emitter from '#/js/mixins/emitter.js';
import eventNameJson from '#/js/config/eventName.json';
import moment from 'moment';
import sourceFromJson from '#/js/config/sourceFrom.json'
import { TalentOrTracking as tableTitleMap } from '#/js/config/tableTitleMap.json';
const eventName = eventNameJson.talentPoolTable;
    export default {
        name: "TalentPoolTable",
        componentName: "TalentPoolTable",
        components:{
            CallPhoneBtn,
            PhoneCall,
            MailDialog,
            ResumeOperation,
            BatchOperation,
            CommunicationJob,
            RecommendInfo,
            ReminderInfo,
            Avatar,
            Attachments,
            SelectPopover,
            AddItapPopover,
            OperationInfo,
            InquiryLog,
            RecommendReasonPopover
        },
        mixins: [emitter],
        props: {
            tableData: {
                type: Array,
                default: []
            },
            titleStore: {
                type: Array,
                default: []
            },
            page: {
                type: Object,
                default: {
                    current: 0,
                    size: 20,
                    total: 0
                }
            },
            headerFilter: {},
            tableType: String
        },
        watch: {
            tableData: 'updateHeight',
            titleStore: 'updateHeight'
        },
        data(){
            return{
                timeLimit:'最近一周',
                timeLimits: [
                    {name: '最近一周', value: 7},
                    {name: '最近一个月', value: 30},
                    {name: '最近三个月', value: 90},
                    {name: '最近半年', value: 182},
                    {name: '最近一年', value: 365}
                ],
                tableDataLoading:true,
                multipleSelection:[],
                phoneCall:{
                    callTo:2,//callTo：0、1 传userId，2 传candidateId，3 传recommendationId。而原逻辑只有传candidateId的情况
                    candidateId:'',
                    resuneId: ''
                },//拨打电话组件需要的传的props
                pagerJump:0,
                operateType: 0,
                popoverDelay: 300,
                addTrackingListSource: 0,
                tableTitleMap: tableTitleMap,
                callingIndex: -1
            }
        },
        computed:{
            allSelect(){
                return this.tableData.length > 0 ? this.tableData.length == this.multipleSelection.length : false;
            },
            isCFUser(){
                return this.$store.state.user.userInfo.isCFUser;
            },
            isAdministrator(){
                return this.$store.state.user.userInfo.isAdministrator;
            },
            defaultCreatorId(){
                return this.$store.state.user.userInfo.id;
            },
            defaultCreatorAvatar(){
                return this.$store.state.user.userInfo.avatarUrl
            },
            timeLimitShow(){
                // return this.tableData.length <= (this.page.size-2) && this.tableData.length >0;
                // 当从少数条切换到多数条会出bug
                return this.tableData.length <= 10 && this.tableData.length >0;
            },
            isTalentPool(){
                return this.tableType == 'talentPool';
            }
        },
        filters:{
            degreeValue(val){
                return degreeKeyValue[val];
            },
            yearValue(val){
                if(val ==0 ){
                    return '未知'
                }
                return val + '年'
            },
            sourceChannel(val){
                if(val.indexOf('BZW')>=0 || val.indexOf('RCB')>=0 ){
                    return '盒子同步-'
                }else if(val.indexOf('XLS')>=0){
                    return '小猎手'
                }
            },
            sourceFrom(val){
                return sourceFromJson[val];
            },
            date(val){
                if(val){
                    return moment(val).format('YYYY-MM-DD');
                }else{
                    return ''
                }
            },
        },
        created(){
            this.$on(eventName.tableHeaderFilter.reset, this.handelCleanHeadFilter);
        },
        mounted() {
            let target = document.getElementsByClassName('el-table__body-wrapper')[0];
            if(target) {
                const SCROLL_BAR_HEIGHT = 18;
                target.onwheel = (e) =>{
                     if(
                        e.target.className
                        && {}.toString.call(e.target.className) == '[object String]'    //svg元素的className不是字符串
                        && e.target.className.indexOf('el-table__body-wrapper') > -1
                        && e.offsetY <= target.offsetHeight
                        && (e.offsetY + 18) >= target.offsetHeight
                    ) {
                        e.preventDefault();
                        if (e.deltaY < 0) {
                            e.target.scrollLeft += 50;
                        }else {
                            e.target.scrollLeft -= 50;
                        }
                    }
                }
            }
        },
        methods:{
            handleJumpDetail(item) {
                this.$emit('jump-detail', item.resumeListItem.isFirmResume ? item.resumeListItem.candidateId : item.resumeListItem.resumeId, item);
                // window.open(`/Headhunting/MyCompany.html#/candidateDetail/${item.resumeListItem.resumeId}`, '_blank');
                window.open(`/#/candidateDetail/${item.resumeListItem.resumeId}`, '_blank');
            },
            handleSelectionChange(val){
                this.multipleSelection = val;
            },
            handleAllSelect(){
                this.$refs.multipleTable.toggleAllSelection();
            },
            handleCommand(item){
                this.timeLimit = item.name;
                this.$emit('empty-time-select',item);
            },
            load(tree, treeNode, resolve) {
                let isFirmResume = tree.resumeListItem.isFirmResume;
                _tracker.track(this.tableType === 'talentPool' ? 'TalentPoolExecuteOperation' : 'TrackingListExecuteOperation', JSON.stringify({
                    operationType: 'viewMoreResume',
                    operationText: '查看更多简历',
                    isFirmResume: isFirmResume
                }));

                let treeIndex = tree.index;
                let childrenData = [];
                talentPoolService.getMoreResume({
                    talentId: tree.talentId,
                    resumeId: tree.resumeListItem.resumeId,
                    isTrackingList: !this.isTalentPool
                }).then((res) =>{
                    if(res && res.length > 0) {
                        res.forEach((item, subIndex) => {
                            let children = {};
                            children.resumeListItem = item;
                            children.resumeListItem.resumeInquiryLog = {};
                            children.index = treeIndex;
                            children.subIndex = subIndex;
                            children.talentId = tree.talentId;
                            children.rowKey = _generateUUID();
                            childrenData.push(children);
                        })
                        tree.children = childrenData;
                        setTimeout(() => {
                            resolve(childrenData);
                            this.$nextTick(() => {
                                if(tree.index == (this.page.size - 1)) {
                                    let bodyWrapper = document.querySelector('.el-table__body-wrapper');
                                    bodyWrapper.scrollTop += (childrenData.length * 40);
                                }
                            })
                        }, 1000);
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            handleCallPhoneDialog(item){
                let json = {
                    candidateId: item.candidateId || '',
                    isFromAuthorization: item.isFromAuthorization,
                    isFromShare: item.isFromShare,
                    resumeId: item.resumeId,
                    isFirmResume: item.isFirmResume,
                    proportion: item.proportion || 0,
                    sourceFirmName: item.authorizationSourceFirmName || '',
                }
                this.phoneCall.candidateId = item.candidateId||'';
                this.phoneCall.resumeId = item.resumeId||'';
                this.$refs.phoneCallDialog.calledNumber = item.mobile;
                this.$refs.phoneCallDialog.callByWorkbench(json);
            },
            handleMailDialog(item){
                let json = {
                    candidateId: item.candidateId,
                    emailAddress: item.email
                }
                this.$refs.mailDialog.emailByTable(json);
            },
            resumeOperation(command, dataJson) {
                this.$emit('resume-operation', command, dataJson);
            },
            handlePagerJump(){
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total/this.page.size);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.$emit('page-change',{current:currentPager,type:"current"})
                }
            },
            handleCurrentChange(val){
                this.$emit('page-change',{current:val,type:"current"})
            },
            handleSizeChange(val){
                if(window.localStorage) {
                    localStorage.setItem('LBD_TABLE_PAGE_SIZE',val);
                }
                this.$emit('page-change',{size:val,type:"size"})
            },
            transformHeaderFilterParams (params){
                for (let key in params){
                    params[key].text = params[key].label;
                }
                return params;
            },
            selectDataChange (label, result){
                result = this.transformHeaderFilterParams(result);
                if(result.length > 0){
                    return this.$emit(eventName.searchLabel.addLabel, label, result, true);
                } else {
                    return this.$emit(eventName.searchLabel.cleanLabel, label, true);
                }

            },
            handelCleanHeadFilter (label){
                const list = this.headerFilter[label] || [];
                for (let key in list){
                    list[key].isSelect = false;
                }
            },
            handleMail(resume,index){
                let item = resume.resumeListItem;
                let status = item.contactStatus?item.contactStatus.emailStatus:-1;
                if(!item.isFirmResume){
                    return;
                }
                switch (status) {
                    /* case 3:
                    case -1:
                        this.handleMailDialog(item);
                        break; */
                    case 1:
                        this.handleViewMail(resume,index);
                        break;
                    default:
                        break;
                }
            },
            handleMobile(resume, index){
                let item = resume.resumeListItem;
                let isFirmResume = resume.resumeListItem.isFirmResume;
                let status = item.contactStatus?item.contactStatus.mobileStatus:-1;
                let eventName = this.tableType === 'talentPool' ? 'TalentPoolExecuteOperation' : 'TrackingListExecuteOperation';
                this.callingIndex = index;
                switch (status) {
                    case 3:
                    case -1:
                        //this.handleCallPhoneDialog(item);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'callPhone',
                            operationText: '拨打电话',
                            isFirmResume: isFirmResume
                        }));
                        break;
                    case 1:
                        this.handleViewMobile(resume,index);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'viewContact',
                            operationText: '查看联系方式',
                            isFirmResume: isFirmResume
                        }));
                        break;
                    default:
                        break;
                }
            },
            handleViewMail(resume,index){
                this.$emit('view-mail',resume,index);
            },
            handleViewMobile(resume,index){
                this.$emit('view-mobile',resume,index);
            },
            handleOperationDone(){
                this.$refs.multipleTable.clearSelection()
            },
            getReminder(resume){
                this.$emit('get-reminder',resume);
            },
            scrollTop(){
                this.$refs.multipleTable && (this.$refs.multipleTable.bodyWrapper.scrollTop = 0);
            },
            setOperateType(val){
                this.operateType = val;
            },
            handleOperateTitle(val){
                switch(val) {
                    case 'joinTime':
                        this.operateType = 2;
                        this.$emit('trackinglist-operate-change', 2);
                        break;
                    case 'latestLogTime':
                        this.operateType = 0;
                        this.$emit('trackinglist-operate-change', 0);
                        break;
                    case 'composite':
                        this.operateType = 1;
                        this.$emit('trackinglist-operate-change', 1);
                        break;
                }
            },
            updateHeight() {
                const self = this;
                this.$nextTick(() => {
                    let bodyWrapper = document.querySelector('.el-table__body-wrapper');
                    let overflowHeight = bodyWrapper.scrollHeight - bodyWrapper.clientHeight;
                    if(this.tableData.length == 0) {
                        bodyWrapper.style.overflowY = 'auto';
                        return false;
                    }
                    if(overflowHeight <= 80 && overflowHeight != 0) {
                        bodyWrapper.style.overflowY = 'auto';
                        if(this.titleStore.length != 2) {
                            document.querySelector('.gutter').classList.add('show-gutter');
                        }
                        document.querySelector('.gutter').classList.add('show-gutter');
                    } else if(overflowHeight == 0) {
                        bodyWrapper.style.overflowY = '';
                        document.querySelector('.gutter').classList.remove('show-gutter');
                    } else if(bodyWrapper.style.overflowY == 'auto' && this.titleStore.length != 2) {
                        document.querySelector('.gutter').classList.add('show-gutter');
                    } else if(this.titleStore.length == 2) {
                        document.querySelector('.gutter').classList.remove('show-gutter');
                    }
                    self.$refs.multipleTable.doLayout();
                });
            },
            handleUpdateItem(item){
                this.$emit('update-item',item);
            },
            handleAddTrackingListSource(value) {
                let label = 'addTrackingListSource';
                let result = [{
                    label: '',
                    text: ''
                }];
                if(value == 'bothSource') {
                    result = [];
                    this.addTrackingListSource = 0;
                } else if(value == 'firmSource') {
                    result[0].label = '企业库';
                    this.addTrackingListSource = 1;
                } else {
                    result[0].label = '个人库';
                    this.addTrackingListSource = 2;
                }
                this.selectDataChange(label, result);
            },
            setSourceFrom(item){
                let sourceChannel = item.sourceChannel;
                let sourceFromText = item.sourceFromText;
                if(item.creatorId!==this.defaultCreatorId){
                    return '企业库';
                }else{
                    if(sourceChannel.indexOf('BZW')>=0 || sourceChannel.indexOf('RCB')>=0 ){
                        //盒子
                        return `${sourceFromText}-盒子`
                    }else if(sourceChannel.indexOf('XLS')>=0){
                        //小猎手
                        return `${sourceFromText}-小猎手`
                    }else{
                        //猎必得
                        let text = '';
                        switch(item.resumeCreateType){
                            case 1:
                                text = sourceFromText?`${sourceFromText}-单份上传`:'单份上传';
                                break;
                            case 2:
                                text = sourceFromText?`${sourceFromText}-批量上传`:'批量上传';
                                break;
                            case 3:
                                text = sourceFromText?`${sourceFromText}-Excel上传`:'Excel上传';
                                break;
                            default:
                                text = '其它';
                        }
                        return text;
                    }
                }
            },
            getShareData(candidateInfo) {
                return {
                    resumeId: candidateInfo.resumeId,
                    candidateName: candidateInfo.realName,
                    title: candidateInfo.title,
                    company: candidateInfo.company,
                    cityName: candidateInfo.location,
                    yearOfExperience: candidateInfo.yearOfExperience,
                    degree: candidateInfo.degree,
                    school: candidateInfo.school
                }
            },
            handleCancelPhoneCall() {
                this.$refs[`callPhoneBtn_${this.callingIndex}`][0].isCalling = false;
            }
        }
    }

</script>

<style lang="scss" scoped>
    @import "#/css/scss/variables.scss";

    .color-orange {
        color: $orange;
    }
    .color-primary{
        color: $primary;
    }
    .color-warn{
        color: #FF6564;
    }
    .center{
        display: table;
        margin: auto;
    }
    .cursor-pointer{
        cursor: pointer;
    }

    .text-ellipsis{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .line-through{
        text-decoration: line-through;
    }

    /*
        .bg-gray{

            box-shadow: 4px 0 4px -2px #CCCCCC;
        }
    */

    .talent-pool-table /deep/ {
        padding: 0 20px;
        background:url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        letter-spacing: 0px;
        height: 54.5% !important;
        .el-table {
            overflow-y: auto;
            color: #666;

            .el-table__append-wrapper,.el-table__append-gutter{
                text-align: center;
                height: 80px;
                line-height: 80px;
                .empty-data{
                    display: inline-block;
                }
            }

            .el-loading-mask {
                z-index: 900;
            }

            .el-table__fixed::before {
                display: none;
            }

            th {
                padding: 0 0 0 20px;
                height: 42px;
                line-height: 42px;
                background-color: #E8E8E8;
                border-color: #CCC;
                color: #666;
                .el-checkbox {
                    padding: 0 10px;
                }

                .cell {
                    padding: 0;
                    .el-checkbox {
                        display: none;
                    }
                }
                &.gutter{
                    display: none;
                }

                .title-slot{
                    padding: 0 !important;
                }
                &.is-center{
                    padding: 0;
                }
                div{
                    display: block;
                }
            }
            th:nth-of-type(2) {
                padding-left: 10px;
            }
            th:nth-of-type(3) {
                padding-left: 30px;
            }

            td {
                padding: 0;
                height: 40px;
                line-height: 40px;
                .cell {
                    white-space: nowrap;
                    padding-left: 20px;
                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #fff;
                        border-color: $primary;
                        &::after {
                            border-color: $primary;
                        }
                    }
                    &.el-tooltip {
                        div {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
                &.is-center{
                    .cell{
                        padding: 0 3px;
                    }
                }
            }
            td:nth-of-type(1) .cell{
                padding-left: 10px;
            }
            td:nth-of-type(2) .cell{
                padding-left: 10px;
            }
            td:nth-of-type(3) .cell{
                padding-left: 30px;
            }

            tr.el-table__row.el-table__row--level-1{
                background-color: #f8f8f8;
                td{
                    border-top: 1px solid #f8f8f8;
                    border-bottom: 1px solid #f8f8f8;
                    .el-checkbox{
                        display: none;
                    }
                }
                td:nth-last-of-type(1) {
                    border-right: 1px solid #EBEEF5;
                }
            }
            tr.el-table__row.el-table__row--level-1.hover-row{
                td{
                    border-top: 1px solid #38BC9D;
                    border-bottom: 1px solid #38BC9D;
                }
            }
            tr.el-table__row.el-table__row--level-0 + .el-table__row--level-1 {
                td{
                    border-top-color: #EBEEF5;
                }

                &.hover-row td {
                    border-top-color: $primary;
                }
            }

            .el-table__row {
                .el-checkbox__inner {
                    border-color: #999;
                }
            }

            .expand-cell{
                border-right: none;
                .el-table__expand-icon--expanded {
                    color: $primary;
                }
                .el-table__expand-icon > .el-icon {
                    z-index: 10;
                    &.el-icon-arrow-right {
                        font-weight: bold;
                    }
                }
            }

            .name-cell{
                position: relative;
                 .cell {
                    .candidate-link {
                        color: #666;
                        float: left;
                        // width: 45px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &:hover {
                            color: $primary;
                        }
                    }
                    .tip-margin{
                        margin-left: 5px;
                        .el-loading-spinner {
                            margin-top: -12px;
                            .circular {
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                    .icon-job-hunting{
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }

            .operate-cell, .table-header-dropdown-wrapper .cell{
                .el-table__expand-icon--expanded{
                    .el-icon-arrow-right{
                        color: $primary;
                        font-weight: bolder;
                    }
                }
                .el-table__expand-icon{
                    position: absolute;
                    z-index: 20;
                    left: 120px;
                    top: 9px;
                }
            }
            .table-header-dropdown-wrapper .cell{
                .el-table__expand-icon{
                    left: auto;
                    right: -30px;
                }
            }
            .el-table__empty-block{
                min-height: 250px;
                .empty-data{
                    display: block;
                }
            }
            .el-table__empty-block,.el-table__append-wrapper{
                .el-table__empty-text{
                    line-height: inherit;
                }
                .empty-img{
                    display: inline-block;
                    background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                    width: 240px;
                    height: 228px;
                }
                .empty-data{
                    color: #444444;
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 0px;
                    .icon-talent_shouzhi{
                        position: relative;
                        color: $primary;
                        animation:leftmove 0.6s linear infinite;
                    }
                    @keyframes leftmove
                    {
                        from {left:0px;}
                        to {left:10px;}
                    }
                    .el-dropdown{
                        cursor: pointer;
                        font-size: 14px;
                        color: #666;
                        background: #FFF;
                        border: 1px solid #38BC9D;
                        border-radius: 4px;
                        left: 20px;
                        .dropdown-title{
                            outline: none;
                            width: 125px;
                            height: 28px;
                            line-height: 28px;
                            padding: 0 25px 0 10px;
                            display: inline-block;
                            text-align: right;
                            .table-body-icon-calendar{
                                left: 10px;
                                position: absolute;
                                top: 45%;
                                transform: translateY(-50%);
                            }
                            .el-icon--right{
                                position: absolute;
                                right: 10px;
                                top: 7px;
                            }
                        }
                    }
                }
            }
            .el-table-column--selection {
                border-right: none;
            }
            .el-table__body-wrapper, .el-table__fixed-body-wrapper {
                padding-bottom: 1px;
            }
        }
    }
</style>
<style lang="scss">
.el-popover.talentpool-popover{
    margin-top: 5px;
    min-width: 50px;
    color: #666;
}
.el-popover.talentpool-phone-popover,.el-popover.talentpool-email-popover{
    max-width: 300px;
    min-width: 0px;
    padding: 0px;
    .phone-popover-content,.email-popover-content{
        padding: 10px 15px;
        display: inline-block
    }
}

.el-popover{
    .record-audio-listen{
        margin-top: 8px;
        width: 260px;
        height: 28px;

        .audio-wrapper{
            line-height: 28px;

            .control-wrap{
                width: 140px;
                height: 28px;
                color: $primary;

                .progress-control{
                    width: 140px;
                    height: 2px;
                    background-color: $primary;
                }

                .control-btn{
                    width: 10px;
                    height: 10px;
                    background-color: $primary;
                }
            }

            .play-item{
                margin-top: 5px;
            }

            .audio-time{
                max-width: 80px;
                min-width: 68px;
                height: 28px;
                font-size: 12px;
                .time-item {
                    font-size: 12px;
                    &:nth-of-type(1) {
                        margin-right: -2px;
                    }
                    &:nth-of-type(2) {
                        margin-left: -2px;
                    }
                }
            }
        }
    }
}

.el-popover.talentpool-name-popover{
    padding: 15px;
    padding-right: 35px;
    .popover-card{
        font-size: 12px;
        letter-spacing: 0px;
        .tip-icon{
            display: inline-block;
            width: 35px;
            text-align: right;
            margin-right: 10px;
        }
        .occupy-flex{
            display: flex;
            .tip-occupy{
                width: 49px;
                margin-right: 13px;
            }
        }
        .card-tip{
            margin-bottom: 10px;
        }
        .card-tip:nth-last-of-type(1){
            margin-bottom: 0px;
        }
    }
}
.el-popover.itap-popover {
    margin-top: 5px;
    padding: 16px;
    padding-right: 0;
}
.timelimit-dropdown-menu{
    padding: 0;
    user-select: none;
    .el-dropdown-menu__item.bubble-date-selected{
        background-color: rgba(56,188,157,0.1);
        color: $primary;
    }
    .popper__arrow {
        display: none;
    }
}

.inquiry-log-header{
    margin-bottom: 13px !important;

    .el-button {
        float: right;
        min-width: 62px;
        height: 20px;
        margin: 0;
        padding: 0;
        line-height: 18px;
        background-color: #fff;
        border-radius: 4px;
        color: $primary;
        border-color: #36B495;
        &:hover {
            background-color: #36B495;
            color: #fff;

            .el-icon-circle-plus-outline.add-itap-icon{
                color: #fff;
            }
        }

        span{
            position: relative;
            top: -2px;
        }

        .el-icon-circle-plus-outline.add-itap-icon{
            line-height: 18px;
            color: $primary;
            font-size: 18px;
        }
    }
}

    .inquiry-log-userInfo{
        margin-bottom: 7px;
        img{
            display: inline-block;
            margin-right: 5px;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            border: 1px solid;
        }
        .name,.time{
            font-size: 12px;
        }

        .time{
            margin-left: 10px;
            color: #999;
        }
    }

    .talentpool-popover-inquiryLog{
        padding: 20px;
    }

    .show-gutter {
        width: 18px !important;
        display: table-cell !important;
    }
    .empty-bar{
        padding-left: 3px;
    }
</style>
