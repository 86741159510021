import { render, staticRenderFns } from "./mail-dialog.vue?vue&type=template&id=8121d9c0&"
import script from "./mail-dialog.vue?vue&type=script&lang=js&"
export * from "./mail-dialog.vue?vue&type=script&lang=js&"
import style0 from "./mail-dialog.vue?vue&type=style&index=0&id=8121d9c0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8121d9c0')) {
      api.createRecord('8121d9c0', component.options)
    } else {
      api.reload('8121d9c0', component.options)
    }
    module.hot.accept("./mail-dialog.vue?vue&type=template&id=8121d9c0&", function () {
      api.rerender('8121d9c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/component/common/dialog/mail-dialog.vue"
export default component.exports