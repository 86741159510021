var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom-start",
        trigger: "hover",
        "popper-class": "reminder-info",
        "visible-arrow": false,
        offset: 500,
      },
    },
    [
      _c(
        "ul",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "reminderInfo",
          staticClass: "reminder-status-stage",
        },
        _vm._l(_vm.reminderList, function (reminder, index) {
          return _c(
            "li",
            { key: index, staticClass: "reminder-li" },
            [
              _c("div", { staticClass: "reminder-span reminder-time" }, [
                _c("span", { staticClass: "reminder-text" }, [
                  _vm._v("提醒时间："),
                ]),
                _c("span", { staticClass: "reminder-value" }, [
                  _vm._v(_vm._s(_vm._f("date")(reminder.remindTime))),
                ]),
              ]),
              _c("div", { staticClass: "reminder-span" }, [
                _c("span", { staticClass: "reminder-text" }, [
                  _vm._v("提醒内容："),
                ]),
                _c("span", { staticClass: "reminder-value" }, [
                  _vm._v(_vm._s(reminder.content)),
                ]),
              ]),
              _c("notice-opr", {
                attrs: { noticeItem: reminder, index: index },
                on: { handleDone: _vm.removeItem, handleDel: _vm.removeItem },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c("span", { attrs: { slot: "reference" }, slot: "reference" }, [
        _c(
          "svg",
          {
            staticClass: "icon table-body-icon table-body-icon-tx",
            attrs: { "aria-hidden": "true" },
          },
          [
            _c("use", {
              staticClass: "tx-size",
              attrs: { "xlink:href": "#icon-talent_tx" },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }