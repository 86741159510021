import { render, staticRenderFns } from "./tableWrapper.vue?vue&type=template&id=c96fb064&"
import script from "./tableWrapper.vue?vue&type=script&lang=js&"
export * from "./tableWrapper.vue?vue&type=script&lang=js&"
import style0 from "./tableWrapper.vue?vue&type=style&index=0&id=c96fb064&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c96fb064')) {
      api.createRecord('c96fb064', component.options)
    } else {
      api.reload('c96fb064', component.options)
    }
    module.hot.accept("./tableWrapper.vue?vue&type=template&id=c96fb064&", function () {
      api.rerender('c96fb064', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/talent-pool/layout/tableWrapper.vue"
export default component.exports