var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "communicationPopover",
      attrs: {
        placement: "bottom-start",
        width: "580",
        trigger: "hover",
        "popper-class": "communication-job",
        "visible-arrow": false,
        "open-delay": 700,
        disabled: _vm.count === 0,
      },
      on: { show: _vm.showDetails },
    },
    [
      _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
        _vm.count > 0
          ? _c(
              "span",
              [
                _c("font-icon", {
                  staticClass: "table-body-icon-big",
                  attrs: { href: "#icon-coffee" },
                }),
                _c("span", [_vm._v(" " + _vm._s(_vm.count))]),
              ],
              1
            )
          : _c(
              "span",
              {
                staticClass: "cursor-pointer add-communication-btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("add-communication")
                  },
                },
              },
              [
                _c("i", {
                  staticClass:
                    "el-icon-circle-plus-outline table-body-icon-big",
                }),
                _c("span", { staticClass: "color-warn" }, [_vm._v(" 添加")]),
              ]
            ),
      ]),
      _c("div", { staticClass: "communication-job-wrapper" }, [
        _c("div", { staticClass: "table-popper-header" }, [
          _vm._v("\n            待沟通的职位\n            "),
          _c(
            "span",
            {
              staticClass: "cursor-pointer add-communication-btn",
              on: {
                click: function ($event) {
                  return _vm.$emit("add-communication")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-plus-outline" }),
              _vm._v("\n                添加\n            "),
            ]
          ),
        ]),
        _c(
          "ul",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "communication-job-list",
          },
          [
            _vm._l(_vm.communicationList, function (communication, index) {
              return _c(
                "li",
                { key: index, staticClass: "communication-job-item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "job-title",
                      attrs: {
                        title: `${communication.jobName}[${communication.jobLocationName}]-${communication.jobCustomerName}`,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "job-name",
                          attrs: {
                            href: `/#/Job/${communication.jobId}`,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(communication.jobName) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n                    [" +
                          _vm._s(communication.jobLocationName) +
                          "]-" +
                          _vm._s(communication.jobCustomerName) +
                          "\n                "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "comminucation-time" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(communication.created.slice(0, 10)) +
                        " 添加\n                "
                    ),
                  ]),
                  _c("div", { staticClass: "communication-operate" }, [
                    _c(
                      "span",
                      {
                        staticClass: "text-operate-btn",
                        on: {
                          click: function ($event) {
                            return _vm.addSchedule(communication)
                          },
                        },
                      },
                      [_vm._v("提醒")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "text-operate-btn",
                        on: {
                          click: function ($event) {
                            return _vm.toRecommend(communication)
                          },
                        },
                      },
                      [_vm._v("推荐")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "text-operate-btn orange",
                        on: {
                          click: function ($event) {
                            return _vm.removecomminucation(communication.id)
                          },
                        },
                      },
                      [_vm._v("移出")]
                    ),
                  ]),
                ]
              )
            }),
            _vm.count > 3
              ? _c("li", { staticClass: "show-more-btn" }, [
                  _c(
                    "a",
                    {
                      staticClass: "more-link",
                      attrs: {
                        href: `/#/candidateDetail/${_vm.resumeId}/communicationList`,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    更多\n                    "
                      ),
                      _c("i", { staticClass: "el-icon-d-arrow-right" }),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }