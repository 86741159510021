<template>
    <el-popover
        placement="bottom"
        width="240"
        trigger="click"
        v-model="visible"
        @hide="clearSearch">
        <el-input slot="reference"
            class="search-advanced-params"
            v-model="selectedValue"
            :placeholder="placeholder"
            :readonly="readonly">
            <i v-if="iconClass" slot="prefix" class="icon-font" :class="iconClass">

            </i>
        </el-input>
        <div class="single-select-wrap">
            <el-input class="select-search" size="small" v-model="searchValue" :placeholder="'请输入关键字'" ></el-input>
            <ul class="single-select-list">
                <li class="single-select-item" :class="item.isSelected?'is-selected':''" v-for="(item, index) in selectItems" :key="index" @click="handleSelectItem(item,index)">
                    {{item.value}}
                </li>
            </ul>
        </div>
    </el-popover>
</template>
<script>
export default {
    name: 'selectSearch',
    props:{
        selectOptions:{
            type: Array,
            default: []
        },
        iconClass:{
            type: String,
            default: ''
        },
        placeholder:{
            type: String,
            default: ''
        },
        readonly:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedValue:'',
            searchValue:'',
            selectItems: this.selectOptions,
            visible: false
        }
    },
    watch: {
        searchValue(val,oldVal){
            this.handleSearch();
        }
    },
    created() {
        this.selectItems.map((item)=>{
            item.isSelected = false;
        })
    },
    methods: {
        handleSelectItem(selItem,index){
            this.selectItems.map((item)=>{
                if(item.value == selItem.value){
                    item.isSelected = !item.isSelected;
                    this.$set(this.selectItems,index,item);
                    item.isSelected?this.selectedValue=item.value:this.selectedValue='';
                }else{
                    item.isSelected = false;
                }
            })
            this.$emit('handleSelectItem',this.selectedValue);
            this.visible = false;
        },
        handleReset(){
            this.selectOptions.map((item)=>{
                item.isSelected = false;
            })
            this.selectedValue = '';
            this.selectItems = this.selectOptions;
        },
        handleSearch(){
            if(!this.searchValue){
                this.selectItems = this.selectOptions;
                this.selectItems.map(item=>{
                    if(item.value == this.selectedValue){
                        item.isSelected = true;
                    }else{
                        item.isSelected = false;
                    }
                })
            }else{
                let searchArr = this.selectOptions.filter(item => item.value.toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase())>-1);
                this.selectItems = searchArr;
            }

        },
        clearSearch(){
            this.searchValue = '';
        }
    },
}
</script>
<style lang="scss" scoped>
    .single-select-wrap{
        .single-select-list{
            padding-top: 10px;
            max-height: 300px;
            overflow-y: auto;
            .single-select-item{
                position: relative;
                height: 30px;
                line-height: 30px;
                cursor: pointer;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                &.is-selected{
                    color: $primary;
                    &:after{
                        content: "\e6c5";
                        font-family: 'icon-font';
                        position: absolute;
                        right: 10px;
                        font-size: 18px;
                        top: 0;
                    }
                }
                &:hover{
                    padding-left: 10px;
                    transition: all .2s linear;
                    background-color: #ebebeb;
                }
            }
        }
    }
</style>


