<template>
    <div class="batch-operation">
        <el-button class="batch-operation-btn batch-view" @click="batchOperate('batchView')" :disabled="selectList.length == 0">
            批量查看
        </el-button>
        <el-button type="primary" class="batch-operation-btn add-to-tracking-list" icon="el-icon-circle-plus" @click="batchAddToTrackingList" :disabled="selectList.length == 0" v-if="!isTrackingList" :loading="loading">
            加入到Tracking List
        </el-button>
        <el-button class="batch-operation-btn add-itap" @click="batchOperate('batchAddItap')" :disabled="selectList.length == 0">
            打标签
        </el-button>
        <el-tooltip effect="light" placement="top-start" :open-delay="500" v-if="user.isCFUser">
            <span slot="content">
                将带<span style="color: #F5A623">【占】</span>的个人库简历导入企业库，抢占归属权
            </span>
            <el-button class="batch-operation-btn occupy-ownership" @click="batchOperate('batchOccupyOwnership')" :disabled="selectList.length == 0 || (selectList.length > 0 && cannotOccupy)">
                抢占归属
            </el-button>
        </el-tooltip>
      
        <el-tooltip effect="light" placement="top-start" :open-delay="500" v-if="isTrackingList">
            <span slot="content" v-text="disableShareTitle"></span>
            <el-button 
                style="width:142px"
                class="batch-operation-btn add-itap" 
                :class="{'set-red': invalidTrackListShare}"
                @click="batchOperate('batchTrackListShare')" 
                :disabled="selectList.length == 0"
                >
                 <!-- || (selectList.length > 0 && disableTrackListShare) -->
                <!-- v-if="isTrackingList" -->
                <!-- :disabled="disableTrackListShare" -->
                分享Tracking List
                <i v-if="invalidTrackListShare" class="el-icon-warning-outline" style="color: red;"></i>
            </el-button>
        </el-tooltip>
        <el-button 
            class="batch-operation-btn remove" 
            @click="batchOperate('batchRemove')" 
            :disabled="selectList.length == 0" 
            v-if="isTrackingList">
            移除
        </el-button>
    </div>
</template>

<script>
import talentPoolService from '#/js/service/talentPoolService.js';
import emitter from '#/js/mixins/emitter';

export default {
    name: 'batch-operation',

    mixins: [emitter],

    props: {
        selectList: {
            type: Array
        }
    },
    data() {
        return {
            isTrackingList: false,
            loading: false,
        }
    },
    computed:{
        user(){
            return this.$store.state.user.userInfo;
        },
        resumeIds(){
            let ids =[];
            this.selectList.forEach((item)=>{
                ids.push(item.resumeListItem.resumeId);
            })
            return ids;
        },
        cannotOccupy() {
            return this.selectList.some((item)=>{
                return item.resumeListItem.resumeGrabType != 1;
            })
        },
        disableTrackListShare() {
           return !(this.selectList.length > 0 && this.selectList.every(item=> item.resumeListItem.isFirmResume)); 
        //    return !this.selectList.every(item => item.resumeListItem.isFirmResume); 
        },
        // canShare() {
        //     return (this.selectList.length > 0 && this.selectList.every(item=> item.resumeListItem.isFirmResume)); 
        // },
        invalidTrackListShare() {
           return (this.selectList.length > 0 && !this.selectList.every(item=> item.resumeListItem.isFirmResume)); 
        },
        disableShareTitle() {
            return this.invalidTrackListShare ? '选择中包含私有简历，不能分享。': '分享Tracking List';
        },
    },
    mounted() {
        if(this.$route.path.indexOf('trackingList') > -1) {
            this.isTrackingList = true;
        }
    },
    methods: {
        batchAddToTrackingList() {
            this.loading = true;
            talentPoolService.addToTrackingList({
                sourceFrom: 1,
                resumeIds: this.resumeIds
            }).then((res) => {
                if(res) {
                    let message = '';
                    if(this.resumeIds.length == 1) {
                        message = res.existCount == 1 ? '该候选人已在trackinglist' : (res.successCount == 1 ? '已成功加入trackinglist' : '加入失败，请重新尝试');
                    } else {
                        if(res.successCount != 0) {
                            message += `已成功加入${res.successCount}人`
                        }
                        if(res.existCount != 0) {
                            if(message) {
                                message += '，';
                            }
                            message += `已存在${res.existCount}人`
                        }
                        if(res.failCount != 0) {
                            if(message) {
                                message += '，';
                            }
                            message += `失败${res.failCount}人`
                        }
                    }

                    if(res.successCount != 0) {
                        // 加入TrackingList成功之后弹窗打标签
                        this.batchOperate('batchAddItap');
                    }

                    shortTips(message);
                    setTimeout(() => {
                        this.dispatch('TableWrapper', 'update-list');
                    }, 3000);
                }
            }).catch((err) => {
                console.log(err);
            }).finally(()=>{
                this.loading = false;
                this.$emit('batch-operation-done')
            });
        },
        batchOperate(command) {
            if(command == 'batchOccupyOwnership' && this.cannotOccupy) {
                return false;
            }
            if(command === 'batchTrackListShare' && this.invalidTrackListShare) {
                return false;
            } 
            let dataJson = {};
            dataJson.command = command;
            dataJson.data = this.selectList;
            this.dispatch('TableWrapper', 'resume-operation', dataJson);
        }
    }
}
</script>

<style lang="scss" scoped>
    .batch-operation {
        .batch-view {
            color: #fff;
            background-color: #38BC9D;
            &:hover {
                background-color: #48CAAC;
            }
        }
        .add-to-tracking-list /deep/ {
            width: 168px;
            span {
                margin-left: 0;
            }
            .el-icon-circle-plus {
                font-size: 16px;
            }
        }
        .occupy-ownership {
            background-color: #F5A623;
            &:hover, &:focus {
                background-color: #e89812;
            }
        }
        .share-btn{
            width: 124px;
        }
    }
</style>
