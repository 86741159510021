<template>
    <el-dialog
        title="推荐原因"
        class="recommend-reason-dialog"
        width="608px"
        :visible.sync="isShow"
        :show-close="false"
        :close-on-click-modal="false">
        <div v-loading="loading">
            <el-input
                :class="isError ? 'error-input' : ''"
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 10}"
                placeholder="请输入推荐原因"
                v-model.trim="recommendReason"
                maxlength="400">
            </el-input>
            <div class="textarea-count">({{recommendReason.length}}/400)</div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import talentPoolService from '#/js/service/talentPoolService.js';

export default {
    name: 'recommend-reason-dialog',
    data(){
        return{
            isShow: false,
            resume: {
                resumeListItem: {
                    resumeId: '',
                    tempRecommendReason: ''
                }
            },
            loading: false,
            recommendReason: '',
            isError: false
        }
    },
    methods:{
        show(resume){
            this.resume = resume;
            if(this.resume.resumeListItem.tempRecommendReason) {
                this.recommendReason = this.resume.resumeListItem.tempRecommendReason;
            }
            this.isShow = true;
        },
        handleConfirm(){
            if(!this.recommendReason) {
                this.isError = true;
                shortTips("推荐原因不能为空值或空白符！");
                return;
            }
            this.loading = true;
            this.isError = false;
            if(this.resume.resumeListItem.tempRecommendReason) {
                talentPoolService.updateRecommendReason({
                    resumeId: this.resume.resumeListItem.resumeId,
                    reason: this.recommendReason
                }).then((res) => {
                    this.loading = false;
                    this.isShow = false;
                    this.$emit('update-item', this.resume);
                }).catch((err) => {
                    this.loading = false;
                    this.isShow = false;
                    console.log(err);
                });
            } else {
                talentPoolService.addRecommendReason({
                    resumeId: this.resume.resumeListItem.resumeId,
                    reason: this.recommendReason
                }).then((res) => {
                    this.loading = false;
                    this.isShow = false;
                    this.$emit('update-item', this.resume);
                }).catch((err) => {
                    this.loading = false;
                    this.isShow = false;
                    console.log(err);
                });
            }
            this.recommendReason = ""
        },
        handleCancel() {
            this.isShow = false;
            this.recommendReason = '';
        }
    }
}
</script>

<style lang="scss">
.recommend-reason-dialog {
    .el-dialog__body {
        text-align: center;
        padding: 30px 50px 50px;
        color: #444;
        .error-input {
            border-color: #ff493c !important;
        }
        .textarea-count{
            position: absolute;
            right: 60px;
            bottom: 122px;
            text-align: right;
            font-size: 12px;
            color: #999;
        }
    }
}
</style>
