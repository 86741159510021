<template>
    <div>
        <el-popover
            ref="recommendPopover"
            v-if="count>0 && isFirm"
            placement="bottom-start"
            width="660"
            trigger="hover"
            popper-class="recommend-info"
            :visible-arrow="false"
            @show="showDetails"
            :open-delay="700"
        >
            <el-alert
                v-if="isAgreementJob"
                :title="`当前显示 ${recomendationsList.length} 条`"
                :closable="false"
                style="margin-top: 8px;margin-bottom: 8px;"
                type="error">
            </el-alert>
            <ul class="recommend-status-stage" v-loading="loading">
                <li v-for="(recommend,index) in recomendationsList" :key="index">
                    <div class="status-info">
                        <span class="status-summary">
                            <a v-if="recommend.canAccessJob" class="text-secTxt link-hov" target="_blank" :href="recommend.jobUrl" :title="recommend.jobInfo" v-text="recommend.jobInfo"></a>
                            <span v-if="!recommend.canAccessJob" class="text-secTxt" :title="recommend.jobInfo" v-text="recommend.jobInfo"></span>
                        </span>
                        <el-tag effect="plain" :class="recommend.recommendTheme" class="recommend-tag">{{recommend.recommendState}}</el-tag>
                        <span :class="recommend.recommendTheme" class="attachment">{{recommend.recommendAttachment}}</span>
                    </div>
                    <div class="update-time">
                        <a v-if="recommend.canAccessRecommendation" class="link-hov" :href="recommend.recommendUrl" target="_blank" :title="recommend.created+' 推荐'">
                            <span>{{recommend.created | date}} 推荐 <font-icon class="svg-dbarrow" href="#icon-double-right-arrow"></font-icon></span>
                        </a>
                    </div>
                </li>
                <li v-if="count>3">
                    <!-- <a class="more-link" :href="`/Headhunting/MyCompany.html#/candidateDetail/${resumeId}/recommendationList`" target="_blank">更多 -->
                    <a class="more-link" :href="`/#/candidateDetail/${resumeId}/recommendationList`" target="_blank">更多
                    <i class="el-icon-d-arrow-right"></i></a>
                </li>
            </ul>
            <div slot="reference" >
                <font-icon class="table-body-icon-big icon-inquiry" href="#icon-talent_recommend"></font-icon>
                <span>&nbsp;{{count}}</span>
            </div>
        </el-popover>
        <span v-else>—</span>
    </div>
</template>
<script>
import moment from 'moment';
import recommendation from '#/js/config/recommendation.json';
import server from '#/js/service/talentPoolService.js'
import popover from '#/js/mixins/popover.js';
import invoiceSubFlowStatus from '#/js/config/invoiceSubFlowStatus.js';
export default {
    props:{
        /**
         * count  为推荐情况数量
         * isFirm   是否是企业库简历
         * isMyRecommend    我的推荐列表/全部推荐列表
         */
        count: Number,
        isFirm: Boolean,
        resumeId: String,
        candidateId: String,
        isMyRecommend: {
            type: Boolean,
            default: false
        }
    },

    mixins: [popover],

    data(){
        return{
            recomendationsList:{},
            hasDetail:false,
            loading:true,
        }
    },
    computed: {
        isAgreementJob() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('AgreementJob');
        },
    },
    filters:{
        date(val){
           return moment(val).format('YYYY-MM-DD');
        }
    },
    methods:{
        showDetails() {
            let codeRecommendation = {}
            for(let status in recommendation) {
                codeRecommendation[recommendation[status]['code']] = status;
            }
            if(!this.hasDetail) {
                server.getLastestRecommendations({
                    id: this.resumeId,
                    isRecommendByMe: this.isMyRecommend
                }).then((res)=>{
                    console.log(`k---------------1`, res)
                    this.recomendationsList = res;
                    this.recomendationsList.forEach(listItem => {
                        // listItem.jobUrl = '/Headhunting/MyCompany.html#/Job/' + listItem.jobId;
                        listItem.jobUrl = '/#/Job/' + listItem.jobId;
                        listItem.locationInfo = listItem.locationInfo ? '[' + listItem.locationInfo + ']' : '';
                        listItem.jobInfo = listItem.jobName +  listItem.locationInfo + '-' + listItem.employerName;
                        let recommendationInfo  = recommendation[codeRecommendation[listItem.recommendStatus]];
                        if([24,26].includes(listItem.recommendStatus)) {
                            listItem.recommendState = invoiceSubFlowStatus[listItem.flowStepName];
                            listItem.recommendTheme = 'info';
                        } else {
                            listItem.recommendState = recommendationInfo.text;
                            listItem.recommendTheme = recommendationInfo.theme;
                            listItem.recommendAttachment = recommendationInfo.attachment || '';
                        }
                        // if(listItem.recommendStatus == 64 && listItem.interviewConfirmedStatus != 1 && listItem.interviewConfirmedStatus != 2) {
                        //     listItem.recommendAttachment = '待确认';
                        // }
                        // listItem.recommendUrl = `/Headhunting/MyCompany.html#/candidateDetail/${this.resumeId}/recommendation/${listItem.id}`;
                        listItem.recommendUrl = `/#/candidateDetail/${this.resumeId}/recommendation/${listItem.id}`;
                    });
                    this.hasDetail = true;
                    this.loading=false;
                }).finally(()=>{
                    this.popoverShow(this.$refs.recommendPopover);
                    this.loading=false;
                });
            }
        },
    }
}
</script>
<style lang="scss">
.recommend-status-stage {
    display: block;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px 10px 10px 20px;
    position: relative;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.30);
    text-align: left;
    margin-bottom: 0;
    .more-link {
        color: $primary;
    }

    li {
        overflow: hidden;
        line-height: 24px;
        padding: 5px 0;
        .status-info{
            float: left;
        }
    }

    .status-summary {
        float: left;
        width: 290px;
        margin-right: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .recommend-tag {
        float: left;
        height: 22px;
        font-size: 12px;
        padding: 0 5px;
        line-height: 22px;
        cursor: default;
        &.warning{
            border: 1px solid #ff7200;
            color: #ff7200;
        }
        &.success{
            border: 1px solid #38bc9d;
            color: #38bc9d;
        }
        &.default{
            border: 1px solid #b9b9b9;
            color: #b9b9b9;
        }
        &.info{
            border: 1px solid #39f;
            color: #39f;
        }
    }

    .attachment{
        float: left;
        width: 70px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        padding: 0 5px;
        &.warning{
            color: #ff7200;
        }
        &.success{
            color: #38bc9d;
        }
        &.default{
            color: #b9b9b9;
        }
        &.info{
            color: #39f;
        }
    }

    .update-time {
        float: right;
        text-align: right;
        width: 135px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .svg-dbarrow{
            font-size: 12px;
        }
    }
    .link-hov:hover{
        color: $primary;
    }
    .el-icon-d-arrow-right{
        font-size: 12px;
    }
}
.recommend-info.el-popover{
    padding: 0;
    min-height: 50px;
    box-sizing: content-box;
    margin-top: 0px;
}
</style>
