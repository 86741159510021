var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isTalentPool
    ? _c("div", { staticClass: "select-bubble" }, [
        _c(
          "div",
          { staticStyle: { "margin-left": "21px" } },
          [
            _c("span", { staticClass: "title" }, [
              _vm._v("\n            最近接触的人：\n        "),
            ]),
            _vm._l(
              _vm.bubbleParam[_vm.tableType].recentlyContactedPerson,
              function (item) {
                return _c(
                  "Bubble",
                  {
                    key: item.code,
                    attrs: {
                      count: item.count,
                      group: "Label",
                      isSelect: item.isSelect,
                      hashCode: item.hashCode,
                      disable: item.count <= 0,
                      enableTooltip: item.enableTooltip,
                      adaptiveSize: true,
                    },
                    on: { click: _vm.handleBubbleClick },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.label) + "\n            "
                    ),
                    item.enableTooltip
                      ? _c(
                          "span",
                          {
                            attrs: { slot: "tooltipContent" },
                            slot: "tooltipContent",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.toolTip) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }
            ),
          ],
          2
        ),
        _c("div", { staticClass: "bubble-margin-top" }, [
          _c(
            "div",
            {
              staticClass: "inline-block",
              staticStyle: { "margin-left": "21px" },
            },
            [
              _c("span", { staticClass: "title" }, [
                _vm._v("\n                待处理候选人：\n            "),
              ]),
              _vm._l(
                _vm.bubbleParam[_vm.tableType].pendingCandidate,
                function (item) {
                  return _c(
                    "Bubble",
                    {
                      key: item.code,
                      attrs: {
                        count: item.count,
                        group: "Label",
                        isSelect: item.isSelect,
                        hashCode: item.hashCode,
                        disable: item.count <= 0,
                        type: "red",
                        enableTooltip: item.enableTooltip,
                        adaptiveSize: true,
                      },
                      on: { click: _vm.handleBubbleClick },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.label) +
                          "\n                "
                      ),
                      item.enableTooltip
                        ? _c(
                            "span",
                            {
                              attrs: { slot: "tooltipContent" },
                              slot: "tooltipContent",
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.toolTip) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "inline-block",
              staticStyle: { "margin-left": "21px" },
            },
            [
              _c("span", { staticClass: "title" }, [
                _vm._v("\n                有求职意向的：\n            "),
              ]),
              _vm._l(
                _vm.bubbleParam[_vm.tableType].jobSearchIntention,
                function (item) {
                  return _c(
                    "Bubble",
                    {
                      key: item.code,
                      attrs: {
                        count: item.count,
                        group: "Label",
                        isSelect: item.isSelect,
                        hashCode: item.hashCode,
                        disable: item.count <= 0,
                        type: "blue",
                        enableTooltip: item.enableTooltip,
                        adaptiveSize: true,
                      },
                      on: { click: _vm.handleBubbleClick },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.label) +
                          "\n                "
                      ),
                      item.enableTooltip
                        ? _c(
                            "span",
                            {
                              attrs: { slot: "tooltipContent" },
                              slot: "tooltipContent",
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.toolTip) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }
              ),
            ],
            2
          ),
        ]),
      ])
    : _c("div", { staticClass: "select-bubble" }, [
        _c(
          "div",
          {
            staticClass: "inline-block",
            staticStyle: { "margin-left": "21px" },
          },
          [
            _c("span", { staticClass: "title" }, [
              _vm._v("\n                我的接触：\n            "),
            ]),
            _vm._l(_vm.bubbleParam[_vm.tableType].myContact, function (item) {
              return _c(
                "Bubble",
                {
                  key: item.code,
                  attrs: {
                    count: item.count,
                    group: "Label",
                    isSelect: item.isSelect,
                    hashCode: item.hashCode,
                    disable: item.count <= 0,
                    enableTooltip: item.enableTooltip,
                    adaptiveSize: true,
                  },
                  on: { click: _vm.handleBubbleClick },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(item.label) +
                      "\n                "
                  ),
                  item.enableTooltip
                    ? _c(
                        "span",
                        {
                          attrs: { slot: "tooltipContent" },
                          slot: "tooltipContent",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.toolTip) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass: "inline-block",
            staticStyle: { "margin-left": "21px" },
          },
          [
            _c("span", { staticClass: "title" }, [
              _vm._v("\n                他人接触：\n            "),
            ]),
            _vm._l(
              _vm.bubbleParam[_vm.tableType].otherContact,
              function (item) {
                return _c(
                  "Bubble",
                  {
                    key: item.code,
                    attrs: {
                      count: item.count,
                      group: "Label",
                      isSelect: item.isSelect,
                      hashCode: item.hashCode,
                      disable: item.count <= 0,
                      type: "blue",
                      enableTooltip: item.enableTooltip,
                      adaptiveSize: true,
                    },
                    on: { click: _vm.handleBubbleClick },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.label) +
                        "\n                "
                    ),
                    item.enableTooltip
                      ? _c(
                          "span",
                          {
                            attrs: { slot: "tooltipContent" },
                            slot: "tooltipContent",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(item.toolTip) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }
            ),
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass: "inline-block",
            staticStyle: { "margin-left": "21px" },
          },
          [
            _c("span", { staticClass: "title" }, [
              _vm._v("\n                我的推荐：\n            "),
            ]),
            _vm._l(_vm.bubbleParam[_vm.tableType].myRecommend, function (item) {
              return _c(
                "Bubble",
                {
                  key: item.code,
                  attrs: {
                    count: item.count,
                    group: "Label",
                    isSelect: item.isSelect,
                    hashCode: item.hashCode,
                    disable: item.count <= 0,
                    enableTooltip: item.enableTooltip,
                    type: "red",
                    adaptiveSize: true,
                  },
                  on: { click: _vm.handleBubbleClick },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(item.label) +
                      "\n                "
                  ),
                  item.enableTooltip
                    ? _c(
                        "span",
                        {
                          attrs: { slot: "tooltipContent" },
                          slot: "tooltipContent",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.toolTip) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            }),
          ],
          2
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }