<template>
    <div class="attachments">
        <el-popover
            v-if="resume.resumeListItem.resumeAttachments.length>0"
            placement="bottom-end"
            trigger="hover"
            popper-class="talentpool-attachments-popover talentpool-popover"
            :visible-arrow="false"
            :open-delay="700"
            :offset="500"
            width="280">
            <div v-loading="loading">
                <div class="table-popper-header inquiry-log-header">
                    附件
                    <el-upload 
                        class="popover-attachment-upload" 
                        :action="formatUploadUrl('/Headhunting/Candidate/'+resume.resumeListItem.resumeId+'/Attachment/Add')" 
                        :data="token" 
                        :headers="fileUploadHeaders"
                        :show-file-list="false"
                        :with-credentials="true"
                        :on-success="handleUploadSuccess" 
                        :on-error="handleUploadError" 
                        :before-upload="handleBeforeUpload"
                    >
                        <i class="el-icon-circle-plus-outline add-itap-icon"></i>
                        <span class="attachments-text">添加</span>
                    </el-upload>
                </div>
                <div class="document">
                    <a @click="downloadFile(file.fileId,resume.resumeListItem.resumeId)" class="document-anchor" v-for="(file,index) in resume.resumeListItem.resumeAttachments" :key="index" >
                        <i class="icon-font icon-talent_attachement"></i>
                        <span v-text="file.fileName" class="document-title"></span>
                    </a>
                </div>
            </div>
            <span slot="reference">
                <font-icon class="table-body-icon-big" href="#icon-talent_fujian"></font-icon>
                <span class="attachments-text">{{resume.resumeListItem.resumeAttachments.length}}</span>
            </span>
        </el-popover>
        <span v-else>
            <el-upload 
                class="attachment-upload" 
                :action="formatUploadUrl('/Headhunting/Candidate/'+resume.resumeListItem.resumeId+'/Attachment/Add')" 
                :data="token" 
                :headers="fileUploadHeaders"
                :show-file-list="false"
                :with-credentials="true"
                :on-success="handleUploadSuccess" 
                :on-error="handleUploadError" 
                :before-upload="handleBeforeUpload"
            >
                <font-icon class="table-body-icon-big" href="#icon-talent_fujian_red"></font-icon>
                <span class="attachments-text">添加</span>
            </el-upload>
        </span>
    </div>
</template>
<script>
import {candidate as candidateUrl} from '#/js/config/api.json';
export default {
    computed:{
        token(){
            return {__RequestVerificationToken:this.$store.state.verificationToken.verificationToken};
        },
    },
    props:{
        resume:Object
    },
    data(){
        return{
            loading:false,
            fileUploadHeaders: {
                'RequestVerificationToken': this.$store.state.verificationToken.verificationToken
            },
        }
    },
    methods:{
        formatUploadUrl(url) {
            return _host.portal + url;
        },
        handleUploadSuccess(response, file, fileList){
            if(response.succeeded){
                shortTips('添加附件成功！');
                this.$emit('update-item',this.resume)
            }else{
                shortTips(response.message);
            }
            this.loading = false;
        },
        handleUploadError(err, file, fileList){
            this.loading = false;
            shortTips('上传附件错误，请刷新页面重新尝试！');
        },
        handleBeforeUpload(file){
            if (file.size / 1024 / 1024 > 2) {
                shortTips('上传附件大小不能超过 2MB！');
                return false;
            }
            this.loading = true;
        },
        downloadFile(fileId,resumeId){
            let url = candidateUrl.url_attachment;
            _request({
                method: 'GET',
                url: url,
                data:{resumeId:resumeId,fileId:fileId}
            }).then((data)=>{
                location.href = data;
            }).catch((err)=>{
                shortTips('下载附件失败！')
            })
        }
    }
}
</script>
<style lang="scss">
.attachments{
    .attachment-upload {
        color: #FF6564;
        .el-upload:focus{
            color:#FF6564;
        }
    }
}
.talentpool-attachments-popover.el-popover{
    padding: 20px;
    padding-bottom: 10px;
    .popover-attachment-upload {
        float: right;
        width: 62px;
        height: 20px;
        margin: 0;
        padding: 0;
        line-height: 18px;
        background-color: #fff;
        border-radius: 4px;
        color: $primary;
        border: 1px solid #36B495;
        font-size: 14px;
        font-weight: normal;
        display: inline-flex;
        justify-content: center;
        .el-icon-circle-plus-outline.add-itap-icon{
            line-height: 18px;
            color: $primary;
            font-size: 18px;
        }
        span{
            position: relative;
            top: -2px;
        }
        &:hover, &:focus, &:active {
            background-color: #36B495;
            color: #fff;

            .el-icon-circle-plus-outline.add-itap-icon{
                color: #fff;
            }
        }
    }
    .document{
        max-height: 145px;
        overflow-y: scroll;
    }
    .document-anchor{
        margin-bottom: 10px;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
        user-select: none;
        display: block;
        cursor: pointer;
        &:nth-last-of-type(1){
            margin-bottom: 0;
        }
        &:hover{
            color: $primary;
        }
        .icon-talent_attachement{
            color:$primary;
            margin-right: 5px;
            font-size: 14px;
        }
    }
}
.attachments-text{
    margin-left: 5px;
}
</style>
