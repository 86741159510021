<template>
    <el-popover
        placement="bottom-start"
        trigger="hover"
        popper-class="reminder-info"
        :visible-arrow="false"
        :offset="500">
        <ul class="reminder-status-stage" ref="reminderInfo" v-loading="loading">
            <li v-for="(reminder,index) in reminderList" :key="index" class="reminder-li">
                <div class="reminder-span reminder-time">
                    <span class="reminder-text">提醒时间：</span>
                    <span class="reminder-value">{{reminder.remindTime | date}}</span>
                </div>
                <div class="reminder-span">
                    <span class="reminder-text">提醒内容：</span>
                    <span class="reminder-value">{{reminder.content}}</span>
                </div>
               <!--  <div class="reminder-operate">
                    <span class="operate-done" @click="handleDone(reminder.id,index)">完成</span>
                    <span class="operate-del" @click="handleDelete(reminder.id,index)">删除</span>
                </div> -->
                <notice-opr :noticeItem="reminder" :index="index" @handleDone="removeItem" @handleDel="removeItem"></notice-opr>
            </li>
        </ul>
        <span slot="reference" >
            <svg class="icon table-body-icon table-body-icon-tx" aria-hidden="true">
                <use xlink:href="#icon-talent_tx" class="tx-size"></use>
            </svg>
        </span>
    </el-popover>
</template>
<script>
import server from '#/js/service/talentPoolService.js'
import moment from 'moment';
import NoticeOpr from '#/component/notice/noticeOperate.vue'

export default {
    props:{
        reminders:Array,
    },
    components:{
        NoticeOpr
    },
    data(){
        return{
            hasDetail:false,
            loading:false,
            reminderList: this.reminders
        }
    },
    watch: {
        reminders:function(val,oldVal){
            this.reminderList = val;
        }
    },
    filters:{
        date(val){
            let formatDate = moment(val).format('YYYY-MM-DD HH:mm');
            if(moment(val).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
                formatDate = `今日 ${moment(val).format('HH:mm')}`
            }
            return formatDate;
        },
    },
    methods:{
        removeItem(index){
            this.reminderList.splice(index,1);
        }
    }
}
</script>
<style lang="scss">
.reminder-info.el-popover{
    max-width: 322px;
    margin-top: 0px;
    max-height: 194px;
    min-height: 70px;
    min-width: 200px;
    padding: 10px 20px;
    letter-spacing: 0;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.30);
    .reminder-li{
        padding:10px 0;
        border-bottom: 1px solid #EEE;
        &:nth-of-type(1){
            padding-top: 0;
        }
        &:nth-last-of-type(1){
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    .reminder-span{
        display: flex;
        .reminder-text{
            font-size: 12px;
            color: #999;
            padding-top: 1px;
            white-space: nowrap;
            margin-right: 10px;
        }
        .reminder-value{
            font-size: 14px;
            color: #666;
        }
    }
    .notice-opr{
        margin-left: 70px;
        margin-top: 10px;
        .del-btn{
            margin-right: 0px;
        }
    }
    .reminder-time{
        margin-bottom: 3px;
    }
    .reminder-operate{
        font-size: 12px;
        margin-top: 10px;
        height: 16px;
        line-height: 16px;
        .operate-done{
            cursor: pointer;
            color: #38BC9D;
            float: left;
        }
        .operate-del{
            cursor: pointer;
            color: #999;
            float: left;
            margin-left: 40px;
        }
    }
}
.reminder-status-stage{
    overflow-y: auto;
    max-height: 174px;
}
</style>
