var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "occupy-ownership-dialog",
      attrs: {
        width: "608px",
        visible: _vm.isShow,
        "show-close": false,
        center: true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "content-title",
        },
        [
          _c(
            "p",
            [
              !_vm.isBatch
                ? [
                    _vm._v("确定抢占候选人"),
                    _c("span", { staticClass: "text-green" }, [
                      _vm._v(_vm._s(_vm.realName) + "的归属权吗？"),
                    ]),
                  ]
                : [
                    _vm._v("\n                确定抢占"),
                    _vm._l(_vm.candidateList, function (cadidate, index) {
                      return _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: index < 8,
                              expression: "index < 8",
                            },
                          ],
                          key: cadidate.resumeListItem.resumeId,
                          staticClass: "text-green",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(cadidate.resumeListItem.realName)
                          ),
                          (_vm.candidateList.length <= 8 &&
                            index < _vm.candidateList.length - 1) ||
                          (_vm.candidateList.length > 8 && index < 7)
                            ? _c("span", [_vm._v("、")])
                            : _vm._e(),
                        ]
                      )
                    }),
                    _vm._v("等"),
                    _c("span", { staticClass: "warning-color" }, [
                      _vm._v(" " + _vm._s(_vm.total)),
                    ]),
                    _vm._v("名候选人的归属权吗？\n            "),
                  ],
            ],
            2
          ),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.isShow = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }