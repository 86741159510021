var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.time
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-start",
                trigger: "hover",
                "popper-class": "operation-info",
                "visible-arrow": false,
                "open-delay": 700,
                offset: 500,
              },
              on: { show: _vm.showDetails },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("p", { staticClass: "operation-title" }, [
                    _c("span", [_vm._v("最近操作：")]),
                    _c("span", {
                      staticClass: "title-text",
                      domProps: { textContent: _vm._s(_vm.operationTitle) },
                    }),
                  ]),
                  _vm.operationContent
                    ? _c("p", {
                        staticClass: "operation-content",
                        domProps: { textContent: _vm._s(_vm.operationContent) },
                      })
                    : _vm._e(),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "operate-time",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [_vm._v(_vm._s(_vm._f("date")(_vm.time)))]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }