<template>
    <el-popover
        ref="recommendReasonPopover"
        placement="bottom-start"
        trigger="hover"
        popper-class="recommend-reason-popover talentpool-popover"
        :visible-arrow="false"
        :open-delay="700"
        width="380"
        :offset="500"
        @show="popoverShow($refs.recommendReasonPopover)">
        <div class="table-popper-header inquiry-log-header recommend-reason-popover-header">
            推荐原因
            <el-button
                icon="el-icon-edit-outline"
                @click="resumeOperation('editRecommendReason', {resume: resume})"
            >
                修改
            </el-button>
        </div>

        <div class="recommend-reason-content">
            {{resume.resumeListItem.tempRecommendReason}}
        </div>
        <span slot="reference">
            <span>{{resume.resumeListItem.tempRecommendReason}}</span>
        </span>
    </el-popover>
</template>
<script>
import emitter from '#/js/mixins/emitter.js';
import popover from '#/js/mixins/popover.js';
export default {
    computed:{
        
    },

    mixins: [emitter, popover],

    data() {
        return {}
    },

    props:{
        resume: Object
    },
    methods:{
        resumeOperation(command, param) {
            this.dispatch('TableWrapper', 'resume-operation', {
                command: command,
                data: param
            });
        },
    }
}
</script>
<style lang="scss">
.recommend-reason-popover.el-popover{
    padding: 20px;
    padding-right: 0;
    .recommend-reason-popover-header {
        margin-right: 20px;
        .el-icon-edit-outline{
            line-height: 18px;
            color: $primary;
            font-size: 18px;
        }
        span {
            position: relative;
            top: -2px;
        }
        .el-button:hover, .el-button:focus, .el-button:active {
            .el-icon-edit-outline{
                color: #fff;
            }
        }
    }
    .recommend-reason-content {
        max-height: 95px;
        overflow-y: auto;
        margin-right: 10px;
    }
}
</style>