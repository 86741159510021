var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attachments" },
    [
      _vm.resume.resumeListItem.resumeAttachments.length > 0
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-end",
                trigger: "hover",
                "popper-class":
                  "talentpool-attachments-popover talentpool-popover",
                "visible-arrow": false,
                "open-delay": 700,
                offset: 500,
                width: "280",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "table-popper-header inquiry-log-header" },
                    [
                      _vm._v("\n                附件\n                "),
                      _c(
                        "el-upload",
                        {
                          staticClass: "popover-attachment-upload",
                          attrs: {
                            action: _vm.formatUploadUrl(
                              "/Headhunting/Candidate/" +
                                _vm.resume.resumeListItem.resumeId +
                                "/Attachment/Add"
                            ),
                            data: _vm.token,
                            headers: _vm.fileUploadHeaders,
                            "show-file-list": false,
                            "with-credentials": true,
                            "on-success": _vm.handleUploadSuccess,
                            "on-error": _vm.handleUploadError,
                            "before-upload": _vm.handleBeforeUpload,
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "el-icon-circle-plus-outline add-itap-icon",
                          }),
                          _c("span", { staticClass: "attachments-text" }, [
                            _vm._v("添加"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "document" },
                    _vm._l(
                      _vm.resume.resumeListItem.resumeAttachments,
                      function (file, index) {
                        return _c(
                          "a",
                          {
                            key: index,
                            staticClass: "document-anchor",
                            on: {
                              click: function ($event) {
                                return _vm.downloadFile(
                                  file.fileId,
                                  _vm.resume.resumeListItem.resumeId
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "icon-font icon-talent_attachement",
                            }),
                            _c("span", {
                              staticClass: "document-title",
                              domProps: { textContent: _vm._s(file.fileName) },
                            }),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
              _c(
                "span",
                { attrs: { slot: "reference" }, slot: "reference" },
                [
                  _c("font-icon", {
                    staticClass: "table-body-icon-big",
                    attrs: { href: "#icon-talent_fujian" },
                  }),
                  _c("span", { staticClass: "attachments-text" }, [
                    _vm._v(
                      _vm._s(_vm.resume.resumeListItem.resumeAttachments.length)
                    ),
                  ]),
                ],
                1
              ),
            ]
          )
        : _c(
            "span",
            [
              _c(
                "el-upload",
                {
                  staticClass: "attachment-upload",
                  attrs: {
                    action: _vm.formatUploadUrl(
                      "/Headhunting/Candidate/" +
                        _vm.resume.resumeListItem.resumeId +
                        "/Attachment/Add"
                    ),
                    data: _vm.token,
                    headers: _vm.fileUploadHeaders,
                    "show-file-list": false,
                    "with-credentials": true,
                    "on-success": _vm.handleUploadSuccess,
                    "on-error": _vm.handleUploadError,
                    "before-upload": _vm.handleBeforeUpload,
                  },
                },
                [
                  _c("font-icon", {
                    staticClass: "table-body-icon-big",
                    attrs: { href: "#icon-talent_fujian_red" },
                  }),
                  _c("span", { staticClass: "attachments-text" }, [
                    _vm._v("添加"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }