var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "batch-operation" },
    [
      _c(
        "el-button",
        {
          staticClass: "batch-operation-btn batch-view",
          attrs: { disabled: _vm.selectList.length == 0 },
          on: {
            click: function ($event) {
              return _vm.batchOperate("batchView")
            },
          },
        },
        [_vm._v("\n        批量查看\n    ")]
      ),
      !_vm.isTrackingList
        ? _c(
            "el-button",
            {
              staticClass: "batch-operation-btn add-to-tracking-list",
              attrs: {
                type: "primary",
                icon: "el-icon-circle-plus",
                disabled: _vm.selectList.length == 0,
                loading: _vm.loading,
              },
              on: { click: _vm.batchAddToTrackingList },
            },
            [_vm._v("\n        加入到Tracking List\n    ")]
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          staticClass: "batch-operation-btn add-itap",
          attrs: { disabled: _vm.selectList.length == 0 },
          on: {
            click: function ($event) {
              return _vm.batchOperate("batchAddItap")
            },
          },
        },
        [_vm._v("\n        打标签\n    ")]
      ),
      _vm.user.isCFUser
        ? _c(
            "el-tooltip",
            {
              attrs: {
                effect: "light",
                placement: "top-start",
                "open-delay": 500,
              },
            },
            [
              _c("span", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v("\n            将带"),
                _c("span", { staticStyle: { color: "#F5A623" } }, [
                  _vm._v("【占】"),
                ]),
                _vm._v("的个人库简历导入企业库，抢占归属权\n        "),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "batch-operation-btn occupy-ownership",
                  attrs: {
                    disabled:
                      _vm.selectList.length == 0 ||
                      (_vm.selectList.length > 0 && _vm.cannotOccupy),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchOperate("batchOccupyOwnership")
                    },
                  },
                },
                [_vm._v("\n            抢占归属\n        ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isTrackingList
        ? _c(
            "el-tooltip",
            {
              attrs: {
                effect: "light",
                placement: "top-start",
                "open-delay": 500,
              },
            },
            [
              _c("span", {
                attrs: { slot: "content" },
                domProps: { textContent: _vm._s(_vm.disableShareTitle) },
                slot: "content",
              }),
              _c(
                "el-button",
                {
                  staticClass: "batch-operation-btn add-itap",
                  class: { "set-red": _vm.invalidTrackListShare },
                  staticStyle: { width: "142px" },
                  attrs: { disabled: _vm.selectList.length == 0 },
                  on: {
                    click: function ($event) {
                      return _vm.batchOperate("batchTrackListShare")
                    },
                  },
                },
                [
                  _vm._v("\n            分享Tracking List\n            "),
                  _vm.invalidTrackListShare
                    ? _c("i", {
                        staticClass: "el-icon-warning-outline",
                        staticStyle: { color: "red" },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isTrackingList
        ? _c(
            "el-button",
            {
              staticClass: "batch-operation-btn remove",
              attrs: { disabled: _vm.selectList.length == 0 },
              on: {
                click: function ($event) {
                  return _vm.batchOperate("batchRemove")
                },
              },
            },
            [_vm._v("\n        移除\n    ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }