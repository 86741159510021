<template>
    <div class="select-contianer">
        <!-- <div class="select-timelimit">
            <el-dropdown trigger="click" @command="handleCommand">
                    <span class="dropdown-title">
                        <font-icon class="talent-icon talent-icon-calendar" href="#icon-calendar"></font-icon>{{timeLimit}}<i
                        class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                <el-dropdown-menu slot="dropdown" class="timelimit-dropdown-menu">
                    <el-dropdown-item 
                        v-for="item in timeLimits" 
                        :key="item.name"
                        :class="{'bubble-date-selected':timeLimit==item.name}" 
                        :command="item">
                        {{item.name}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div> -->
        <div class="range-wrap">
            <date-range-picker 
                @date-picked="handleDatePicked"
                size="sm"
            />
        </div>

        <search-area 
            ref="searchArea" 
            :searchParams="searchParams" 
            :bubbleIsShow="bubbleIsShow" 
            :date="{startDate,endDate}" 
            @advancedShow="advancedShow"
            @handleCleanLabel="handleCleanLabel" 
            @handleAddLabel="handleAddLabel" 
            :table-type="tableType"
        ></search-area>

        <!-- <select-bubble 
            ref="selectBubble" 
            :class="{'isShow' : bubbleIsShow}" 
            v-show="bubbleIsShow" 
            :date="{startDate,endDate}" 
            @handleCleanLabel="handleCleanLabel" 
            @handleAddLabel="handleAddLabel" 
            :table-type="tableType"
        ></select-bubble> -->

        <div class="filter-area" :class="{'no-top-border': !bubbleIsShow}">
            <div class="filter-left-text">筛选条件：</div>
            <div 
                class="filter-cell"
                :class="{'hot-light': aCurrentLabel.includes(item)}"
                v-for="(item, index) in filterStore"
                :key="'filter_' + index"
                v-show="headerFilter[tableTitleMap[item].headerFilterName].length>0"
                >
                <select-popover
                    :maxSelect="5"
                    :enableCount="true"
                    :title="tableTitleMap[item].label"
                    :label="tableTitleMap[item].headerSelectLabel"
                    :enableSearch="tableTitleMap[item].headerSelectEnableSearch"
                    :selectItem="headerFilter[tableTitleMap[item].headerFilterName]"
                    @selectChange="selectDataChange"
                ></select-popover>
            </div>
        </div>

        <!-- <p
            v-show="bubbleIsShow && tableType == 'trackingList'"
            class="select-contianer-point-tip"
            v-if="$store.state.user.userInfo.isCFUser"
        >
            更新当前雇主公司和职位可得15积分
        </p> -->
    </div>
</template>

<script>
    import SelectBubble from './selectBubble.vue';
    import SearchArea from './searchArea.vue';
    import emitter from '#/js/mixins/emitter.js';
    import SelectPopover from '#/component/common/select-popover.vue';
    import DateRangePicker from '#/component/common/date-range-picker.vue';
    import { TalentOrTracking as tableTitleMap } from '#/js/config/tableTitleMap.json';
    import eventNameJson from '#/js/config/eventName.json';
    import moment from 'moment';

    const eventName = eventNameJson.talentPoolTable;
    const TODAY = moment().format('YYYY-MM-DD');
    const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");

    export default {
        name: "selectWrapper",

        componentName: "selectWrapper",

        components: {
            SelectBubble,
            SearchArea,
            SelectPopover,
            DateRangePicker,
        },
        mixins: [emitter],
        data() {
            return {
                timeLimit: '最近一周',
                startDate: '',
                endDate: '',
                timeLimits: [
                    // {name: '全部', value: 0},
                    {name: '最近一周', value: 7},
                    {name: '最近一个月', value: 30},
                    {name: '最近三个月', value: 90},
                    {name: '最近半年', value: 182},
                    {name: '最近一年', value: 365}
                ],
                bubbleIsShow: true,
                tableTitleMap: tableTitleMap,
                filterStore: ['location', 'yearOfExperience', 'company', 'degree', 'tag'],
                aCurrentLabel: [],
            };
        },
        props: {
            labelList: {
                type: Object,
                default: () => {},
            },
            searchParams: {
                type: Object,
                default: () => {},
            },
            tableType:String,
            headerFilter: {
                type: Object,
                default: () => {},
            },
        },
        mounted(){
            // this.handleCommand(this.timeLimits[0]);
            this.handleDatePicked([lastMonth, TODAY]);
        },
        methods : {
            handleCommand(item){
                this.timeLimit = item.name;
                let today = new Date();
                let endDate ='',startDate='';
                switch(item.value){
                    case 0:
                        endDate = '';
                        startDate = '';
                        break;
                    case 181:
                        endDate = new Date(today.setTime(today.getTime() - (item.value-1) * 24 * 60 * 60 * 1000));
                        endDate = moment(endDate).format('YYYY-MM-DD');
                        startDate = "1970-01-01";
                        break;
                    default:
                        endDate = moment(today).format('YYYY-MM-DD');
                        startDate = new Date(today.setTime(today.getTime() - (item.value-1) * 24 * 60 * 60 * 1000));
                        startDate = moment(startDate).format('YYYY-MM-DD');
                }
                this.startDate = startDate;
                this.endDate = endDate;
                this.$emit('setStartDate', {
                        startDate:this.startDate,
                        name:item.name
                        });

                setTimeout(() => {
                    this.dispatch('TableWrapper', 'getBubbleGroupedCount');
                }, 0)
            },
            handleDatePicked(dates) {
                this.$emit('setStartDate', dates);
                setTimeout(() => {
                    this.dispatch('TableWrapper', 'getBubbleGroupedCount');
                }, 0);
            },
            advancedShow (){
                this.bubbleIsShow = !this.bubbleIsShow;
            },
            handleCleanLabel (label, isEmit) {
                isEmit = isEmit || false;
                this.$emit('handleCleanLabel', label, isEmit);
            },
            handleAddLabel (label, params, isCover) {
                isCover = isCover || false;
                this.$emit('handleAddLabel',label,params,isCover);
            },
            transformHeaderFilterParams (params){
                for (let key in params){
                    params[key].text = params[key].label;
                }
                return params;
            },
            selectDataChange (label, result){
                const hashMap = {
                    citys: 'location',
                    workExperienceCompaniesName: 'company',
                    label: 'tag',
                };
                const _label = hashMap[label] || label;
                if(!this.aCurrentLabel.includes(_label)){
                    this.aCurrentLabel.push(_label);
                }

                
                result = this.transformHeaderFilterParams(result);
                if(result.length > 0){
                    return this.$emit(eventName.searchLabel.addLabel, label, result, true);
                } else {
                    return this.$emit(eventName.searchLabel.cleanLabel, label, true);
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "#/css/scss/variables.scss";

    .select-contianer{
        position: relative;
        margin: 10px 20px 0 20px;
        padding: 10px 20px;
        background-color: #fff;
        border-radius: 4px;

        &-point-tip {
            position: absolute;
            left: 20px;
            bottom: 10px;
            font-size: 14px;
            color: #EE6E4F; 
        }

        .range-wrap{
            position: absolute;
            left: 20px;
            top: 10px;
        }
    }

    .select-timelimit{
        position: absolute;
        .el-dropdown{
            cursor: pointer;
            font-size: 14px;
            color: #666666;
            background: #FFFFFF;
            border: 1px solid #38BC9D;
            border-radius: 4px;
            .dropdown-title{
                outline: none;
                width: 125px;
                height: 26px;
                line-height: 26px;
                padding: 0px 25px 0 10px;
                display: inline-block;
                text-align: right;
                .talent-icon-calendar{
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 10px;
                    top: 45%;
                    transform: translateY(-50%);
                }
                .el-icon--right{
                    position: absolute;
                    right: 10px;
                    top: 6px;
                }
            }
        }
    }

    .timelimit-dropdown-menu{
        padding: 0;
        user-select: none;
        margin-top: 5px;
        .el-dropdown-menu__item.bubble-date-selected{
            background-color: rgba(56,188,157,0.1);
            color: $primary;
        }
        .popper__arrow {
            display: none;
        }
    }
    .filter-area{
        // background: red;
        border-top: 1px dashed #CCC;
        box-sizing: border-box;
        padding-top: 10px;
        .filter-left-text{
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
        }
        .filter-cell{
            display: inline-block;
            margin-right: 20px;
        }
        .hot-light{
            color: #38BC9D;
        }
        &.no-top-border{
            border-top: 1px dashed transparent;
        }
    }   
</style>
