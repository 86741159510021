var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: { placement: "bottom", width: "240", trigger: "click" },
      on: { hide: _vm.clearSearch },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "el-input",
        {
          staticClass: "search-advanced-params",
          attrs: {
            slot: "reference",
            placeholder: _vm.placeholder,
            readonly: _vm.readonly,
          },
          slot: "reference",
          model: {
            value: _vm.selectedValue,
            callback: function ($$v) {
              _vm.selectedValue = $$v
            },
            expression: "selectedValue",
          },
        },
        [
          _vm.iconClass
            ? _c("i", {
                staticClass: "icon-font",
                class: _vm.iconClass,
                attrs: { slot: "prefix" },
                slot: "prefix",
              })
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "single-select-wrap" },
        [
          _c("el-input", {
            staticClass: "select-search",
            attrs: { size: "small", placeholder: "请输入关键字" },
            model: {
              value: _vm.searchValue,
              callback: function ($$v) {
                _vm.searchValue = $$v
              },
              expression: "searchValue",
            },
          }),
          _c(
            "ul",
            { staticClass: "single-select-list" },
            _vm._l(_vm.selectItems, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "single-select-item",
                  class: item.isSelected ? "is-selected" : "",
                  on: {
                    click: function ($event) {
                      return _vm.handleSelectItem(item, index)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(item.value) + "\n            "
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }