var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "remove-dialog",
      attrs: {
        width: "608px",
        visible: _vm.isShow,
        "show-close": false,
        center: true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c("div", { staticClass: "remove-modal-content" }, [
        _c("div", { staticClass: "content-title" }, [
          _c(
            "p",
            [
              _vm._v("\n                确定将\n                "),
              !_vm.isBatch
                ? [
                    _vm._v("\n                    候选人"),
                    _c("span", { staticClass: "text-green" }, [
                      _vm._v(_vm._s(_vm.realName)),
                    ]),
                  ]
                : [
                    _vm._l(_vm.candidateList, function (cadidate, index) {
                      return _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: index < 8,
                              expression: "index < 8",
                            },
                          ],
                          key: cadidate.id,
                          staticClass: "text-green",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(cadidate.resumeListItem.realName)
                          ),
                          (_vm.candidateList.length <= 8 &&
                            index < _vm.candidateList.length - 1) ||
                          (_vm.candidateList.length > 8 && index < 7)
                            ? _c("span", [_vm._v("、")])
                            : _vm._e(),
                        ]
                      )
                    }),
                    _vm._v("\n                    等\n                    "),
                    _c("span", { staticClass: "warning-color" }, [
                      _vm._v(" " + _vm._s(_vm.total)),
                    ]),
                    _vm._v("\n                    名候选人\n                "),
                  ],
              _vm._v(
                "\n                移除Tracking List吗？移除后将不可恢复，请谨慎操作。\n            "
              ),
            ],
            2
          ),
        ]),
        _c("p", { staticClass: "content-tip" }, [
          _c("span", { staticClass: "warning-color" }, [_vm._v("注意：")]),
          _vm._v(
            "\n            移除候选人可重新手动加至tracking list\n        "
          ),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.isShow = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }