<template>
    <el-dialog
        class="occupy-ownership-dialog"
        width="608px"
        :visible.sync="isShow"
        :show-close="false"
        :center="true">
        <div
            class="content-title"
            v-loading="loading">
            <p>
                
                <template v-if="!isBatch">确定抢占候选人<span class="text-green">{{realName}}的归属权吗？</span></template>
                <template v-else>
                    确定抢占<span class="text-green" v-for="(cadidate, index) in candidateList" :key="cadidate.resumeListItem.resumeId" v-show="index < 8">
                        {{cadidate.resumeListItem.realName}}<span v-if="(candidateList.length <= 8 && index < candidateList.length - 1) || (candidateList.length > 8 && index < 7)">、</span>
                    </span>等<span class="warning-color"> {{total}}</span>名候选人的归属权吗？
                </template>
            </p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="isShow = false">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import talentPoolService from '#/js/service/talentPoolService.js';

export default {
    name: 'occupy-ownership-dialog',
    data(){
        return{
            isShow: false,
            isBatch: false,
            ids: [],
            total: 0,
            candidateList: [],
            realName: '',
            loading: false
        }
    },
    methods:{
        show(isBatch){
            this.isBatch = isBatch;
            if(isBatch) {
                this.total = this.candidateList.length;
                let idArray = [];
                this.candidateList.forEach((item) => {
                    idArray.push(item.resumeListItem.resumeId);
                });
                this.ids = idArray;
            } else {
                this.ids.push(this.candidateList[0].resumeListItem.resumeId);
                this.realName = this.candidateList[0].resumeListItem.realName;
            }
            this.isShow = true;
        },
        handleConfirm(){
            this.loading = true;
            talentPoolService.importToFirm({
                tbdResumeIds: this.ids
            }).then((res) => {
                this.loading = false;
                if(res) {
                    let message = `抢占成功 ${res.successCount} 份`;
                    if(res.failCount > 0) {
                        // let errorMessage = '';
                        // res.list.forEach(item => {
                        //     if(!item.successed) {
                        //         errorMessage = item.errorMessage;
                        //         return;
                        //     }
                        // });
                        // message += `\<br\>失败 ${res.failCount} 份\<br\>失败原因：${errorMessage}`;
                        message += `\<br\>失败 ${res.failCount} 份\<br\>失败原因：简历信息不完整，姓名、手机、公司、职位、城市为必须项！`;
                    }
                    this.isShow = false;
                    this.$alert(message, '抢占结果', {
                        showClose: false,
                        dangerouslyUseHTMLString: true
                    });
                    if(this.isBatch) {
                        this.$emit('update-list');
                    } else {
                        this.$emit('update-item', this.candidateList[0]);
                    }
                }
            }).catch((err) => {
                this.loading = false;
                this.isShow = false;
                console.log(err);
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.occupy-ownership-dialog {
    .text-green {
        color: $primary;
    }
    .warning-color{
        color: #FC7859;
    }
    .content-title {
        margin-bottom: 10px;
        line-height: 24px;
        font-size: 16px;
    }
}
</style>
<style lang="scss">
.occupy-ownership-dialog {
    .el-dialog {
        height: 264px;
    }
    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        padding: 80px 74px 50px;
        color: #444;
    }
}
</style>
