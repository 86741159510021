var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "resumeOperation",
      attrs: {
        placement: "bottom-start",
        trigger: "hover",
        "hide-on-click": false,
      },
      on: { command: _vm.handleCommand, "visible-change": _vm.visibleChange },
    },
    [
      _c(
        "span",
        { staticClass: "operation-icon" },
        [
          _c("font-icon", {
            staticClass: "table-body-icon operation-icon-default",
            attrs: { href: "#icon-ic_act_grey" },
          }),
          _c("font-icon", {
            staticClass: "table-body-icon operation-icon-hover",
            attrs: { href: "#icon-ic_act_green" },
          }),
        ],
        1
      ),
      _vm.type == 0
        ? _c(
            "el-dropdown-menu",
            {
              staticClass: "operation-menu",
              attrs: { slot: "dropdown" },
              slot: "dropdown",
            },
            [
              !_vm.resume.resumeListItem.isTracking
                ? _c(
                    "el-dropdown-item",
                    { attrs: { command: "addToTrackingList" } },
                    [_vm._v("\n            加入Tracking List\n        ")]
                  )
                : _vm._e(),
              _vm.resume.resumeListItem.isTracking
                ? _c(
                    "el-dropdown-item",
                    { attrs: { command: "removeTrackingList" } },
                    [_vm._v("\n            移除Tracking List\n        ")]
                  )
                : _vm._e(),
              _c("el-dropdown-item", { attrs: { command: "addSchedule" } }, [
                _vm._v("\n            添加提醒事项\n        "),
              ]),
              !_vm.resume.resumeListItem.isFromAuthorization &&
              (!_vm.isCfUser ||
                (_vm.isCfUser && _vm.resume.resumeListItem.resumeGrabType == 1))
                ? _c(
                    "el-dropdown-item",
                    { attrs: { command: "addToCompany" } },
                    [_vm._v("导入企业库")]
                  )
                : _vm._e(),
              _vm.isCfUser && _vm.resume.resumeListItem.resumeGrabType == 2
                ? _c(
                    "el-dropdown-item",
                    { attrs: { command: "updateCompanyResume" } },
                    [_vm._v("更新企业库已有简历")]
                  )
                : _vm._e(),
              _c("el-dropdown-item", { attrs: { command: "recommend" } }, [
                _vm._v("推荐"),
              ]),
              !_vm.resume.resumeListItem.isFromAuthorization
                ? _c("el-dropdown-item", { attrs: { command: "edit" } }, [
                    _vm._v("编辑"),
                  ])
                : _vm._e(),
              _vm.resume.resumeListItem.isFirmResume &&
              !_vm.resume.resumeListItem.isFromAuthorization
                ? _c(
                    "el-dropdown-item",
                    { staticClass: "share-item", attrs: { command: "share" } },
                    [
                      _vm._v("\n            分享\n            "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isShowShareCard,
                              expression: "isShowShareCard",
                            },
                          ],
                          staticClass: "share-card-contain",
                        },
                        [
                          _c("div", { staticClass: "share-card" }, [
                            _c("img", {
                              staticClass: "share-card-code",
                              attrs: {
                                width: "98",
                                height: "98",
                                src: _vm.qrcodeFormatter(
                                  _vm.resume.resumeListItem.candidateId
                                ),
                                alt: "二维码名片",
                              },
                            }),
                            _c("p", [_vm._v("扫码转发简历到微信")]),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.resume.resumeListItem.collected
                ? _c("el-dropdown-item", { attrs: { command: "collect" } }, [
                    _vm._v("收藏"),
                  ])
                : _c(
                    "el-dropdown-item",
                    { attrs: { command: "cancelCollect" } },
                    [_vm._v("取消收藏")]
                  ),
            ],
            1
          )
        : _c(
            "el-dropdown-menu",
            {
              staticClass: "operation-menu tracking-list-menu",
              class:
                _vm.isCfUser && _vm.resume.resumeListItem.resumeGrabType == 2
                  ? "large-width-menu"
                  : !_vm.resume.resumeListItem.isFirmResume
                  ? "middle-width-menu"
                  : "",
              attrs: { slot: "dropdown" },
              slot: "dropdown",
            },
            [
              _c("el-dropdown-item", { attrs: { command: "recommend" } }, [
                _vm._v("推荐"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "addItap" } }, [
                _vm._v("打标签"),
              ]),
              _vm.resume.resumeListItem.isFromAuthorization
                ? _c("el-dropdown-item", { attrs: { command: "edit" } }, [
                    _vm._v("编辑"),
                  ])
                : _vm._e(),
              _vm.resume.resumeListItem.isFirmResume &&
              !_vm.resume.resumeListItem.isFromAuthorization
                ? _c(
                    "el-dropdown-item",
                    { staticClass: "share-item", attrs: { command: "share" } },
                    [
                      _vm._v("\n            分享\n            "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isShowShareCard,
                              expression: "isShowShareCard",
                            },
                          ],
                          staticClass: "share-card-contain",
                        },
                        [
                          _c("div", { staticClass: "share-card" }, [
                            _c("img", {
                              staticClass: "share-card-code",
                              attrs: {
                                width: "98",
                                height: "98",
                                src: _vm.qrcodeFormatter(
                                  _vm.resume.resumeListItem.candidateId
                                ),
                                alt: "二维码名片",
                              },
                            }),
                            _c("p", [_vm._v("扫码转发简历到微信")]),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c("el-dropdown-item", { attrs: { command: "callPhone" } }, [
                _vm._v("拨打电话"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "addSchedule" } }, [
                _vm._v("添加提醒事项"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "addVisit" } }, [
                _vm._v("添加寻访"),
              ]),
              !_vm.resume.resumeListItem.isFromAuthorization &&
              (!_vm.isCfUser ||
                (_vm.isCfUser && _vm.resume.resumeListItem.resumeGrabType == 1))
                ? _c(
                    "el-dropdown-item",
                    { attrs: { command: "addToCompany" } },
                    [_vm._v("导入企业库")]
                  )
                : _vm._e(),
              _vm.isCfUser && _vm.resume.resumeListItem.resumeGrabType == 2
                ? _c(
                    "el-dropdown-item",
                    { attrs: { command: "updateCompanyResume" } },
                    [_vm._v("更新企业库已有简历")]
                  )
                : _vm._e(),
              _c(
                "el-dropdown-item",
                { attrs: { command: "removeTrackingList" } },
                [_vm._v("移除")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }