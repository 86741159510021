<template>
    <el-dropdown
        ref="resumeOperation"
        placement="bottom-start"
        @command="handleCommand"
        v-loading="loading"
        trigger="hover"
        :hide-on-click="false"
        @visible-change="visibleChange">
        <span class="operation-icon">
            <font-icon class="table-body-icon operation-icon-default" href="#icon-ic_act_grey"></font-icon>
            <font-icon class="table-body-icon operation-icon-hover" href="#icon-ic_act_green"></font-icon>
        </span>
        <el-dropdown-menu class="operation-menu" slot="dropdown" v-if="type == 0">
            <el-dropdown-item
                command="addToTrackingList"
                v-if="!resume.resumeListItem.isTracking"
            >
                加入Tracking List
            </el-dropdown-item>
            <el-dropdown-item
                command="removeTrackingList"
                v-if="resume.resumeListItem.isTracking"
            >
                移除Tracking List
            </el-dropdown-item>
            <el-dropdown-item
                command="addSchedule"
            >
                添加提醒事项
            </el-dropdown-item>
            <el-dropdown-item command="addToCompany" v-if="!resume.resumeListItem.isFromAuthorization && (!isCfUser || (isCfUser && resume.resumeListItem.resumeGrabType == 1))">导入企业库</el-dropdown-item>
            <el-dropdown-item command="updateCompanyResume" v-if="isCfUser && resume.resumeListItem.resumeGrabType == 2">更新企业库已有简历</el-dropdown-item>
            <el-dropdown-item command="recommend">推荐</el-dropdown-item>
            <el-dropdown-item command="edit" v-if="!resume.resumeListItem.isFromAuthorization">编辑</el-dropdown-item>
            <el-dropdown-item class="share-item" command="share" v-if="resume.resumeListItem.isFirmResume && !resume.resumeListItem.isFromAuthorization">
                分享
                <div class="share-card-contain" v-show="isShowShareCard">
                    <div class="share-card">
                        <img width="98" height="98" :src="qrcodeFormatter(resume.resumeListItem.candidateId)" alt="二维码名片" class="share-card-code">
                        <p>扫码转发简历到微信</p>
                    </div>
                </div>
            </el-dropdown-item>
            <el-dropdown-item command="collect" v-if="!resume.resumeListItem.collected">收藏</el-dropdown-item>
            <el-dropdown-item command="cancelCollect" v-else>取消收藏</el-dropdown-item>
        </el-dropdown-menu>
        <el-dropdown-menu
            class="operation-menu tracking-list-menu"
            :class="isCfUser && resume.resumeListItem.resumeGrabType == 2 ? 'large-width-menu' : (!resume.resumeListItem.isFirmResume ? 'middle-width-menu' :  '')"
            slot="dropdown"
            v-else>
            <el-dropdown-item command="recommend">推荐</el-dropdown-item>
            <el-dropdown-item command="addItap">打标签</el-dropdown-item>
            <el-dropdown-item command="edit" v-if="resume.resumeListItem.isFromAuthorization">编辑</el-dropdown-item>
            <el-dropdown-item class="share-item" command="share" v-if="resume.resumeListItem.isFirmResume && !resume.resumeListItem.isFromAuthorization">
                分享
                <div class="share-card-contain" v-show="isShowShareCard">
                    <div class="share-card">
                        <img width="98" height="98" :src="qrcodeFormatter(resume.resumeListItem.candidateId)" alt="二维码名片" class="share-card-code">
                        <p>扫码转发简历到微信</p>
                    </div>
                </div>
            </el-dropdown-item>
            <el-dropdown-item command="callPhone">拨打电话</el-dropdown-item>
            <el-dropdown-item command="addSchedule">添加提醒事项</el-dropdown-item>
            <el-dropdown-item command="addVisit">添加寻访</el-dropdown-item>
            <el-dropdown-item command="addToCompany" v-if="!resume.resumeListItem.isFromAuthorization && (!isCfUser || (isCfUser && resume.resumeListItem.resumeGrabType == 1))">导入企业库</el-dropdown-item>
            <el-dropdown-item command="updateCompanyResume" v-if="isCfUser && resume.resumeListItem.resumeGrabType == 2">更新企业库已有简历</el-dropdown-item>
            <el-dropdown-item command="removeTrackingList">移除</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import talentPoolService from '@src/js/service/talentPoolService.js';
import emitter from '@src/js/mixins/emitter';

export default {
    name: 'resume-operation',

    mixins: [emitter],

    props: {
        tableType: String,
        type: {
            default: 0
        },
        resume: Object,
    },
    data() {
        return {
            loading: false,
            existedCandidateId: "",
            existedResumeId: "",
            isShowShareCard: false
        }
    },
    computed: {
        isCfUser() {
            return this.$store.state.user.userInfo.isCFUser;
        }
    },
    methods: {
        qrcodeFormatter(candidateId) {
            return `${_host.portal}/Sharing/QRCode?url=` + encodeURIComponent(`${_host.h5_page}/candidateShare.html?candidateId=${candidateId}`);
        },
        handleCommand(command) {
            let eventName = this.tableType === 'talentPool' ? 'TalentPoolExecuteOperation' : 'TrackingListExecuteOperation',isFirmResume = this.resume.resumeListItem.isFirmResume;
            this.isShowShareCard = false;
            switch(command) {
                //加入tracking-list
                case 'addToTrackingList':
                    this.addToTrackingList();
                    _tracker.track(eventName, JSON.stringify({
                        operationType: 'addToTrackingList',
                        operationText: '加入TrackingList',
                        isFirmResume: isFirmResume
                    }));
                    break;
                //导入企业库/共享至企业库
                case 'addToCompany': this.addToCompany(); break;
                //推荐
                case 'recommend':
                    this.recommend();
                    _tracker.track(eventName, JSON.stringify({
                        operationType: 'recommend',
                        operationText: '推荐',
                        isFirmResume: isFirmResume
                    }));
                    break;
                //编辑
                case 'edit':
                    this.edit();
                    _tracker.track(eventName, JSON.stringify({
                        operationType: 'edit',
                        operationText: '编辑',
                        isFirmResume: isFirmResume
                    }));
                    break;
                //分享
                case 'share':
                    this.isShowShareCard = true;
                    _tracker.track(eventName, JSON.stringify({
                        operationType: 'share',
                        operationText: '二维码分享',
                        isFirmResume: isFirmResume
                    }));
                    break;
                //收藏
                case 'collect':
                    this.collect();
                    _tracker.track(eventName, JSON.stringify({
                        operationType: 'collect',
                        operationText: '收藏',
                        isFirmResume: isFirmResume
                    }));
                    break;
                //取消收藏
                case 'cancelCollect':
                    this.cancelCollect();
                    _tracker.track(eventName, JSON.stringify({
                        operationType: 'cancelCollect',
                        operationText: '取消收藏',
                        isFirmResume: isFirmResume
                    }));
                    break;
                //更新企业库已有简历
                case 'updateCompanyResume':
                    this.updateCompanyResume();
                    _tracker.track(eventName, JSON.stringify({
                        operationType: 'updateCompanyResume',
                        operationText: '更新企业库已有简历',
                        isFirmResume: isFirmResume
                    }));
                    break;
                case 'callPhone':
                    this.callPhone();
                    _tracker.track('TrackingListExecuteOperation', JSON.stringify({
                        operationType: 'callPhone',
                        operationText: '拨打电话'
                    }));
                //将其他展示弹窗交互的操作传到父组件处理
                default: {
                    this.dispatch('TableWrapper', 'resume-operation', {
                        command: command,
                        data: {
                            resume: this.resume
                        }
                    });
                }
            }
        },
        addToTrackingList() {
            let resumeIds = [];
            resumeIds.push(this.resume.resumeListItem.resumeId);
            this.loading = true;
            talentPoolService.addToTrackingList({
                sourceFrom: 1,
                resumeIds: resumeIds
            }).then((res) => {
                this.loading = false;
                if(res) {
                    let message = res.existCount == 1 ? '该候选人已在trackinglist' : (res.successCount == 1 ? '已成功加入trackinglist' : '加入失败，请重新尝试');
                    shortTips(message);
                    this.dispatch('TableWrapper', 'update-item', this.resume);

                    // 加入TrackingList成功之后弹窗打标签
                    this.dispatch('TableWrapper', 'resume-operation', {
                        command: 'addItap',
                        data: {
                            resume: this.resume
                        }
                    });
                }
            }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        //导入企业库 请求函数
        importToFirm() {
            let resumeIds = [];
            resumeIds.push(this.resume.resumeListItem.resumeId);
            this.loading = true;
            return talentPoolService.importToFirm({
                tbdResumeIds: resumeIds
            });
        },
        addToCompany() {
            this.$confirm('确定导入到企业人才库？', '提示', {
                showClose: false,
                cancelButtonText: '取消',
                confirmButtonText: '确定'
            }).then(() => {
                this.importToFirm().then((res) => {
                    this.loading = false;
                    if(res) {
                        // let message = `抢占成功 ${res.successCount} 份`;
                        // if(res.failCount > 0) {
                        //     let errorMessage = '';
                        //     res.list.forEach(item => {
                        //         if(!item.successed) {
                        //             errorMessage = item.errorMessage;
                        //             return;
                        //         }
                        //     });
                        //     message += `\<br\>失败 ${res.failCount} 份\<br\>失败原因：${errorMessage}`;
                        // }
                        // this.$alert(message, '抢占结果', {
                        //     showClose: false,
                        //     dangerouslyUseHTMLString: true
                        // });
                        this.dispatch('TableWrapper', 'resume-operation', {
                            command: 'addToCompany',
                            data: {
                                resume: this.resume,
                                importResult: res
                            }
                        });
                    }
                }).catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
            }).catch(() => {});
        },
        recommend() {
            let resume = this.resume.resumeListItem;
            if(!resume.isFirmResume) {
                this.$route.params.isFirm = false;
                //个人库简历需要先判断企业库中是否已有该简历，如果没有，走 导入企业库->推荐 流程
                if(resume.resumeGrabType == 1){
                    this.$confirm('简历需要导入企业库后才能完成推荐操作，是否将简历导入企业库并继续推荐？', '推荐提示', {
                        showClose: false,
                        customClass: 'comfirm-dialog-class',
                        cancelButtonText: '取消',
                        confirmButtonText: '导入并推荐候选人'
                    }).then(() => {
                        this.importToFirm().then((res) => {
                            this.loading = false;
                            if(res) {
                                let importResult = res;
                                if(importResult.failCount == 0) {
                                    this.dispatch('TableWrapper', 'update-item', this.resume);
                                    this.dispatch('TableWrapper', 'resume-operation', {
                                        command: 'recommend',
                                        data: {
                                            resume: this.resume,
                                            candidateId: res.list[0].id
                                        }
                                    });
                                } else{
                                    let errorMessage = '';
                                    importResult.list.forEach(item => {
                                        if(!item.successed) {
                                            errorMessage = item.errorMessage;
                                            return;
                                        }
                                    });
                                    shortTips(errorMessage);
                                }
                            }
                        }).catch((err) => {
                            this.loading = false;
                            console.log(err);
                        });
                    }).catch(() => {});
                } else {
                    // 已在企业库中存在的个人库简历 需要引导用户使用企业库简历进行推荐
                    this.$route.params.isFirm = true;
                    this.$confirm('企业库中已有该候选人，请使用企业库简历进行推荐', '推荐提示', {
                        showClose: false,
                        customClass: 'comfirm-dialog-class',
                        cancelButtonText: '取消',
                        confirmButtonText: '打开企业库简历'
                    }).then(() => {
                        this.getExistId(() => {
                            // window.open(`/Headhunting/MyCompany.html#/candidateDetail/${this.existedCandidateId}`, '_blank');
                            window.open(`#/candidateDetail/${this.existedCandidateId}`, '_blank');
                        })
                   }).catch(() => {});
                }
            } else {
                //企业库简历直接走推荐流程
                this.$route.params.isFirm = true;
                this.dispatch('TableWrapper', 'resume-operation', {
                    command: 'recommend',
                    data: {
                        resume: this.resume,
                        candidateId: this.resume.resumeListItem.candidateId
                    }
                });
            }
        },
        edit() {
            if(this.resume.resumeListItem.isFirmResume) {
                // window.open(`/Headhunting/MyCompany.html?type=1&candidateId=${this.resume.resumeListItem.candidateId}#/resumeUpload/singleUpload`, '_blank'); 
                window.open(`/?type=1&candidateId=${this.resume.resumeListItem.candidateId}#/resumeUpload/singleUpload`, '_blank'); 
            } else {
                // window.open(`/Headhunting/MyCompany.html?type=0&candidateId=${this.resume.resumeListItem.resumeId}#/resumeUpload/singleUpload`, '_blank'); 
                window.open(`/?type=0&candidateId=${this.resume.resumeListItem.resumeId}#/resumeUpload/singleUpload`, '_blank'); 
            }
        },
        collect() {
            this.loading = true;
            talentPoolService.resumeCollect({
                resumeId: this.resume.resumeListItem.resumeId
            }).then((res) => {
                this.loading = false;
                shortTips("收藏成功");
                this.dispatch('TableWrapper', 'update-item', this.resume);
            }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        cancelCollect() {
            this.$confirm(`确定要取消收藏候选人${this.resume.resumeListItem.realName}？`, '提示', {
                showClose: false,
                cancelButtonText: '取消',
                confirmButtonText: '确定'
            }).then(() => {
                this.loading = true;
                talentPoolService.resumeUncollect({
                    resumeId: this.resume.resumeListItem.resumeId
                }).then((res) => {
                    this.loading = false;
                    shortTips("取消收藏成功");
                    this.dispatch('TableWrapper', 'update-item', this.resume);
                }).catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
            }).catch(() => {});
        },
        updateCompanyResume() {
            this.loading = true;
            _request({
                url: `/ResumeDetail/${this.resume.resumeListItem.resumeId}`,
                method: 'GET',
                date: {
                    _: new Date().getTime()
                }
            }).then((res) => {
                let resumeListItem = this.resume.resumeListItem;
                let originalResume = {
                    autoApprovelTrading: true,
                    city: 0,
                    company: "",
                    completionScore: 0,
                    content: "",
                    degree: "",
                    educationExperiences: [],
                    email: "",
                    employerInfo: "",
                    existedId: "",
                    expectSalary: "",
                    extraInfo: "",
                    fileId: "",
                    gender: 1,
                    intentions: [],
                    isExisted: true,
                    jobHoppingOccasion: "",
                    major: "",
                    mobile: "",
                    projectExperiences: [],
                    province: 213,
                    realName: "",
                    resumeId: "",
                    school: "",
                    selfEvaluation: "",
                    source: "",
                    title: "",
                    workExperiences: [],
                    yearOfExperience: 7,
                    __RequestVerificationToken: ""
                };
                for(let key in originalResume) {
                    if(res[key]){
                        originalResume[key] = res[key];
                    }
                }
                originalResume.completionScore = resumeListItem.absoluteCompletion;
                this.getExistId(() => {
                    originalResume.resumeId = this.existedResumeId;
                    originalResume.existedId = this.existedCandidateId;
                    originalResume.__RequestVerificationToken = this.$store.state.verificationToken.verificationToken;
                    sessionStorage.setItem(encodeURIComponent('singleEditFile'), JSON.stringify(originalResume));
                    this.loading = false;
                    window.open(`/Headhunting/MyCompany.html#/candidate/updatecheck?file=singleEditFile&originalResumeId=${originalResume.resumeId}`, '_blank');
                    // window.open(`/#/candidate/updatecheck?file=singleEditFile&originalResumeId=${originalResume.resumeId}`, '_blank');
                });
            }).catch(err => {
                this.loading = false;
                console.log(err);
            });
        },
        getExistId(cd) {
            if(!this.existedCandidateId && !this.existedResumeId) {
                talentPoolService.getMoreResume({
                    talentId: this.resume.resumeListItem.talentId
                }).then((res) =>{
                    if(res) {
                        let resumeListItem = res.find((item) => {
                            if(item.isFirmResume) {
                                return item;
                            }
                        });
                        this.existedCandidateId = resumeListItem.candidateId;
                        this.existedResumeId = resumeListItem.resumeId;
                        cd();
                    }
                }).catch(err => {
                    console.log(err);
                });
            } else {
                cd();
            }
        },
        callPhone() {
            let mobileStatus = this.resume.resumeListItem.contactStatus.mobileStatus;
            let isAdministrator =  this.$store.state.user.userInfo.isAdministrator;
            switch(mobileStatus) {
                case 1:
                    //点击查看号码
                    talentPoolService.viewContact(this.resume.resumeListItem.candidateId, {
                        type: 1,
                        __RequestVerificationToken: this.$store.state.verificationToken
                    }).then((res) => {
                        this.resume.resumeListItem.mobile = res;
                        this.handleCallPhone();
                    }).catch((err) => {
                        console.log(err);
                    })
                    break;
                case 2:
                    shortTips(isAdministrator ? '拨打失败，候选人正在入职保证期中，联系方式已上锁，您可以进行解锁' : '拨打失败，候选人正在入职保证期中，联系方式已上锁');
                    break;
                case 3:
                    //点击拨打
                    this.handleCallPhone();
                    break;
                case 4:
                    shortTips("拨打失败，该号码已失效");
                    break;
            }
        },
        handleCallPhone() {
            this.$emit('call-phone', this.resume.resumeListItem);
        },
        visibleChange(visible) {
            if(!visible) {
                this.isShowShareCard = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .more-operation-icon {
        color: #666;
        font-size: 12px;
        &:hover {
            color: $primary;
        }
    }
</style>
<style lang="scss">
.operation-menu.el-dropdown-menu {
    .share-item {
        .share-card-contain {
            position: absolute;
            top: 0;
            left: 100%;
            width: 160px;
            height: 152px;
            cursor: default;
            .share-card {
                width: 152px;
                height: 152px;
                padding: 20px 13px 10px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
                border-radius: 4px;
                margin-left: 8px;
                background-color: #fff;
                & > p {
                    font-size: 14px;
                    color: #666;
                    line-height: 1;
                }
            }
        }
    }
}
.tracking-list-menu.operation-menu.el-dropdown-menu {
    width: 224px;
    text-align: left;
    .el-dropdown-menu__item {
        display: inline-block;
        width: 97px;
        &:nth-of-type(2n) {
            width: 125px;
        }
    }
    .el-dropdown-menu__item:nth-of-type(2n+1) {
        border-right: 1px dotted #ddd;
    }
    &.middle-width-menu {
        .el-dropdown-menu__item {
            &:nth-of-type(2n+1) {
                width: 125px;
            }
            &:nth-of-type(2n) {
                width: 97px;
            }
        }
    }
    &.large-width-menu {
        width: 266px;
        .el-dropdown-menu__item {
            &:nth-of-type(2n+1) {
                width: 167px;
            }
            &:nth-of-type(2n) {
                width: 97px;
            }
        }
    }
}
// .comfirm-dialog-class.el-message-box {
//     padding-bottom: 30px;
//     width: 608px;
//     height: 320px;
//     .el-message-box__header {
//         border-bottom: 1px solid #ececec;
//         height: 64px;
//         padding: 28px 20px 10px;
//     }
//     .el-message-box__content {
//         padding: 50px 58px;
//         min-height: 150px;
//     }
//     .el-message-box__btns {
//         text-align: center;

//         .el-button--default {
//             width: 132px;
//             height: 36px;
//             padding: 0;
//             line-height: 36px;
//             font-size: 14px;
//             color: #999;
//         }
//         & button:nth-child(2) {
//             margin-left: 20px;
//             color: #fff;
//         }
//     }
// }
</style>
