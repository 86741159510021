var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "talent-table-wrapper" },
    [
      _c("select-wrapper", {
        ref: "selectWrapper",
        attrs: {
          labelList: _vm.labelList,
          searchParams: _vm.searchAreaParam,
          "table-type": _vm.tableType,
          headerFilter: _vm.headerFilter,
        },
        on: {
          handleCleanLabel: _vm.handleCleanLabel,
          handleAddLabel: _vm.handleAddLabel,
          setStartDate: _vm.setStartDate,
        },
      }),
      _c("search-label", {
        ref: "searchLabel",
        attrs: {
          count: _vm.page.total,
          labelList: _vm.labelList,
          tableType: _vm.tableType,
          "title-store": _vm.titleStore,
          "table-title": _vm.tableTitleJson,
          "can-grab-count": _vm.canGrabCount,
          searchParams: _vm.searchAreaParam,
          "job-hunting-count": _vm.jobHuntingCount,
          orderLabelShowList: _vm.orderLabelShowList,
        },
        on: {
          "table-title": _vm.handleTableTitle,
          handleAddLabel: _vm.handleAddLabel,
          handleCleanLabel: _vm.handleCleanLabel,
        },
      }),
      _c("talent-pool-table", {
        ref: "talentPoolTable",
        attrs: {
          page: _vm.page,
          "table-data": _vm.newTableData,
          "table-type": _vm.tableType,
          "title-store": _vm.titleStore,
          headerFilter: _vm.headerFilter,
        },
        on: {
          "update-item": _vm.updateItem,
          "view-mail": _vm.handleViewMail,
          "get-reminder": _vm.getReminder,
          "page-change": _vm.handlePageChange,
          "jump-detail": _vm.handleJumpDetail,
          "view-mobile": _vm.handleViewMobile,
          handleAddLabel: _vm.handleAddLabel,
          handleCleanLabel: _vm.handleCleanLabel,
          "empty-time-select": _vm.handleEmptyTime,
          "resume-operation": _vm.resumeOperationDetail,
          "trackinglist-operate-change": _vm.sortTypeChange,
        },
      }),
      _c("create-notice-dialog", {
        ref: "createNoticeDialog",
        on: { "update-item": _vm.updateItem },
      }),
      _c("import-result-dialog", {
        ref: "importResultDialog",
        attrs: { isOccupy: true, isTextContext: true },
        on: { "import-then-compare": _vm.goToCompare },
      }),
      _c("recommend-dialog", { ref: "recommendDialog", attrs: { type: 0 } }),
      _c("add-itap-dialog", {
        ref: "addItapDialog",
        attrs: { isResume: true },
        on: {
          "update-list": function ($event) {
            return _vm.search(true)
          },
          showLabelManagement: _vm.showLabelManagement,
        },
      }),
      _c("inquiry-dialog", {
        ref: "inquiryDialog",
        attrs: { isTrackingList: _vm.isTrackingList, "source-from": 6 },
        on: { "update-item": _vm.handleInquiryUpdate },
      }),
      _c("remove-dialog", {
        ref: "removeDialog",
        attrs: { isTrackingList: _vm.isTrackingList },
        on: {
          "update-list": function ($event) {
            return _vm.search(true)
          },
          "update-item": _vm.updateItem,
        },
      }),
      _c("occupy-ownership-dialog", {
        ref: "occupyOwnershipDialog",
        on: {
          "update-list": function ($event) {
            return _vm.search(true)
          },
          updateItem: _vm.updateItem,
        },
      }),
      _c("recommend-reason-dialog", {
        ref: "editRecommendReasonDialog",
        on: { "update-item": _vm.updateItem },
      }),
      _c("job-status-dialog", { ref: "jobStatusDialog" }),
      _c("label-management", { ref: "labelManagement" }),
      _vm.isTrackingList
        ? _c("add-communication-dialog", {
            ref: "addCommunicationDialog",
            on: { "update-item": _vm.updateItem },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }