var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "recommend-reason-dialog",
      attrs: {
        title: "推荐原因",
        width: "608px",
        visible: _vm.isShow,
        "show-close": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("el-input", {
            class: _vm.isError ? "error-input" : "",
            attrs: {
              type: "textarea",
              autosize: { minRows: 6, maxRows: 10 },
              placeholder: "请输入推荐原因",
              maxlength: "400",
            },
            model: {
              value: _vm.recommendReason,
              callback: function ($$v) {
                _vm.recommendReason = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "recommendReason",
            },
          }),
          _c("div", { staticClass: "textarea-count" }, [
            _vm._v("(" + _vm._s(_vm.recommendReason.length) + "/400)"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }