<template>
    <el-dialog class="remove-dialog" width="608px" :visible.sync="isShow" :show-close="false" :center="true" v-loading="loading">
        <div class="remove-modal-content">
            <div class="content-title">
                <p>
                    确定将
                    <template v-if="!isBatch">
                        候选人<span class="text-green">{{realName}}</span>
                    </template>
                    <template v-else>
                        <span class="text-green" v-for="(cadidate, index) in candidateList" :key="cadidate.id" v-show="index < 8">
                            {{cadidate.resumeListItem.realName}}<span v-if="(candidateList.length <= 8 && index < candidateList.length - 1) || (candidateList.length > 8 && index < 7)">、</span>
                        </span>
                        等
                        <span class="warning-color"> {{total}}</span>
                        名候选人
                    </template>
                    移除Tracking List吗？移除后将不可恢复，请谨慎操作。
                </p>
            </div>
            <p class="content-tip">
                <span class="warning-color">注意：</span>
                移除候选人可重新手动加至tracking list
            </p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="isShow = false">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import talentPoolService from '@src/js/service/talentPoolService.js';
export default {
    name: 'remove-dialog',
    data(){
        return{
            isShow: false,
            isBatch: false,
            ids: [],
            total: 0,
            candidateList: [],
            realName: '',
            loading: false
        }
    },
    props: {
        isTrackingList: Boolean
    },
    methods:{
        show(isBatch){
            this.isBatch = isBatch;
            if(isBatch) {
                this.total = this.candidateList.length;
                let idArray = [];
                this.candidateList.forEach((item) => {
                    idArray.push(item.resumeListItem.resumeId);
                });
                this.ids = idArray;
            } else {
                this.realName = this.candidateList[0].resumeListItem.realName;
            }
            this.isShow = true;
        },
        handleConfirm(){
            this.loading = true;
            let resumeIds = [];
            if(this.isBatch) {
                resumeIds = this.ids;
            } else {
                resumeIds.push(this.candidateList[0].resumeListItem.resumeId);
            }
            talentPoolService.removeTrackingList({
                sourceFrom: this.isTrackingList ? 2 : 1,
                resumeIds: resumeIds
            }).then((res) => {
                this.loading = false;
                this.isShow = false;
                shortTips("移除成功");
                if(this.isBatch || this.isTrackingList) {
                    setTimeout(() => {
                        this.$emit('update-list');
                    }, 2000);
                } else {
                    this.$emit('update-item', this.candidateList[0]);
                }
            }).catch((err) => {
                this.loading = false;
                this.isShow = false;
                console.log(err);
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.remove-dialog {
    .text-green {
        color: $primary;
    }
    .warning-color{
        color: #FC7859;
    }
    .content-title {
        margin-bottom: 10px;
        line-height: 24px;
        font-size: 16px;
    }
    .content-tip {
        font-size: 12px;
        line-height: 20px;
        color: #2a2a2a;
    }
}
</style>
<style lang="scss">
.remove-dialog {
    .el-dialog {
        height: 320px;
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            padding: 80px 74px 50px;
            color: #444;
        }
    }
}
</style>
