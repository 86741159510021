<template>
    <div>
        <el-popover
            v-if="time"
            placement="bottom-start"
            trigger="hover"
            popper-class="operation-info"
            :visible-arrow="false"
            @show="showDetails"
            :open-delay="700"
            :offset="500">
            <div v-loading="loading">
                <p class="operation-title">
                    <span>最近操作：</span>
                    <span class="title-text" v-text="operationTitle"></span>
                </p>
                <p v-if="operationContent" class="operation-content" v-text="operationContent"></p>
            </div>
            <span slot="reference" class="operate-time">{{time | date}}</span>
        </el-popover>
    </div>
</template>
<script>
import server from '#/js/service/talentPoolService.js'
import moment from 'moment';
export default {
    props:{
        resumeId:String,
        time:String,
    },
    data(){
        return{
            loading:true,
            operationList:{}
        }
    },
    filters:{
        date(val){
            if(val){
                return moment(val).format('YYYY-MM-DD');
            }else{
                return ''
            }
        }
    },
    computed:{
        operationTitle(){
            let type = this.operationList.type;
            let isPersonal = this.operationList.isPersonal;
            let content = this.operationList.content;
            switch(type){
                case 1 :
                    return isPersonal? '创建简历（个人）':'创建简历';
                    break;
                case 2 :
                    return isPersonal? '编辑简历（个人）':'编辑简历';
                    break;
                case 12 :
                    return '查看联系方式';
                    break;
                case 3 :
                    return isPersonal? '拨打电话（个人）':'拨打电话';
                    break;
                case 4 :
                    return isPersonal? '添加寻访（个人）':'添加寻访';
                    break;
                case 9 :
                    return isPersonal? '添加标签（个人）':'添加标签';
                    break;
                case 7 :
                    return '添加事项提醒';
                    break;
                case 13 :
                    return '推荐候选人';
                    break;
                case 5 :
                    return '收藏简历';
                    break;
                case 6 :
                    return '取消收藏';
                    break;
                case 16 :
                    return '上传附件';
                    break;
                case 8 :
                    return '加入trackinglist';
                    break;
                case 11 :
                    return '将简历导入企业库';
                    break;
                case 10 :
                    return '领取简易简历';
                    break;
                case 14 :
                    return '加入待沟通';
                    break;
                case 30 :
                    return '取消待沟通';
                    break;
                case 27 :
                    return '移除trackinglist';
                    break;
                case 28 :
                    content = JSON.parse(content);
                    return content.isCoverSuccess?'上传重复简历-已覆盖':'上传重复简历';
                    break;
            }
        },
        operationContent(){
            let type = this.operationList.type;
            let isHistorical = this.operationList.isHistorical;
            let content = this.operationList.content;
            if(!content){
                return '';
            }
            if(isHistorical){
                if(type == 12 || type == 3 || type == 9 || type == 13 || type == 16){
                    return content;
                }else{
                    return '';
                }
            }else{
                content = JSON.parse(content);
                switch(type){
                    case 2 :
                        return content.editLog ? (content.editLog.editType == 0 ? '普通编辑' : '重大更新') : '';
                        break;
                    case 12 :
                        return content.type == 1 ? '查看手机号' : '查看邮箱';
                        break;
                    case 3 :
                        if(parseInt(content.duration) > 0) {
                            let hour = Math.floor(content.duration / 3600);
                            let minute = Math.floor(content.duration / 60) % 60;
                            let second = content.duration % 60;
                            hour = hour > 0 ? hour + '时' : '';
                            minute = minute > 0 ? minute + '分' : '';
                            second = second > 0 ? second + '秒' : '';
                            return '通话时长：' + hour + minute + second;
                        } else {
                            return '';
                        }
                    case 9 :
                        return content.tags[0];
                        break;
                    case 13 :
                        return `推荐至【${content.customerName}】${content.jobName}`;
                        break;
                    case 16 :
                        return isHistorical ? this.operationList.paramOne : content.fileName;
                    default :
                        return '';
                }
            }
        }
    },
    methods:{
        showDetails() {
            this.loading = true;
            server.getLastestOperation({resumeIds:[this.resumeId]}).then((res)=>{
                if(res.length > 0){
                    this.operationList = res[0];
                }
            }).finally(()=>{
                this.loading = false;
                _tracker.track('showLastOperationInfo', JSON.stringify({
                    resumeId: this.resumeId
                }))
            });
        },
    }
}
</script>
<style lang="scss">
.operation-info.el-popover{
    margin-top: 5px;
    padding: 10px 20px;
    font-size: 14px;
    color: #666666;
    letter-spacing: 0;
    .operation-title{
        margin-bottom: 0;
        .title-text{
            color: $primary;
        }
    }
    .operation-content{
        margin: 5px 0 0;
    }
}
</style>
