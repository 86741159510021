<template>
    <div class="talent-table-wrapper">
        <select-wrapper 
            ref="selectWrapper"
            :labelList="labelList"
            :searchParams="searchAreaParam"
            :table-type="tableType"
            :headerFilter="headerFilter"
            @handleCleanLabel="handleCleanLabel"
            @handleAddLabel="handleAddLabel"
            @setStartDate="setStartDate"
        ></select-wrapper>

        <search-label
            ref="searchLabel"
            :count="page.total"
            :labelList="labelList"
            :tableType="tableType"
            :title-store="titleStore"
            :table-title="tableTitleJson"
            :can-grab-count="canGrabCount"
            :searchParams="searchAreaParam"
            :job-hunting-count="jobHuntingCount"
            :orderLabelShowList="orderLabelShowList"
            @table-title="handleTableTitle"
            @handleAddLabel="handleAddLabel"
            @handleCleanLabel="handleCleanLabel"
        ></search-label>
        <!-- :table-data="tableData" -->
        <talent-pool-table
            ref="talentPoolTable"
            :page="page"
            :table-data="newTableData"
            :table-type="tableType"
            :title-store="titleStore"
            :headerFilter="headerFilter"
            @update-item="updateItem"
            @view-mail="handleViewMail"
            @get-reminder="getReminder"
            @page-change="handlePageChange"
            @jump-detail="handleJumpDetail"
            @view-mobile="handleViewMobile"
            @handleAddLabel="handleAddLabel"
            @handleCleanLabel="handleCleanLabel"
            @empty-time-select="handleEmptyTime"
            @resume-operation="resumeOperationDetail"
            @trackinglist-operate-change="sortTypeChange"
        ></talent-pool-table>

        <create-notice-dialog 
            ref="createNoticeDialog" 
            @update-item="updateItem"
        ></create-notice-dialog>

        <import-result-dialog
            ref="importResultDialog"
            :isOccupy="true"
            :isTextContext="true"
            @import-then-compare="goToCompare"
        ></import-result-dialog>

        <recommend-dialog 
            ref="recommendDialog" 
            :type="0"
        ></recommend-dialog>

        <add-itap-dialog
            ref="addItapDialog"
            :isResume="true"
            @update-list="search(true)"
            @showLabelManagement="showLabelManagement"
        ></add-itap-dialog>

        <inquiry-dialog
            ref="inquiryDialog"
            :isTrackingList="isTrackingList"
            @update-item="handleInquiryUpdate"
            :source-from="6"
        ></inquiry-dialog>

        <remove-dialog
            ref="removeDialog"
            @update-list="search(true)"
            @update-item="updateItem"
            :isTrackingList="isTrackingList"
        ></remove-dialog>

        <occupy-ownership-dialog
            ref="occupyOwnershipDialog"
            @update-list="search(true)"
            @updateItem="updateItem"
        ></occupy-ownership-dialog>

        <recommend-reason-dialog
            ref="editRecommendReasonDialog"
            @update-item="updateItem"
        ></recommend-reason-dialog>

        <job-status-dialog ref="jobStatusDialog"></job-status-dialog>
        <label-management ref="labelManagement"></label-management>

        <add-communication-dialog
            ref="addCommunicationDialog"
            v-if="isTrackingList"
            @update-item="updateItem"
        ></add-communication-dialog>
    </div>
</template>

<script>
    import SelectWrapper from '../component/selectWrapper.vue'
    import TalentPoolTable from './talentPoolTable'
    import ImportResultDialog from '#/component/common/dialog/importResultDialog.vue';
    import RecommendDialog from '#/component/common/dialog/recommendDialog.vue';
    import AddItapDialog from '#/component/common/dialog/addItapDialog.vue';
    import InquiryDialog from '#/component/common/dialog/inquiryDialog.vue';
    import RemoveDialog from '#/views/tracking-list/component/removeDialog.vue';
    import OccupyOwnershipDialog from '../component/occupyOwnershipDialog.vue';
    import RecommendReasonDialog from '../component/recommendReasonDialog.vue';
    import CreateNoticeDialog from '#/component/common/dialog/createNoticeDialog.vue'
    import SearchLabel from '../component/searchLabel.vue';
    import talentPoolTableTitle from '#/js/config/talentPoolTableTitle.json';
    import trackingListTableTitle from '#/js/config/trackingListTableTitle.json';
    import server from '#/js/service/talentPoolService.js';
    import { candidate as candidateUrl } from '#/js/config/api.json';
    import eventNameJson from '#/js/config/eventName.json';
    import emitter from '#/js/mixins/emitter.js';
    import {_generateUUID} from "#/js/util/global";
    import moment from 'moment';
    import JobStatusDialog from '#/component/common/dialog/jobStatusDialog.vue'
    import LabelManagement from '#/component/common/dialog/label-management.vue';
    import AddCommunicationDialog from '#/component/common/dialog/add-communication-dialog.vue';
    import degreeKeyValue from '#/js/config/degreeKeyValue.json'
    const eventName = eventNameJson.talentPoolTable;
    export default {
        name: "table-wrapper",

        componentName: "TableWrapper",

        mixins: [emitter],

        props: {
            /**
             * 表格类型值:
             * talentPool
             * trackingList
             **/
            tableType: String,
        },

        components: {
            ImportResultDialog,
            RecommendDialog,
            AddItapDialog,
            InquiryDialog,
            RemoveDialog,
            OccupyOwnershipDialog,
            TalentPoolTable,
            SelectWrapper,
            CreateNoticeDialog,
            SearchLabel,
            RecommendReasonDialog,
            JobStatusDialog,
            LabelManagement,
            AddCommunicationDialog
        },
        data() {
            return {
                tbdResumeIds: [],
                resumesCompletionsList: [],
                newTableData: [],

                firstSearched: false,
                searchId: '',
                startDate: '',
                endDate: '',
                dialogVisible: false,
                tableData: [],
                titleStore: [],
                filterStage: {
                    0: ['all'],
                    1: ['bubble', 'degree', 'school', 'workExperienceCompaniesName', 'yearOfExperience', 'isJobHunting', 'isGrab', 'citys','latestOperate', 'addTrackingListSource', 'label'],
                    2: ['degree', 'school', 'workExperienceCompaniesName', 'yearOfExperience', 'isJobHunting', 'isGrab', 'citys', 'latestOperate', 'addTrackingListSource', 'label']
                },
                cleanStage: false,
                pageParams: {
                    start: 0,
                    take: 20,
                },
                page: {
                    current: 1,
                    size: 20,
                    total: 0
                },
                orderLabelMap: new Set(),
                orderLabelShowList: [],
                labelList: {
                    // labelList 内标准格式为{
                    //     text: 'xxxx',
                    //     .......
                    // }
                    bubble: [],
                    title: [],
                    city: [],
                    companyName: [],
                    yearOfExperience: [],
                    isJobHunting: [],
                    isGrab: [],
                    school: [],
                    degree: [],
                    keyWords: [],
                    label: [],
                    email: [],
                    mobile: [],
                    realName: [],
                    inquiryLogContent: [],
                    workExperienceCompaniesName: [],
                    latestOperate: [],
                    addTrackingListSource: [],
                    citys: []
                },
                searchAreaParam: {
                    title: '',
                    keyWords: '',
                    companyName: '',
                    realName: '',
                    mobile: '',
                    email: '',
                    label: '',
                    inquiryLogContent: '',
                    locationId: '',
                    isJobHunting: false,
                    isGrab: false,
                },
                searchLock: false,
                canGrabCount: 0,
                jobHuntingCount: 0,
                headerFilter: {
                    citySearchGroupedItems: [],
                    companySearchGroupedItems: [],
                    degreeSearchGroupedItems: [],
                    educationTagsSearchGroupedItems: [],
                    yearOfExperience: [],
                    behaviorCenterOperation: [],
                    label: []
                },
                bubbleGroupedCount: [],
                sortType: 0,
                lockSortType: false,
                currentAggregationType: -1
            }
        },
        computed: {
            searchParams() {
                let keyName = 'itemValue';
                const params = {
                    startDate: this.startDate,
                    enDateTime: moment(this.endDate).add(1, 'days').format('YYYY-MM-DD 00:00:00'),
                    locations: this.getLabelListParam('city', 'id').concat(this.getLabelListParam('citys', 'id')),
                    keyWords: this.getLabelListParam('keyWords', 'text')[0],
                    title: this.getLabelListParam('title', 'text')[0],
                    companyName: this.getLabelListParam('companyName', 'text')[0],
                    realName: this.getLabelListParam('realName', 'text')[0],
                    mobile: this.getLabelListParam('mobile', 'text')[0],
                    email: this.getLabelListParam('email', 'text')[0],
                    labels: this.getLabelListParam('label', 'text'),
                    inquiryLogContent: this.getLabelListParam('inquiryLogContent', 'text')[0],
                    degree: this.getLabelListParam('degree', keyName),
                    yearOfExperienceRegion: this.getLabelListParam('yearOfExperience', keyName),
                    workExperienceCompaniesName: this.getLabelListParam('workExperienceCompaniesName', keyName),
                    educationTags: this.getLabelListParam('school', keyName),
                    filterType: this.labelList.bubble[0] ? this.labelList.bubble[0].code : undefined,
                    isJobHunting: this.searchAreaParam.isJobHunting,
                    canGrab: this.searchAreaParam.isGrab,
                    isTrackingList: this.isTrackingList,
                    addTrackingListSource: this.getLabelListParam('addTrackingListSource', 'text'),
                    latestOperationType: this.getLabelListParam('latestOperate', 'itemValue'),
                    currentAggregationType: this.currentAggregationType
                };
                return params;
            },
            isTrackingList() {
                return this.tableType == 'trackingList';
            },
            tableTitleJson() {
                return this.isTrackingList ? trackingListTableTitle : talentPoolTableTitle;
            },
            userInfo() {
                return this.$store.state.user.userInfo;
            },
        },
        mounted() {
            let params = {};
            if (localStorage.getItem(`${this.tableType}TableTitleNew`)) {
                this.titleStore = JSON.parse(localStorage.getItem(`${this.tableType}TableTitleNew`));
            } else {
                this.titleStore = this.tableType == 'talentPool' ?
                    ['operationTime', 'realName', 'mobile', 'email', 'inquiryInfo', 'tag', 'location', 'title', 'company', 'yearOfExperience', 'school', 'degree', 'recommendInfo', 'source'] :
                    ['operationTime', 'realName', 'communication', 'myRecomendation', 'location', 'title', 'company', 'school', 'degree', 'yearOfExperience', 'latestOperation', 'addFrom', 'recommendReason', 'inquiryInfo', 'tag'];
            }
            this.table = this.$refs.talentPoolTable;
            this.$on('resume-operation', item => {
                this.resumeOperationDetail(item.command, item.data);
            });
            this.$on('update-item', item => {
                this.updateItem(item);
            });
            this.$on('refresh-search-tag',this.handleSearchTagRrefresh);
            this.$on('update-list', () => {
                this.search(true);
            })
            this.$on('getBubbleGroupedCount', this.getBubbleGroupedCount);

            this.$on(eventName.searchLabel.addLabel, this.handleAddLabel);

            this.$on('showLabelManagement', this.showLabelManagement);
            if(window.localStorage) {
                let num = Number(localStorage.getItem('LBD_TABLE_PAGE_SIZE')) || 20;
                this.page.size = num;
                this.pageParams.take = num;
            }
            if(localStorage && this.isTrackingList) {
                params = {
                    realName : localStorage.getItem('trackingList_search_name') ? localStorage.getItem('trackingList_search_name') : '',
                    title: localStorage.getItem('trackingList_search_title') ? localStorage.getItem('trackingList_search_title') : '',
                    companyName: localStorage.getItem('trackingList_search_company') ? localStorage.getItem('trackingList_search_company') : '',
                    mobile: localStorage.getItem('trackingList_search_mobile') ? localStorage.getItem('trackingList_search_mobile') : '',
                };
                if(params.companyName || params.title || params.mobile || params.realName){
                    Object.assign(this.searchAreaParam, params);
                    this.$refs.selectWrapper.handleCommand(this.$refs.selectWrapper.timeLimits[4]);
                    this.$refs.selectWrapper.$refs.searchArea.handleSearch();
                    this.$nextTick(()=>{
                        this.orderLabelShowList = ['realName', 'mobile', 'title', 'companyName'];
                        localStorage.removeItem('trackingList_search_name');
                        localStorage.removeItem('trackingList_search_title');
                        localStorage.removeItem('trackingList_search_company');
                        localStorage.removeItem('trackingList_search_mobile');
                    })
                }
            }
            window.tableWrapper = this;
        },
        watch: {
            searchParams() {
                this.$nextTick(() => {
                    if (!this.searchLock) {
                        this.search(false);
                    }
                })
            },
            lockSortType(val) {
                if(val){
                    this.search(false);
                }
            },
            'searchAreaParam.locationId': function () {
                this.setCleanStage(1);
            },
            'searchParams.keyWords': function () {
                this.setCleanStage(1);
            },
            'searchParams.mobile': function () {
                this.setCleanStage(1)
            },
            'searchParams.email': function () {
                this.setCleanStage(1)
            },
            'searchParams.inquiryLogContent': function () {
                this.setCleanStage(1)
            },
            'searchParams.companyName': function () {
                this.setCleanStage(1)
            },
            'searchParams.realName': function () {
                this.setCleanStage(1)
            },
            'searchParams.title': function () {
                this.setCleanStage(1)
            },
            'searchParams.filterType': function () {
                this.setCleanStage(2)
            }
        },
        methods: {
            // 获取简历完整度
            getResumesCompletions(){
                let params = {
                    ResumeIds: this.tbdResumeIds
                }
                _request({
                    url: candidateUrl.get_resumes_completions,
                    method: "POST",
                    baseURL: "LbdOpenApi",
                    data: params
                }).then(res => {
                    console.log(res)
                    this.resumesCompletionsList = res;

                    if(this.resumesCompletionsList.length > 0) {
                        this.tableData.forEach(item => {
                            this.resumesCompletionsList.forEach(el => {
                                if(item.resumeListItem.resumeId.indexOf(el.key) != -1) {
                                    // item['resumeListItem']['resumesCompletions'] = el.value;
                                    item.resumeListItem.resumesCompletions = el.value;
                                }
                            })
                        })
                        console.log(this.tableData);
                        this.newTableData = this.tableData;
                        console.log(this.newTableData);
                    } else {
                        this.newTableData = this.tableData;
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            search(slient) {
                if (this.cleanStage !== false) {
                    this.cleanFilter();
                }
                let params = this.searchParams;
                if (!slient) {
                    this.page.current = 1;
                    this.pageParams.start = 0;
                }
                Object.assign(params, this.pageParams);
                this.table.tableDataLoading = true;
                this.searchLock = true;
                // if((params.keyWords || params.companyName || params.title) && !this.lockSortType) {
                //     params.sort = 1;
                // } else {
                    params.sort = this.sortType;
                // }
                // this.sortType = params.sort;
                this.$refs.talentPoolTable.setOperateType(params.sort);
                server.search(params).then((res) => {
                    let checkMoreChildrenTalentIds = [],
                        checkMoreChildrenIndexList = [],
                        hasMoreChildrenIndexList = [];
                    this.page.total = res.total;
                    this.canGrabCount = res.canGrabCount;
                    this.jobHuntingCount = res.jobHuntingCount;
                    this.setHeaderFilter('citySearchGroupedItems', res.categoryGroupedItemsDic.location);
                    this.setHeaderFilter('companySearchGroupedItems', res.categoryGroupedItemsDic.workExperienceCompaniesName);
                    this.setHeaderFilter('degreeSearchGroupedItems', res.categoryGroupedItemsDic.degree);
                    this.setHeaderFilter('educationTagsSearchGroupedItems', res.categoryGroupedItemsDic.educationTags);
                    this.setHeaderFilter('yearOfExperience', res.categoryGroupedItemsDic.yearOfExperienceRegion);
                    this.setHeaderFilter('behaviorCenterOperation', res.categoryGroupedItemsDic.behaviorCenterOperation);
                    this.setHeaderFilter('label', res.categoryGroupedItemsDic.tags);

                    res.list.forEach((item, index) => {
                        item.index = index;
                        item.subIndex = -1;
                        item.rowKey = _generateUUID();
                        if (item.hitMultipleResumes) {
                            hasMoreChildrenIndexList.push(index);
                        } else {
                            if (item.talentId) {
                                checkMoreChildrenTalentIds.push(item.talentId);
                                checkMoreChildrenIndexList.push(index);
                            }
                        }
                        item.resumeListItem.tags.sort((tag1, tag2) => {
                            let time1 = new Date(tag1.created).getTime(),
                                time2 = new Date(tag2.created).getTime();
                            if (time1 > time2) {
                                return -1
                            } else {
                                return 1
                            }
                        })
                    });
                    let keyName = 'itemValue';
                    this.syncFilter(this.labelList.citys, this.headerFilter.citySearchGroupedItems, keyName);
                    this.syncFilter(this.labelList.workExperienceCompaniesName.concat(this.labelList.companyName), this.headerFilter.companySearchGroupedItems, keyName);
                    this.syncFilter(this.labelList.degree, this.headerFilter.degreeSearchGroupedItems, keyName);
                    this.syncFilter(this.labelList.school, this.headerFilter.educationTagsSearchGroupedItems, keyName);
                    this.syncFilter(this.labelList.yearOfExperience, this.headerFilter.yearOfExperience, keyName);
                    this.syncFilter(this.labelList.latestOperate, this.headerFilter.behaviorCenterOperation, keyName);
                    this.syncFilter(this.labelList.label, this.headerFilter.label, keyName);
                    this.tableData = res.list;
                    this.table.tableDataLoading = false;
                    this.checkIfResumeChildren(checkMoreChildrenTalentIds, checkMoreChildrenIndexList,hasMoreChildrenIndexList);

                    if (!slient && this.firstSearched) {
                        this.searchId = _generateUUID();
                        _tracker.track(this.tableType === 'talentPool' ? 'TalentPoolSearch' : 'TrackingListSearch', JSON.stringify(Object.assign({}, {
                            searchId: this.searchId,
                            searchParams: Object.keys(params).map(item => {
                                switch (Object.prototype.toString.call(params[item])){
                                    case '[object Array]':
                                        if(params[item].length > 0) {
                                            return {
                                                field : item,
                                                value : params[item]
                                            }
                                        }
                                        break;
                                    case '[object Boolean]':
                                    case '[object String]':
                                        if(params[item]){
                                            return {
                                                field : item,
                                                value : params[item]
                                            }
                                        }
                                        break;
                                    case '[object Number]':
                                        return {
                                            field : item,
                                            value : params[item]
                                        };
                                }
                            }).filter(item => {
                                return item
                            }),
                            searchResult: {
                                matchCount: res.total,
                                firstPageResumeIds: this.tableData.map(item => {
                                    return item.resumeListItem.resumeId
                                })
                            },
                            timeRange: this.$refs.talentPoolTable.timeLimit
                        })))
                    } else if (slient){
                        _tracker.track('SearchResultPagingClick', JSON.stringify({
                            searchId: this.searchId,
                            pageIndex: this.page.current,
                            pageSize: this.pageParams.take,
                            from: this.tableType
                            })
                        )
                    }
                    !this.firstSearched && (this.firstSearched = true);

                    // 每次查找之后都把tbdResumeIds置空
                    this.tbdResumeIds = [];
                    this.tableData.forEach( el => {
                        if (el.resumeListItem.completion == 0 || el.resumeListItem.completion == null ) {
                            this.tbdResumeIds.push(el.resumeListItem.resumeId);
                        } else {
                            el.resumeListItem.resumesCompletions = el.resumeListItem.completion;
                        }
                    })
                    console.log(this.tbdResumeIds);
                    this.getResumesCompletions();

                }).finally(() => {
                    this.table.scrollTop();
                    this.searchLock = false;
                    this.table.tableDataLoading = false;
                    this.lockSortType = false;
                });
            },
            getBubbleGroupedCount() {
                server
                    .getBubbleGroupedCount(this.searchParams)
                    .then(res => {
                        this.bubbleGroupedCount = res;
                        this.broadcast('selectBubble', `setBubbleGroupedCount`, {
                            bubble: this.bubbleGroupedCount
                        });
                    })
            },
            updateItem(resume) {
                /*
                * 刷新单条数据
                * resume.index: 列表index
                * resume.subIndex: 姓名下拉列表index
                */
                //发送resumeId获取当前操作行最新简历数据
                server.updateSingle({
                    resumeId: resume.resumeListItem.resumeId,
                    isTrackingList: this.isTrackingList
                }).then((res) => {
                    res.tags.sort((tag1, tag2) => {
                        let time1 = new Date(tag1.created).getTime(),
                            time2 = new Date(tag2.created).getTime();
                        if (time1 > time2) {
                            return -1
                        } else {
                            return 1
                        }
                    })
                    this.updateItemFields(resume, res);
                }).catch((err) => {
                    console.log(err);
                })
            },
            handleJumpDetail(resumeId,resume) {
                _tracker.track(this.tableType === 'talentPool' ? 'TalentPoolSearchResultClick' : 'TrackingListSearchResultClick', JSON.stringify({
                    searchId: this.searchId,
                    clickPlace: {
                        pageIndex: this.page.current,
                        itemRank: resume.index,
                        resumeId : resumeId
                    }
                }))
            },
            // 打开多个页面窗口
            openWindow(url, id) {
                console.log(1111);
                console.log(url);
                var a = document.createElement('a'); //创建a对象
                a.setAttribute('href', url);
                a.setAttribute('target', '_blank');
                a.setAttribute('id', id);
                // 防止反复添加
                if(!document.getElementById(id)) document.body.appendChild(a);
                a.click(); //执行当前对象
            },

            resumeOperationDetail(command, dataJson) {
                console.log(dataJson);
                let resumeListItem = [];
                let isFirmResume = false,eventName = this.tableType === 'talentPool' ? 'TalentPoolExecuteOperation' : 'TrackingListExecuteOperation',resumeId = [];
                if(command != 'editVisit') {
                    switch(Object.prototype.toString.call(dataJson)){
                        case '[object Object]':
                            resumeListItem.push(dataJson.resume.resumeListItem);

                            isFirmResume = dataJson.resume.resumeListItem.isFirmResume;
                            resumeId.push(dataJson.resume.resumeListItem.resumeId);
                            break;
                        case '[object Array]':
                            isFirmResume = false;
                            dataJson.forEach( item => {
                                resumeListItem.push(item.resumeListItem);

                                if(item.resumeListItem){
                                    resumeId.push(item.resumeListItem.resumeId);
                                }
                            });
                            break;
                    }
                }
                switch (command) {
                    case 'batchView':
                        if(resumeListItem.length > 1) {
                            sessionStorage.setItem(`batchViewCandidate`, JSON.stringify(resumeListItem));

                            window.open(`/#/batchCandidateDetail`);
                        } else {
                            window.open(`/#/candidateDetail/${resumeId[0]}`);
                        }
                        
                        // console.log(resumeId);
                        // resumeId.forEach(el => {
                        //     // console.log(el);
                        //     window.open(`/#/candidateDetail/${el}`, el);

                        //     // this.openWindow(`/#/candidateDetail/${el}`, el);
                        // })
                        // _tracker.track(eventName, JSON.stringify({
                        //     operationType: 'batchView',
                        //     operationText: '批量查看',
                        //     isFirmResume: isFirmResume
                        // }));
                        break;
                    case 'addSchedule':
                        this.addSchedule(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'addSchedule',
                            operationText: '添加事项提醒',
                            isFirmResume: isFirmResume
                        }));
                        break;
                    case 'addToCompany':
                        this.addToCompany(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'addToCompany',
                            operationText: '共享至企业库',
                            isFirmResume: isFirmResume
                        }));
                        break;
                    case 'recommend':
                        this.recommend(dataJson);
                        break;
                    case 'addItap':
                        this.addItap(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'addItap',
                            operationText: '添加标签',
                            isFirmResume: isFirmResume
                        }));
                        break;
                    case 'addVisit':
                        this.addVisit(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'addVisit',
                            operationText: '添加寻访',
                            isFirmResume: isFirmResume
                        }));
                        break;
                    case 'editVisit':
                        this.editVisit(dataJson);
                        break;
                    case 'removeTrackingList':
                        this.removeTrackingList(dataJson);
                        _tracker.track('removeTrackingList', JSON.stringify({
                            resumeId: resumeId,
                            isFirmResume: isFirmResume,
                            isBatch: false,
                            form: this.tableType
                        }));
                        break;
                    case 'batchAddItap':
                        this.batchAddItap(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'batchAddItap',
                            operationText: '批量添加标签',
                            isFirmResume: isFirmResume
                        }));
                        break;
                    case 'batchOccupyOwnership':
                        this.batchOccupyOwnership(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'batchOccupyOwnership',
                            operationText: '批量抢占',
                            isFirmResume: isFirmResume
                        }));
                        break;
                    case 'batchRemove':
                        this.batchRemove(dataJson);
                        _tracker.track('removeTrackingList', JSON.stringify({
                            resumeId: resumeId,
                            isBatch: true,
                            isFirmResume: isFirmResume,
                            form: this.tableType
                        }));
                        break;
                    case 'editRecommendReason':
                        this.editRecommendReason(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'editRecommendReason',
                            operationText: '编辑推荐原因',
                            isFirmResume: isFirmResume
                        }));
                        break;
                    case 'addCommunication':
                        this.addCommunication(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'addCommunication',
                            operationText: '添加待沟通',
                            isFirmResume: isFirmResume
                        }));
                        break;
                    case 'batchTrackListShare':
                        this.batchShare(dataJson);
                        break;
                }
            },
            addSchedule(dataJson) {
                if(dataJson.communicationInfo) {
                    this.$refs.createNoticeDialog.showDialog(dataJson.resume, dataJson.communicationInfo);
                } else {
                    this.$refs.createNoticeDialog.showDialog(dataJson.resume);
                }
            },
            addToCompany(dataJson) {
                this.$refs.importResultDialog.show(dataJson.importResult);
                this.updateItem(dataJson.resume);
            },
            recommend(dataJson) {
                let candidateId = dataJson.candidateId;
                this.$refs.recommendDialog.show(candidateId);
            },
            addItap(dataJson) {
                this.$refs.addItapDialog.list = [];
                this.$refs.addItapDialog.list.push(dataJson.resume);
                this.$refs.addItapDialog.show(false);
            },
            addVisit(dataJson) {
                if(dataJson.resume.resumeListItem.isFirmResume) {
                    const param = {
                        resumeId: dataJson.resume.resumeListItem.resumeId
                    };
                    server.getResumeOrg(param).then(res => {
                        dataJson.resume.resumeListItem['workExperiences'] = [res.workExperience];
                        this.$refs.inquiryDialog.show(Object.assign({}, {
                            ...dataJson.resume,
                            customerId: res.customerId,
                            customerDepartmentId: res.customerDepartmentId,
                            customerDepartmentName: res.customerDepartmentName,

                            company: res.company,
                            title: res.title,
                            }));
                    }).catch((err) => {
                        console.log(err);
                    })
                } else {
                    this.$refs.inquiryDialog.show(dataJson.resume);
                }
            },
            editVisit(json) {
                this.$refs.inquiryDialog.edit(json);
            },
            removeTrackingList(dataJson) {
                this.$refs.removeDialog.candidateList = [];
                this.$refs.removeDialog.candidateList.push(dataJson.resume);
                this.$refs.removeDialog.show(false);
            },
            batchAddItap(data) {
                this.$refs.addItapDialog.list = data;
                this.$refs.addItapDialog.show(true);
            },
            batchOccupyOwnership(data) {
                this.$refs.occupyOwnershipDialog.candidateList = data;
                this.$refs.occupyOwnershipDialog.show(true);
            },
            batchRemove(data) {
                this.$refs.removeDialog.candidateList = data;
                this.$refs.removeDialog.show(true);
            },
            handleTableTitle(val) {
                this.titleStore = [];
                this.titleStore = val;
                _tracker.track('TableTitleStore', JSON.stringify({
                    titleStore: val
                }));
                localStorage.setItem(`${this.tableType}TableTitleNew`, JSON.stringify(val));
            },
            handlePageChange(page) {
                if (page.type === 'current') {
                    this.page.current = page.current;
                    this.pageParams.start = (page.current - 1) * this.page.size;
                } else {
                    this.page.size = page.size;
                    this.page.current = 1;
                    this.pageParams.take = page.size;
                    this.pageParams.start = 0;
                }
                this.search(true);
            },
            handleCleanLabel(label, isEmit) {
                // 处理取消Label过滤条件不高亮 start
                const hashMap = {
                    citys: 'location',
                    workExperienceCompaniesName: 'company',
                    label: 'tag',
                };
                const _label = hashMap[label] || label;
                const _nCancelLabelIndex = this.$refs.selectWrapper && this.$refs.selectWrapper.aCurrentLabel.indexOf(_label);
                if(_nCancelLabelIndex >=0 ) {
                    this.$refs.selectWrapper && this.$refs.selectWrapper.aCurrentLabel.splice(_nCancelLabelIndex, 1);
                }
                // 处理取消Label过滤条件不高亮 end

                if (this.labelList[label].length <= 0) {
                    return false;
                }
                this.orderLabelMap.delete(label);
                this.orderLabelShowList = Array.from(this.orderLabelMap);
                if (isEmit) {
                    switch (label) {
                        case "bubble":
                            this.broadcast('selectBubble', `${eventName.bubbleCategory.reset}`);
                            break;
                        case "keyWords":
                        case "realName":
                        case "email":
                        case "mobile":
                        case "inquiryLogContent":
                            // eventBus.$emit(`${eventName.advancedSearch.reset}`, label);
                            this.searchAreaParam[label] = '';
                            break;
                        case "label":
                            this.searchAreaParam[label] = '';
                            this.broadcast('searchArea', `${eventName.advancedSearch.reset}`, label);
                            break;
                        case "companyName":
                        case "title":
                        case "citys":
                            this.searchAreaParam[label] = '';
                            this.broadcast('searchArea', `${eventName.advancedSearch.reset}`, label);
                            this.broadcast('TalentPoolTable', `${eventName.tableHeaderFilter.reset}`, label);
                            break;
                        case "city":
                            this.searchAreaParam['city'] = '';
                            this.searchAreaParam['citys'] = '';
                            this.broadcast('searchArea', `${eventName.advancedSearch.reset}`, 'city');
                            this.broadcast('TalentPoolTable', `${eventName.tableHeaderFilter.reset}`, 'citys');
                            break;
                        case "isJobHunting":
                        case "isGrab":
                            this.searchAreaParam[label] = false;
                            break;
                        case 'addTrackingListSource':
                            this.table.addTrackingListSource = 0;
                            break;
                    }
                }
                this.labelList[label] = [];
                return label === 'city' ? this.labelList['citys'] = [] : '';
            },
            handleAddLabel(label, params, isCover) {
                const orderList = this.labelList[label];
                isCover = isCover || false;
                params = params.constructor === Object ? [params] : params;
                orderList.forEach(item => {
                    params.filter(param => {
                        return param.text !== item.text;
                    });
                });

                if (params.length <= 0) {
                    return false;
                }
                this.orderLabelMap.add(label);
                this.orderLabelShowList = Array.from(this.orderLabelMap);
                if (isCover) {
                    this.labelList[label] = params;
                } else {
                    this.labelList[label] = this.labelList[label].concat(params);
                }
                switch(label) {
                    case 'workExperienceCompaniesName':
                        this.currentAggregationType = 1;
                        break;
                    case 'citys':
                        this.currentAggregationType = 2;
                        break;
                    case 'degree':
                        this.currentAggregationType = 5;
                        break;
                    case 'school':
                        this.currentAggregationType = 6;
                        break;
                    case 'yearOfExperience':
                        this.currentAggregationType = 8;
                        break;
                    case 'latestOperate':
                        this.currentAggregationType = 15;
                        break;
                    case 'addTrackingListSource':
                        this.currentAggregationType = 16;
                        break;
                    default:
                        this.currentAggregationType = -1;
                }
            },
            handleViewMobile(resume, index) {
                let item = resume.resumeListItem;
                server.viewContact(item.candidateId,
                    {
                        type: 1,
                        __RequestVerificationToken: this.$store.state.verificationToken
                    })
                .then((res) => {
                    let json = {};
                    json.contactStatus = this.tableData[index].resumeListItem.contactStatus;
                    json.contactStatus.mobileStatus = 3;
                    json.mobile = res.contact;
                    this.updateItemFields(resume, json);
                }).catch((err) => {
                    console.log(err);
                })

            },
            handleViewMail(resume, index) {
                let item = resume.resumeListItem;
                server.viewContact(item.candidateId,
                    {
                        type: 2,
                        __RequestVerificationToken: this.$store.state.verificationToken
                    })
                .then((res) => {
                    let json = {};
                    json.contactStatus = this.tableData[index].resumeListItem.contactStatus;
                    json.contactStatus.emailStatus = 3;
                    json.email = res.contact;
                    this.updateItemFields(resume, json);
                }).catch((err) => {
                    console.log(err);
                })
            },
            updateItemFields(resume, json) {
                let index = resume.index,
                    subIndex = resume.subIndex,
                    resumeListItem = {};
                if (subIndex == -1) {
                    let tableItem = this.tableData[index];
                    resumeListItem = Object.assign({}, tableItem.resumeListItem, json);
                    tableItem.resumeListItem = resumeListItem;
                    this.$set(this.tableData, index, tableItem);
                } else {
                    let tableItem = this.tableData[index].children[subIndex];
                    resumeListItem = Object.assign({}, tableItem.resumeListItem, json);
                    tableItem.resumeListItem = resumeListItem;
                    this.$set(this.tableData[index].children, subIndex, tableItem);
                }
            },
            checkIfResumeChildren(ids, indexList,hasIndexList) {
                if (ids.length > 0) {
                    _request({
                        url: '/Headhunting/TalentPool/HasMoreCandidates',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: {
                            talentIds: ids
                        }
                    }).then((res) => {
                        if (res) {
                            res.forEach((item, index) => {
                                if (item.hasMore) {
                                    let talent = this.tableData[indexList[index]];
                                    talent.hasChildren = true;
                                    this.tableData.splice(indexList[index], 1, talent);
                                }
                            })
                        }
                    }).finally(()=>{
                        hasIndexList.forEach((item)=>{
                            let talent = this.tableData[item];
                            if(talent) {
                                talent.hasChildren = true;
                                this.tableData.splice(item, 1, talent);
                            }
                        })
                    });
                } else {
                    hasIndexList.forEach((item)=>{
                        let talent = this.tableData[item];
                        if(talent) {
                            talent.hasChildren = true;
                            this.tableData.splice(item, 1, talent);
                        }
                    })
                }
            },
            setStartDate(value) {
                this.startDate = value[0];
                this.endDate = value[1];
                // this.startDate = value.startDate;
                // this.$refs.talentPoolTable.timeLimit = value.name;
                // _tracker.track('searchTimeChang', JSON.stringify({
                //     selectItemName: value.name,
                //     from : this.tableType
                // }))
            },
            getLabelListParam(label, keyName) {
                const resultArr = [], target = this.labelList[label];
                target.map(item => {
                    resultArr.push(item[keyName]);
                });
                return resultArr;
            },
            syncAllFilter() {
                const label = []
            },
            syncFilter(arr, goalArr, keyName) {
                if (arr.length > 0) {
                    arr.map(item => {
                        goalArr.map(param => {
                            if (item[keyName] === param[keyName]) {
                                param.isSelect = true;
                            }
                        })
                    })
                }
            },
            handleEmptyTime(item) {
                this.$refs.selectWrapper.handleCommand(item);
            },
            getReminder(resume) {
                let item = resume.resumeListItem;
                server.getReminder(item.resumeId,
                ).then((res) => {
                    let json = {
                        resumeReminders: res
                    }
                    this.updateItemFields(resume, json);
                }).catch((err) => {
                    console.log(err);
                })
            },
            setCleanStage(value) {
                if (!this.cleanStage || this.cleanStage > value) {
                    this.cleanStage = value;
                }
            },
            cleanFilter() {
                const arr = this.filterStage[this.cleanStage];
                if(this.cleanStage === 1){
                    this.getBubbleGroupedCount();
                }
                arr.forEach(item => {
                    switch (item) {
                        case 'all':
                            for (let key in this.labelList) {
                                this.handleCleanLabel(key, true);
                            }
                            break;
                        default:
                            this.handleCleanLabel(item, true);
                            break;
                    }
                })
                this.cleanStage = false;
            },
            handleInquiryUpdate(val) {
                val.resumeListItem.resumeInquiryLog.resumeId = '';
                this.updateItem(val);
            },
            setHeaderFilter(headerFilterKeyName, orderArray) {
                if(!orderArray) {
                    return false;
                }
                const arr = this.headerFilter[headerFilterKeyName];
                while(arr.length > 0){
                    arr.pop();
                }

                if(orderArray.length > 0){
                    orderArray.forEach(item => {
                        arr.push(item);
                    });
                } else {

                }
            },
            sortTypeChange(sortType) {
                this.sortType = sortType;
                this.lockSortType = true;
            },
            editRecommendReason(data) {
                this.$refs.editRecommendReasonDialog.show(data.resume);
            },
            handleSearchTagRrefresh(itap){
                const param = this.headerFilter.label.find((item) => {
                    return item.text === itap;
                });
                if(param) {
                    param.count = param.count + 1
                } else {
                    this.headerFilter.label.push({
                        itemText: itap,
                        itemValue: itap,
                        isSelect: false,
                        count: 1,
                        text: itap,
                        label: itap
                    })
                }
            },
            goToCompare(resume) {
                if(resume && resume.repeatResumeId) {
                    window.open(`/Headhunting/MyCompany.html#/candidate/updatecheck?resumeId=${resume.resumeId}&originalResumeId=${resume.repeatResumeId}`, '_blank');
                    // window.open(`/#/candidate/updatecheck?resumeId=${resume.resumeId}&originalResumeId=${resume.repeatResumeId}`, '_blank');
                } else {
                    shortTips("系统异常，请稍后重试");
                }
            },
            showLabelManagement() {
                this.$refs.labelManagement.show();
            },
            addCommunication(dataJson) {
                let resume = dataJson.resume.resumeListItem;
                this.$refs.addCommunicationDialog.show({
                    detail: dataJson.resume,
                    candidateId: resume.candidateId
                });
            },
            batchShare(dataJson) {
                let _trackingListShareResumes = [];
                dataJson.forEach(item => {
                    if(item.resumeListItem.addTrackingListSource == '企业库') {
                        _trackingListShareResumes.push({
                            resumeId: item.resumeListItem.resumeId,
                            trackingDate: item.resumeListItem.trackingDate,
                        });
                    }
                });

                let shareData = Object.assign({ type: 'batchTrackListShare' }, {
                    trackingListShareResumes: _trackingListShareResumes,
                    userInfo: {
                        id: this.userInfo.id,
                        fullName: this.userInfo.fullName,
                    },
                });
                this.dispatch('App', 'showShareToImDialog', shareData);
            },
        }
    }
</script>

<style lang="scss">
    .talent-table-wrapper {
        height: 100%;
        min-height: 500px;
        display: flex;
        flex-direction: column;
    }
</style>
