<template>
    <el-popover
        ref="communicationPopover"
        placement="bottom-start"
        width="580"
        trigger="hover"
        popper-class="communication-job"
        :visible-arrow="false"
        @show="showDetails"
        :open-delay="700"
        :disabled="count === 0">
        <div slot="reference">
            <span v-if="count > 0">
                <font-icon class="table-body-icon-big" href="#icon-coffee"></font-icon>
                <span>&nbsp;{{count}}</span>
            </span>
            <span
                class="cursor-pointer add-communication-btn"
                @click="$emit('add-communication')"
                v-else>
                <i class="el-icon-circle-plus-outline table-body-icon-big"></i>
                <span class="color-warn">&nbsp;添加</span>
            </span>
        </div>
        <div class="communication-job-wrapper">
            <div class="table-popper-header">
                待沟通的职位
                <span class="cursor-pointer add-communication-btn" @click="$emit('add-communication')">
                    <i class="el-icon-circle-plus-outline"></i>
                    添加
                </span>
            </div>
            <ul class="communication-job-list" v-loading="loading">
                <li
                    class="communication-job-item"
                    v-for="(communication,index) in communicationList"
                    :key="index">
                    <div
                        class="job-title"
                        :title="`${communication.jobName}[${communication.jobLocationName}]-${communication.jobCustomerName}`">
                        <!-- <a
                            class="job-name"
                            :href="`/Headhunting/MyCompany.html#/Job/${communication.jobId}`"
                            target="_blank">
                            {{communication.jobName}}
                        </a> -->
                        <a
                            class="job-name"
                            :href="`/#/Job/${communication.jobId}`"
                            target="_blank">
                            {{communication.jobName}}
                        </a>
                        [{{communication.jobLocationName}}]-{{communication.jobCustomerName}}
                    </div>
                    <div class="comminucation-time">
                        {{communication.created.slice(0, 10)}} 添加
                    </div>
                    <div class="communication-operate">
                        <span class="text-operate-btn" @click="addSchedule(communication)">提醒</span>
                        <span class="text-operate-btn" @click="toRecommend(communication)">推荐</span>
                        <span class="text-operate-btn orange" @click="removecomminucation(communication.id)">移出</span>
                    </div>
                </li>
                <li v-if="count > 3" class="show-more-btn">
                    <!-- <a class="more-link" :href="`/Headhunting/MyCompany.html#/candidateDetail/${resumeId}/communicationList`" target="_blank">
                        更多
                        <i class="el-icon-d-arrow-right"></i>
                    </a> -->
                    <a class="more-link" :href="`/#/candidateDetail/${resumeId}/communicationList`" target="_blank">
                        更多
                        <i class="el-icon-d-arrow-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </el-popover>
</template>
<script>
import server from '#/js/service/talentPoolService.js'
import moment from 'moment';
import popover from '#/js/mixins/popover.js';
export default {
    props:{
        count: Number,
        resumeId: String,
    },

    mixins: [popover],

    data(){
        return{
            communicationList: {},
            loading: true,
        }
    },
    methods:{
        showDetails() {
            server.getLastestComminucations({
                resumeId: this.resumeId
            }).then((res) => {
                this.communicationList = res;
            }).finally(() => {
                this.loading = false;
                this.popoverShow(this.$refs.communicationPopover);
            });
        },
        addSchedule(communication) {
            this.$emit('add-schedule', {
                jobName: communication.jobName,
                customerName: communication.jobCustomerName
            });
        },
        toRecommend(communication) {
            window.open(`/Headhunting/MyCompany.html#/recommendDetail?jobId=${communication.jobId}&candidateId=${communication.candidateId}&isEdit=Flase&jobType=${communication.jobType}`);
        },
        removecomminucation(communicationId) {
            this.loading = true;
            server.removeComminucation({
                id: communicationId
            }).then((res) => {
                this.loading = false;
                shortTips('移出成功');
                this.showDetails();
                this.$emit('update-item');
            }).catch(err => {
                this.loading = false;
            });
        }
    }
}
</script>
<style lang="scss" scope>
.cursor-pointer {
    cursor: pointer;
}
.add-communication-btn {
    color: #999;
}
.communication-job.el-popover{
    padding: 12px 16px;
    min-height: 80px;
    margin-top: 0px;
    .table-popper-header {
        margin-bottom: 12px;
    }
    .add-communication-btn {
        float: right;
        font-size: 14px;
        line-height: 22px;
    }
    .communication-job-list {
        .communication-job-item {
            cursor: default;
            line-height: 20px;
            font-size: 14px;
            display: flex;
            & + .communication-job-item {
                margin-top: 16px;
            }
        }
        .show-more-btn {
            margin-top: 16px;
            &:hover {
                .more-link {
                    color: $primary;
                }
            }
        }
        .job-title {
            width: 290px;
            max-width: 290px;
            color: #4a4a4a;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // font-weight: bold;
            .job-name {
                cursor: pointer;
                &:hover {
                    color: $primary;
                }
            }
        }
        .comminucation-time {
            color: #999;
            margin: 0 22px 0 10px;
        }
        .communication-operate {
            & > span {
                & + span {
                    margin-left: 12px;
                }
            }
        }
    }
}
</style>
