var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "recommendReasonPopover",
      attrs: {
        placement: "bottom-start",
        trigger: "hover",
        "popper-class": "recommend-reason-popover talentpool-popover",
        "visible-arrow": false,
        "open-delay": 700,
        width: "380",
        offset: 500,
      },
      on: {
        show: function ($event) {
          return _vm.popoverShow(_vm.$refs.recommendReasonPopover)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "table-popper-header inquiry-log-header recommend-reason-popover-header",
        },
        [
          _vm._v("\n        推荐原因\n        "),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-edit-outline" },
              on: {
                click: function ($event) {
                  return _vm.resumeOperation("editRecommendReason", {
                    resume: _vm.resume,
                  })
                },
              },
            },
            [_vm._v("\n            修改\n        ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "recommend-reason-content" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.resume.resumeListItem.tempRecommendReason) +
            "\n    "
        ),
      ]),
      _c("span", { attrs: { slot: "reference" }, slot: "reference" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.resume.resumeListItem.tempRecommendReason)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }