<template>
    <div>
        <div class="search-area">
            <div class="search-area-contianer">
                <div class="search-input">
                    <div class="search-city-btn" :title="selectedCity.text">
                        <el-popover
                            placement="bottom-start"
                            width="629"
                            :visible-arrow="provinceCityConfig.arrowIsShow"
                            v-model="provinceCityConfig.provinceCityIsShow"
                            trigger="click"
                            :offset="-86">
                            <span slot="reference" class="search-city-content">
                                {{ selectedCity.text }}
                                <span class="el-icon-arrow-down"></span>
                            </span>

                            <province-city ref="provinceCity" :grid-length="7"
                                           :enableHeader="provinceCityConfig.enableHeader"
                                           :enableFooter="provinceCityConfig.enableFooter"
                                           @confirm="handleCitySelectConfirm"
                                           @cancel="handleCitySelectCancel"></province-city>
                        </el-popover>
                    </div>

                    <div class="search-keywords-input">
                        <el-popover
                            placement="bottom-start"
                            width="348"
                            :visible-arrow="searchHistoryConfig.arrowIsShow"
                            v-model="searchHistoryConfig.searchHistoryIsShow"
                            :popper-class="searchHistoryPoperClass"
                            trigger="click">
                            <input slot="reference" maxlength="100" type="text" v-model="searchParams.keyWords"
                                   @keyup.enter="handleSearch"
                                   placeholder="输入关键字，多个关键字用空格隔开" @input="handleInput">
                            <div class="search-history">
                                <div class="search-history-header">
                                    搜索历史
                                </div>
                                <ul>
                                    <li class="search-history-li" 
                                        @click="handleSelectKeyWordsHistory(item.text)"
                                        :key="'search_history' + index"
                                        v-for="(item, index) in searchHistory">
                                        <i class="el-icon-close" @click="handleRemoveHistory(index,item)">

                                        </i>
                                        <span>{{ item.text }}</span>
                                    </li>
                                </ul>
                            </div>
                        </el-popover>
                    </div>

                    <div class="search-btn" @click="handleSearch">
                        <span class="el-icon-search"></span>
                    </div>
                </div>
            </div>

            <div class="search-area-contianer more-btn" @click="handleShowAdvanced(false)">
                更多搜索条件
                <span :class="!bubbleIsShow ? 'el-icon-arrow-up': 'el-icon-arrow-down'"></span>
            </div>

<!-- v-show="bubbleIsShow && tableType == 'trackingList'"
                class="select-contianer-point-tip"
                v-if="$store.state.user.userInfo.isCFUser" -->
            <p class="search-area-tip">
                更新当前雇主公司和职位可得15积分
            </p>
            <div class="upload-excel-btn" :class="(tableType != 'trackingList' || userRole == 0) ? 'single' : ''" @click="goToExcelUpload">
                <i class="icon-font icon-resume_ic_uppload_large"></i>
                上传我的Excel
            </div>
            <div v-if="tableType == 'trackingList' && userRole == 1" class="upload-excel-btn leader-feedback-btn" @click="jumpLeaderFeedback">
                Leader反馈
            </div>
            <div v-if="tableType == 'trackingList' && userRole == 2 && secondLevelTeams.length > 1" class="upload-excel-btn leader-feedback-btn">
                <el-dropdown trigger="click" placement="bottom" @command="jumpWeekReport">
                    <span class="el-dropdown-link">
                        Tracking List周报
                    </span>
                    <el-dropdown-menu class="leader-feedback-menu" slot="dropdown">
                        <el-dropdown-item v-for="team in secondLevelTeams" :key="team.teamId" :command="team">{{team.realName || team.nickName}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div v-if="tableType == 'trackingList' && userRole == 2 && secondLevelTeams.length == 1" class="upload-excel-btn leader-feedback-btn" @click="jumpWeekReport()">
                Tracking List周报
            </div>
        </div>
        <div class="draw-cell" v-show="!bubbleIsShow" >
            <select-bubble 
                ref="selectBubble" 
                :class="{'isShow' : !bubbleIsShow}" 
                @handleCleanLabel="handleCleanLabel" 
                @handleAddLabel="handleAddLabel" 
                :table-type="tableType"
            ></select-bubble>
            <div class="search-advanced" :class="{'isShow' : !bubbleIsShow}">
                <div class="search-advanced-list">
                    <el-input
                        class="search-advanced-params"
                        v-model="searchParams.title"
                        maxlength="100"
                        @keyup.enter.native="handleSearch"
                        placeholder="职位">
                        <i slot="prefix" class="icon-font icon-zhiwei1"></i>
                    </el-input>
                    <el-input
                        class="search-advanced-params"
                        v-model="searchParams.companyName"
                        maxlength="100"
                        @keyup.enter.native="handleSearch"
                        placeholder="公司">
                        <i slot="prefix" class="icon-font icon-company"></i>
                    </el-input>
                    <el-input
                        class="search-advanced-params"
                        v-model="searchParams.realName"
                        maxlength="100"
                        @keyup.enter.native="handleSearch"
                        placeholder="姓名">
                        <i slot="prefix" class="icon-font icon-ic_xingming_"></i>
                    </el-input>
                    <el-input
                        class="search-advanced-params el-input-mobile"
                        v-model="searchParams.mobile"
                        :class="{ 'is-error' : isMobileFromatError }"
                        maxlength="15"
                        @keyup.enter.native="handleSearch"
                        placeholder="手机">
                        <i slot="prefix" class="icon-font icon-ic_shouji_"></i>
                    </el-input>
                    <el-input
                        class="search-advanced-params"
                        :class="{ 'is-error' : isEmailFormatError }"
                        v-model="searchParams.email"
                        maxlength="100"
                        @keyup.enter.native="handleSearch"
                        placeholder="邮箱">
                        <i slot="prefix" class="icon-font icon-ic_youxiang_"></i>
                    </el-input>

                    <el-input
                        class="search-advanced-params"
                        v-model="searchParams.inquiryLogContent"
                        maxlength="100"
                        @keyup.enter.native="handleSearch"
                        placeholder="寻访内容">
                        <i slot="prefix" class="icon-font icon-ic_neirong_"></i>
                    </el-input>
                </div>
                <div>
                    <el-button class="btn-reset" @click="handleResetParam(false)">
                        重置
                    </el-button>
                    <el-button type="primary" class="btn-search" @click="handleSearch">
                        确定
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '#/js/util/event-bus.js';
    import ProvinceCity from '#/component/common/province-city.vue';
    import talentPoolService from '#/js/service/talentPoolService.js';
    import eventNameJson from '#/js/config/eventName.json';
    import SelectSearch from '#/component/common/select-search.vue';
    import {internationalMobile as mobileTest, email as emailTest, telephone as telephoneTest} from '#/js/util/validators.js';
    import Bubble from '#/component/common/bubble.vue'
    import SelectBubble from './selectBubble.vue';

    const eventName = eventNameJson.talentPoolTable;
    export default {
        name: "searchArea",
        componentName: "searchArea",
        components: {
            ProvinceCity,
            SelectSearch,
            Bubble,
            SelectBubble,
        },
        data() {
            return {
                provinceCityConfig: {
                    enableHeader: true,
                    enableFooter: true,
                    provinceCityIsShow: false,
                    arrowIsShow: false,
                },
                searchHistoryConfig: {
                    searchHistoryIsShow: false,
                    arrowIsShow: false,
                },
                selectedCity: {
                    text: '全国',
                    id: '',
                    isSelect: true,
                },
                searchHistory: [],
                isEmailFormatError: false,
                isMobileFromatError: false,
                hasAccurateSearch: false,
                originSearchParams: {
                    title: '',
                    keyWords: '',
                    companyName: '',
                    realName: '',
                    mobile: '',
                    email: '',
                    inquiryLogContent: '',
                    locationId: '',
                    isJobHunting: false,
                    isGrab: false,
                },
                userRole: 0, //0:管理员或一级leader 1: 普通成员  2: 二级leader
                secondLevelTeams: []
            }
        },
        props: {
            bubbleIsShow: {
                default: true,
                type: Boolean,
            },
            searchParams: {},
            tableType: String,
        },
        created() {
            this.getSearchHistory();
            this.$on(`${eventName.advancedSearch.reset}`, (paramName) => {
                this.handleCleanParam(paramName);
            }, true)

        },
        mounted() {
            if(this.tableType == 'trackingList') {
                talentPoolService
                    .getTeamInfo()
                    .then((res) => {
                        if(res.isSecondLevelTeamLeader) {
                            this.userRole = 2;
                        }else if(this.$store.state.user.userInfo.isAdminOrTeamLeader) {
                            this.userRole = 0;
                        }else {
                            this.userRole = 1;
                        }
                        this.secondLevelTeams = res.secondLevelTeams;
                    })
            }
        },
        computed: {
            searchHistoryIsHidden() {
                return this.searchHistory.length <= 0;
            },
            searchHistoryPoperClass() {
                if (this.searchHistoryIsHidden) {
                    return 'search-history-popover hidden';
                } else {
                    return 'search-history-popover'
                }
            },
            isTrackingList (){
                return this.$route.name === 'TrackingList';
            }
        },
        watch: {
            selectedCity() {
                return this.searchParams.locationId = this.selectedCity.id;
            },
            bubbleIsShow(value) {
                //若上一次收缩前没有进行搜索，再次展开的时候才清空精准搜索的输入框
                if (!value && !this.hasAccurateSearch) {
                    this.handleResetParam(true);
                }
            }
        },
        methods: {
            // advancedShow (){
            //     this.bubbleIsShow = !this.bubbleIsShow;
            // },
            // handleAddLabelSub() {
            //     this.$emit.call(this.$parent, 'handleAddLabel', arguments);
            //     // parent.$emit.apply(parent, [eventName].concat(params));
            //     // this.$parent
            // },
            handleAddLabel (label, params, isCover) {
                isCover = isCover || false;
                this.$emit('handleAddLabel',label,params,isCover);
            },
            handleCleanLabel (label, isEmit) {
                isEmit = isEmit || false;
                this.$emit('handleCleanLabel', label, isEmit);
            },
            getSearchHistory() {
                talentPoolService
                    .getSearchHistory(this.isTrackingList)
                    .then((res) => {
                        this.searchHistory = res;
                    })
            },
            handleShowAdvanced(isSearch) {
                if(!this.bubbleIsShow && isSearch){
                    return this.$emit('advancedShow');
                } else if(!isSearch){
                    return this.$emit('advancedShow');
                }
            },
            handleSearch() {
                const searchParams = this.searchParams;

                if (!this.handleValidate() || !this.handleValidateSearchParams()) {
                    return false;

                }
                this.hasAccurateSearch = true;
                for (let key in searchParams) {
                    if (searchParams[key]) {
                        switch (key){
                            case 'locationId':
                                if (this.selectedCity.text !== '全国') {
                                    this.$emit('handleAddLabel', 'city', this.selectedCity, true);
                                }
                                this.originSearchParams.locationId = this.selectedCity.id;
                                break;
                            case 'isJobHunting':
                            case 'isGrab':
                                this.originSearchParams[key] = this.searchParams[key];
                                break;
                            case 'keyWords':
                                let historyList = this.searchHistory;
                                let historyItemIndex = historyList.findIndex((item) => {
                                    return item.text === searchParams.keyWords;
                                })
                                if(historyItemIndex > -1) {
                                    historyList.splice(historyItemIndex, 1);
                                } else if(historyList.length >= 5) {
                                    historyList.pop();
                                }
                                historyList.unshift({text: searchParams.keyWords});
                                this.searchHistory = historyList;
                                //后端搜索历史接口数据更新有延时，因此先在前端进行更新
                                setTimeout(() => {
                                    this.getSearchHistory();
                                }, 60000);
                                this.$emit('handleAddLabel', key, {
                                    text: searchParams[key],
                                }, true);
                                this.originSearchParams[key] = this.searchParams[key];
                                break;
                            default:
                                this.$emit('handleAddLabel', key, {
                                    text: searchParams[key],
                                }, true);
                                this.originSearchParams[key] = this.searchParams[key];
                                break;
                        }
                    } else {
                        this.originSearchParams[key] = this.searchParams[key];
                        this.$emit('handleCleanLabel', key !== 'locationId' ? key : 'city');
                    }
                }
                this.handleShowAdvanced(true)
                // return eventBus.$emit('advancedSearch', searchParams);
            },
            handleResetParam(onlyAdvanced) {
                this.hasAccurateSearch = false;
                onlyAdvanced = onlyAdvanced || false;
                const params = this.searchParams,
                    labelList = ['companyName', 'title', 'realName', 'inquiryLogContent', 'email', 'mobile'];
                labelList.forEach(key => {
                    if (params[key] === '') {
                        return;
                    }
                    switch (key) {
                        case 'locationId':
                            if (onlyAdvanced) {
                                break;
                            }
                            this.selectedCity = {
                                text: '全国',
                                id: '',
                                isSelect: true,
                            };
                            this.$refs.provinceCity.clearSelectedCity();
                            this.$emit('handleCleanLabel', 'city');
                            break;
                        case 'keyWords':
                            if (onlyAdvanced) {
                                break;
                            }
                            this.$emit('handleCleanLabel', key);
                            break;
                        default:
                            this.$emit('handleCleanLabel', key);
                            break;
                    }
                    this.originSearchParams[key] = '';
                    params[key] = '';
                });
            },
            handleCleanParam(label) {
                const params = this.searchParams;
                if (label === 'city') {
                    params.locationId = '';
                    this.originSearchParams.locationId = '';
                    this.selectedCity = {
                        text: '全国',
                        id: '',
                        isSelect: true,
                    };
                    this.$refs.provinceCity.clearSelectedCity();
                } else {
                    this.originSearchParams[label] = '';
                    params[label] = '';
                }
                //return this.handleSearch();
            },
            handleCitySelectConfirm() {
                if (this.$refs.provinceCity.selectedCity[0]) {
                    this.selectedCity = this.$refs.provinceCity.selectedCity[0];
                } else {
                    this.selectedCity = {
                        text: '全国',
                        id: '',
                        isSelect: true,
                    };
                    this.$emit('handleCleanLabel', 'city', true);
                }

                if (this.selectedCity.text !== '全国') {
                    this.$emit('handleAddLabel', 'city', this.selectedCity, true);
                }
                this.$refs.provinceCity.currentIndex = undefined;
                this.$refs.provinceCity.selectedProvince = '';
                return this.provinceCityConfig.provinceCityIsShow = false;
            },
            handleCitySelectCancel() {
                if (this.$refs.provinceCity.selectedCity[0] && this.$refs.provinceCity.selectedCity[0].id !== this.selectedCity.id) {
                    this.$refs.provinceCity.selectCity(this.selectedCity);
                }
                this.$refs.provinceCity.currentIndex = undefined;
                this.$refs.provinceCity.selectedProvince = '';
                return this.provinceCityConfig.provinceCityIsShow = false;
            },
            handleRemoveHistory(index, item) {
                window.event.stopPropagation();
                talentPoolService
                    .removeSearchHistory(item.text)
                    .then((res) => {
                        setTimeout(() => {
                            this.searchHistory.splice(index, 1);
                        }, 400)
                    }, (err) => {
                        console.log(err);
                    })
            },
            handleSelectKeyWordsHistory(keyWords) {
                if (this.searchParams.keyWords !== keyWords) {
                    this.searchParams.keyWords = keyWords;
                }
                // 搜索历史点击埋点
                _tracker.track('SearchHistoryClick', JSON.stringify({
                    keyWords: keyWords
                }));
                this.searchHistoryConfig.searchHistoryIsShow = false;
                return this.handleSearch();
            },
            handleInput() {
                this.searchHistoryConfig.searchHistoryIsShow = false;
            },
            handleValidate() {
                let status = true,
                    errorContent = '';
                if (this.searchParams.mobile && !(mobileTest(this.searchParams.mobile) || telephoneTest(this.searchParams.mobile))) {
                    errorContent = errorContent + '手机号码';
                    this.isMobileFromatError = true;
                    status = false;
                } else {
                    this.isMobileFromatError = false;
                }

                if (this.searchParams.email && !emailTest(this.searchParams.email)) {
                    errorContent = errorContent + '邮箱';
                    this.isEmailFormatError = true;
                    status = false;
                } else {
                    this.isEmailFormatError = false;
                }

                if (status === false) {
                    shortTips(`${errorContent}格式错误！`);
                }
                return status;
            },
            handleValidateSearchParams(){
                let status = false,isEmpty = false;
                const params = this.searchParams,orderParams = this.originSearchParams;
                Object.keys(params).some( key => {
                    switch(key) {
                        case "locationId":
                            return ;
                    }
                    if(params[key]){
                        isEmpty = true;
                        return status = params[key] !== orderParams[key];
                    } else {
                        return status = params[key] !== orderParams[key];
                    }
                });
                return (status === true || isEmpty === true);
            },
            goToExcelUpload() {
                // window.open("/Headhunting/MyCompany.html?type=0#/resumeUpload/excelUpload", "_blank");
                window.open("/?type=0#/resumeUpload/excelUpload", "_blank");
            },
            jumpLeaderFeedback() {
                // window.open(`/Headhunting/MyCompany.html#/trackingListComment`, "_blank");
                window.open(`/#/trackingListComment`, "_blank");
            },
            jumpWeekReport(team) {
                team = team || this.secondLevelTeams[0];
                let name = team.realName || team.nickName;
                // window.open(`/Headhunting/MyCompany.html#/trackingListComment?teamId=${team.teamId}&teamName=${name}`, "_blank");
                window.open(`/#/trackingListComment?teamId=${team.teamId}&teamName=${name}`, "_blank");
            }
        }
    }
</script>

<style lang="scss" scoped>
    .search-area {
        // width: 572px;
        // margin: 0 auto 10px auto;
        margin-bottom: 4px;
        margin-left: 270px;
        // margin-top: 10px;

        .search-input {
            display: flex;
            flex-direction: row;
            width: 457px;
            height: 28px;
            background-color: #F8F8F8;
            border: 1px solid #DDDDDD;
            border-radius: 4px;

            & > * {

            }

            .search-city-btn {
                position: relative;
                text-align: center;
                line-height: 26px;
                cursor: pointer;

                /deep/ .el-popover__reference {
                    min-width: 66px;
                    display: block;
                }

                /deep/ .el-icon-arrow-down {
                    margin-left: 4px;
                    font-size: 12px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 2px;
                    height: 18px;
                    background-color: #DDDDDD;
                }
            }

            .search-keywords-input {
                width: 100%;
                vertical-align: top;
            }

            input {
                padding-left: 6px;
                max-width: 499px;
                width: 100%;
                height: 26px;
                line-height: 26px;
                outline: unset;
                border: unset;
                background-color: #F8F8F8;
                vertical-align: top;
            }

            .search-btn {
                position: relative;
                min-width: 40px;
                height: 26px;
                padding-top: 1.5px;
                background-color: $primary;
                text-align: center;
                line-height: 23px;
                color: #fff;
                font-size: 20px;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    right: -1px;
                    bottom: -1px;
                    border: 1px solid $primary;
                    pointer-events: none;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }

        .search-area-contianer {
            display: inline-block;
            vertical-align: top;
        }

        .more-btn {
            // position: relative;
            // top: 6px;
            margin-left: 10px;
            color: $primary;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            vertical-align: middle;
            height: 28px;
            line-height: 28px;
        }

        .search-area-tip{
            display: inline-block;
            color: #EE6E4F;
            font-size: 14px;
            margin-left: 10px;
            vertical-align: -1px;
        }
    }
    .upload-excel-btn {
        position: absolute;
        top: 10px;
        right: 164px;
        width: 130px;
        height: 26px;
        line-height: 24px;
        color: $primary;
        border: 1px solid $primary;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;

        &.single {
            right: 20px;
        }

        .icon-font, .el-dropdown-link {
            color: $primary;
        }
        &:hover {
            background: $primary;
            color: #fff;
            .icon-font, .el-dropdown-link {
                color: #fff;
            }
        }
    }
    .leader-feedback-btn {
        right: 20px;
    }
    .search-advanced {
        display: flex;
        padding: 10px 20px 0px 20px;
        // border: 1px solid #ddd;
        // border-radius: 4px;
        border-top: 1px dashed #ccc;
        margin-top: 10px;

        /deep/ .search-advanced-params {
            margin-right: 20px;
            margin-bottom: 10px;
            width: 240px;

            .icon-font {
                color: $primary;
                line-height: 28px;

                &.icon-ic_youxiang_ {
                    font-size: 12px;
                }
            }

             .el-input__prefix {
                left: 10px;
            }

             .el-input__inner {
                padding-left: 36px;
                height: 28px;
                line-height: 28px;
            }

            &.is-error /deep/ .el-input__inner {
                border-color: $red;
                box-shadow: 0 0 5px $red;
            }
        }

        /deep/ .el-button {
            display: block;
            margin: 0 0 10px 0;
        }

        .btn-reset, .btn-search {
            height: 28px;
            line-height: 26px;
            font-size: 14px;
            min-width: 84px;
            padding: 0;
            border-radius: 4px;
        }
        .btn-reset{
            border: 1px solid #DDD;
        }

        .btn-reset {
            background-color: white;
            color: #999;

            &:hover, &:active {
                background-color: #F8F8F8;
            }
        }
    }

    .search-history {
        &-header {
            padding: 10px 30px;
            font-size: 12px;
            color: #999999;
        }

        &-li {
            position: relative;
            padding: 10px 30px;
            height: 39px;
            font-size: 14px;
            color: #666666;
            background-color: #fff;
            transition: .3s;
            cursor: pointer;
            white-space: nowrap;

            &:hover {
                color: $primary;
                background-color: rgba($primary, .1);
            }

            /*&.show {*/
            /*left: 0px;*/
            /*opacity: 1;*/
            /*}*/

            /*&.remove{*/
            /*left: 60px;*/
            /*opacity: 0;*/
            /*}*/

            .el-icon-close {
                float: right;
                color: #999;
                cursor: pointer;
                line-height: 22px;

                &:hover {
                    color: $primary;
                }
            }

            span {
                display: inline-block;
                max-width: 270px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .search-city-content {
        padding: 0 10px;
        white-space: nowrap;

        .el-icon-arrow-down {
            top: 14px;
        }
    }

    @media screen and (max-width: 1300px) {
        .search-area {
            margin: 0 161px 20px 161px;
        }
    }

    .draw-cell{
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding-top: 10px;
    }
</style>

<style lang="scss">
    .search-history-popover {
        padding: 0 !important;

        &.hidden {
            display: none !important;
        }
    }

    .leader-feedback-menu {
        min-width: 124px;
        max-width: 300px;

        .el-dropdown-menu__item {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
</style>
