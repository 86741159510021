var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.count > 0 && _vm.isFirm
        ? _c(
            "el-popover",
            {
              ref: "recommendPopover",
              attrs: {
                placement: "bottom-start",
                width: "660",
                trigger: "hover",
                "popper-class": "recommend-info",
                "visible-arrow": false,
                "open-delay": 700,
              },
              on: { show: _vm.showDetails },
            },
            [
              _vm.isAgreementJob
                ? _c("el-alert", {
                    staticStyle: {
                      "margin-top": "8px",
                      "margin-bottom": "8px",
                    },
                    attrs: {
                      title: `当前显示 ${_vm.recomendationsList.length} 条`,
                      closable: false,
                      type: "error",
                    },
                  })
                : _vm._e(),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "recommend-status-stage",
                },
                [
                  _vm._l(_vm.recomendationsList, function (recommend, index) {
                    return _c("li", { key: index }, [
                      _c(
                        "div",
                        { staticClass: "status-info" },
                        [
                          _c("span", { staticClass: "status-summary" }, [
                            recommend.canAccessJob
                              ? _c("a", {
                                  staticClass: "text-secTxt link-hov",
                                  attrs: {
                                    target: "_blank",
                                    href: recommend.jobUrl,
                                    title: recommend.jobInfo,
                                  },
                                  domProps: {
                                    textContent: _vm._s(recommend.jobInfo),
                                  },
                                })
                              : _vm._e(),
                            !recommend.canAccessJob
                              ? _c("span", {
                                  staticClass: "text-secTxt",
                                  attrs: { title: recommend.jobInfo },
                                  domProps: {
                                    textContent: _vm._s(recommend.jobInfo),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _c(
                            "el-tag",
                            {
                              staticClass: "recommend-tag",
                              class: recommend.recommendTheme,
                              attrs: { effect: "plain" },
                            },
                            [_vm._v(_vm._s(recommend.recommendState))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "attachment",
                              class: recommend.recommendTheme,
                            },
                            [_vm._v(_vm._s(recommend.recommendAttachment))]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "update-time" }, [
                        recommend.canAccessRecommendation
                          ? _c(
                              "a",
                              {
                                staticClass: "link-hov",
                                attrs: {
                                  href: recommend.recommendUrl,
                                  target: "_blank",
                                  title: recommend.created + " 推荐",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(recommend.created)
                                      ) + " 推荐 "
                                    ),
                                    _c("font-icon", {
                                      staticClass: "svg-dbarrow",
                                      attrs: {
                                        href: "#icon-double-right-arrow",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  _vm.count > 3
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "more-link",
                            attrs: {
                              href: `/#/candidateDetail/${_vm.resumeId}/recommendationList`,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v("更多\n                "),
                            _c("i", { staticClass: "el-icon-d-arrow-right" }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                { attrs: { slot: "reference" }, slot: "reference" },
                [
                  _c("font-icon", {
                    staticClass: "table-body-icon-big icon-inquiry",
                    attrs: { href: "#icon-talent_recommend" },
                  }),
                  _c("span", [_vm._v(" " + _vm._s(_vm.count))]),
                ],
                1
              ),
            ],
            1
          )
        : _c("span", [_vm._v("—")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }