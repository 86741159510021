var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "talent-pool-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            lazy: "",
            border: "",
            height: "700",
            "row-key": "rowKey",
            "tooltip-effect": "light",
            load: _vm.load,
            data: _vm.tableData,
            "tree-props": { children: "children", hasChildren: "hasChildren" },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              width: "42",
              align: "center",
              type: "selection",
              "header-align": "center",
            },
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                fixed: "",
                width: "120",
                align: "left",
                "header-align": "left",
                label: "最近操作时间",
                "class-name": "table-header-dropdown-wrapper",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.subIndex == -1
                        ? _c("operation-info", {
                            attrs: {
                              time: scope.row.resumeListItem.latestLogTime,
                              "resume-id": scope.row.resumeListItem.resumeId,
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-dropdown",
                    {
                      staticClass: "table-header-dropdown",
                      attrs: { trigger: "click" },
                      on: { command: _vm.handleOperateTitle },
                    },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _vm._v(
                          _vm._s(
                            _vm.operateType == 0 ? "最近操作时间" : "综合排序"
                          )
                        ),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "table-header-dropdown-menu",
                          attrs: {
                            slot: "dropdown",
                            "visible-arrow": false,
                            offset: 20,
                          },
                          slot: "dropdown",
                        },
                        [
                          _c(
                            "el-dropdown-item",
                            {
                              class:
                                _vm.operateType == 0
                                  ? "el-dropdown-item-selected"
                                  : "",
                              attrs: { command: "latestLogTime" },
                            },
                            [_vm._v("最近操作时间")]
                          ),
                          _c(
                            "el-dropdown-item",
                            {
                              class:
                                _vm.operateType == 1
                                  ? "el-dropdown-item-selected"
                                  : "",
                              attrs: { command: "composite" },
                            },
                            [_vm._v("综合排序")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                fixed: "",
                label: "姓名",
                width: "185",
                "header-align": "left",
                "class-name": "name-cell",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.resumeListItem.isJobHunting
                        ? _c(
                            "span",
                            [
                              _c("font-icon", {
                                staticClass: "table-body-icon icon-job-hunting",
                                attrs: { href: "#icon-talent_qiu" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            content: scope.row.resumeListItem.realName,
                            placement: "top",
                            "open-delay": _vm.popoverDelay,
                            disabled:
                              scope.row.resumeListItem.realName.length <= 3,
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "candidate-link",
                              attrs: {
                                target: "_blank",
                                href: "javascript:void(0)",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleJumpDetail(scope.row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(scope.row.resumeListItem.realName) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      scope.row.resumeListItem.isFirmResume
                        ? _c(
                            "span",
                            [
                              _c("font-icon", {
                                staticClass: "table-body-icon tip-margin",
                                attrs: { href: "#icon-talent_qi" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !scope.row.resumeListItem.isFirmResume
                        ? _c(
                            "span",
                            [
                              _c("font-icon", {
                                staticClass: "table-body-icon tip-margin",
                                attrs: { href: "#icon-talent_si" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isCFUser
                        ? _c(
                            "span",
                            [
                              scope.row.resumeListItem.resumeGrabType == 1
                                ? _c("font-icon", {
                                    staticClass: "table-body-icon tip-margin",
                                    attrs: { href: "#icon-talent_zhan" },
                                  })
                                : _vm._e(),
                              scope.row.resumeListItem.resumeGrabType == 2
                                ? _c("font-icon", {
                                    staticClass: "table-body-icon tip-margin",
                                    attrs: { href: "#icon-talent_zhan_mh" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      scope.row.resumeListItem.resumeReminders.length > 0
                        ? _c(
                            "span",
                            {
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.getReminder(scope.row)
                                },
                              },
                            },
                            [
                              _c("reminder-info", {
                                staticClass: "tip-margin",
                                attrs: {
                                  reminders:
                                    scope.row.resumeListItem.resumeReminders,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("resume-operation", {
                        staticClass: "tip-margin",
                        attrs: {
                          tableType: _vm.tableType,
                          type: 0,
                          resume: scope.row,
                        },
                      }),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "span",
                  { staticClass: "table-header-tip-cell" },
                  [
                    _vm._v("\n                    姓名\n                    "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          offset: 18,
                          width: "430",
                          trigger: "hover",
                          "visible-arrow": false,
                          placement: "bottom-start",
                          "popper-class":
                            "talentpool-name-popover talentpool-popover",
                        },
                      },
                      [
                        _c("div", { staticClass: "popover-card" }, [
                          _c("div", { staticClass: "card-tip" }, [
                            _c(
                              "span",
                              { staticClass: "tip-icon" },
                              [
                                _c("font-icon", {
                                  staticClass: "table-body-icon",
                                  attrs: { href: "#icon-talent_si" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(
                              "\n                                个人库简历\n                            "
                            ),
                          ]),
                          _c("div", { staticClass: "card-tip" }, [
                            _c(
                              "span",
                              { staticClass: "tip-icon" },
                              [
                                _c("font-icon", {
                                  staticClass: "table-body-icon",
                                  attrs: { href: "#icon-talent_qi" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(
                              "\n                                企业库简历\n                            "
                            ),
                          ]),
                          _c("div", { staticClass: "card-tip" }, [
                            _c("span", { staticClass: "tip-icon" }, [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "icon table-body-icon table-body-icon-tx",
                                  attrs: { "aria-hidden": "true" },
                                },
                                [
                                  _c("use", {
                                    staticClass: "tx-size",
                                    attrs: { "xlink:href": "#icon-talent_tx" },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(
                              "\n                                添加了事项提醒"
                            ),
                            _c("span", { staticClass: "color-primary" }, [
                              _vm._v("（未完成）"),
                            ]),
                          ]),
                          _vm.isCFUser
                            ? _c("div", { staticClass: "card-tip" }, [
                                _c(
                                  "span",
                                  { staticClass: "tip-icon" },
                                  [
                                    _c("font-icon", {
                                      staticClass: "table-body-icon",
                                      attrs: { href: "#icon-talent_zhan" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(
                                  "\n                                系统判断当前个人库简历不在企业库时，显示【占】的标签\n                            "
                                ),
                              ])
                            : _vm._e(),
                          _vm.isCFUser
                            ? _c(
                                "div",
                                { staticClass: "card-tip occupy-flex" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "tip-icon tip-occupy" },
                                    [
                                      _c("font-icon", {
                                        staticClass: "table-body-icon",
                                        attrs: { href: "#icon-talent_zhan_mh" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      "\n                                    系统判断当前个人库简历更新鲜更完整时，可以更新覆盖企业库简历，显示【占标签】\n                                "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "icon-question",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c("font-icon", {
                              staticClass:
                                "table-header-tip-icon question-hover",
                              attrs: { href: "#icon-talent_ql" },
                            }),
                            _c("font-icon", {
                              staticClass:
                                "table-header-tip-icon question-no-hover",
                              attrs: { href: "#icon-talent_qd" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _vm._l(_vm.titleStore.slice(2), function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                "header-align": "left",
                label: _vm.tableTitleMap[item].label,
                "min-width": _vm.tableTitleMap[item].minWidth,
                "class-name": _vm.tableTitleMap[item].customClass,
                "show-overflow-tooltip":
                  _vm.tableTitleMap[item].showOverflowTooltip,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function ({ column }) {
                      return [
                        _vm.tableTitleMap[item].isHeaderSelect
                          ? [
                              _vm.tableData.length > 0
                                ? _c(
                                    "el-dropdown",
                                    {
                                      staticClass: "table-header-dropdown",
                                      attrs: {
                                        trigger: "click",
                                        placement: "bottom",
                                      },
                                      on: {
                                        command:
                                          _vm.handleAddTrackingListSource,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "el-dropdown-link" },
                                        [
                                          _vm._v(
                                            "\n                            加入渠道\n                            "
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          staticClass:
                                            "table-header-dropdown-menu",
                                          attrs: {
                                            slot: "dropdown",
                                            "visible-arrow": false,
                                          },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.addTrackingListSource == 0
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: { command: "bothSource" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                全部\n                            "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.addTrackingListSource == 1
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: { command: "firmSource" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                企业库\n                            "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              class:
                                                _vm.addTrackingListSource == 2
                                                  ? "el-dropdown-item-selected"
                                                  : "",
                                              attrs: {
                                                command: "personalSource",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                个人库\n                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c("span", [_vm._v("加入渠道")]),
                            ]
                          : [_vm._v(_vm._s(column.label))],
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.tableTitleMap[item].isSimpleText
                          ? [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    scope.row.resumeListItem[
                                      _vm.tableTitleMap[item].name
                                    ] || "—"
                                  ) +
                                  "\n                "
                              ),
                            ]
                          : [
                              _vm.tableTitleMap[item].name ==
                              "communicationCount"
                                ? [
                                    scope.row.resumeListItem.isFirmResume
                                      ? _c("communication-job", {
                                          ref: "communicationJob",
                                          refInFor: true,
                                          attrs: {
                                            count:
                                              scope.row.resumeListItem
                                                .communicationCount,
                                            "resume-id":
                                              scope.row.resumeListItem.resumeId,
                                          },
                                          on: {
                                            "add-communication": function (
                                              $event
                                            ) {
                                              return _vm.resumeOperation(
                                                "addCommunication",
                                                { resume: scope.row }
                                              )
                                            },
                                            "add-schedule": (communication) =>
                                              _vm.resumeOperation(
                                                "addSchedule",
                                                {
                                                  resume: scope.row,
                                                  communicationInfo:
                                                    communication,
                                                }
                                              ),
                                            "update-item": function ($event) {
                                              return _vm.handleUpdateItem(
                                                scope.row
                                              )
                                            },
                                          },
                                        })
                                      : _c("span", [_vm._v("—")]),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name ==
                              "recomendationCount"
                                ? [
                                    _c("recommend-info", {
                                      attrs: {
                                        isMyRecommend: true,
                                        count:
                                          scope.row.resumeListItem
                                            .recomendationCount,
                                        isFirm:
                                          scope.row.resumeListItem.isFirmResume,
                                        "candidate-id":
                                          scope.row.resumeListItem.candidateId,
                                        "resume-id":
                                          scope.row.resumeListItem.resumeId,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "mobile"
                                ? [
                                    ((scope.row.resumeListItem.contactStatus &&
                                      scope.row.resumeListItem.contactStatus
                                        .mobileStatus === 3) ||
                                      !scope.row.resumeListItem
                                        .contactStatus) &&
                                    scope.row.resumeListItem.mobile === ""
                                      ? _c(
                                          "span",
                                          { staticClass: "empty-bar" },
                                          [_vm._v("—")]
                                        )
                                      : _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "bottom-start",
                                              trigger: "hover",
                                              "popper-class":
                                                "talentpool-phone-popover talentpool-popover",
                                              "visible-arrow": false,
                                              "open-delay": _vm.popoverDelay,
                                            },
                                          },
                                          [
                                            scope.row.resumeListItem
                                              .contactStatus
                                              ? [
                                                  scope.row.resumeListItem
                                                    .contactStatus
                                                    .mobileStatus === 2
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "phone-popover-content",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.isAdministrator
                                                                ? "候选人正在入职保证期中，联系方式已被上锁，您可以进行解锁"
                                                                : "候选人正在入职保证期中，联系方式已被上锁"
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  scope.row.resumeListItem
                                                    .contactStatus
                                                    .mobileStatus === 3
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "phone-popover-content",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "点击拨打：" +
                                                              _vm._s(
                                                                scope.row
                                                                  .resumeListItem
                                                                  .mobile
                                                              )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  scope.row.resumeListItem
                                                    .contactStatus
                                                    .mobileStatus === 4
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "phone-popover-content",
                                                        },
                                                        [
                                                          _vm._v("号码失效："),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "line-through",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .resumeListItem
                                                                    .mobile
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  scope.row.resumeListItem
                                                    .contactStatus
                                                    .mobileStatus === 1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "phone-popover-content",
                                                        },
                                                        [_vm._v("点击查看手机")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "phone-popover-content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "点击拨打" +
                                                          _vm._s(
                                                            scope.row
                                                              .resumeListItem
                                                              .mobile
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "reference" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.handleMobile(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                slot: "reference",
                                              },
                                              [
                                                scope.row.resumeListItem
                                                  .contactStatus
                                                  ? [
                                                      scope.row.resumeListItem
                                                        .contactStatus
                                                        .mobileStatus === 2
                                                        ? _c("font-icon", {
                                                            staticClass:
                                                              "table-body-icon-big",
                                                            class: {
                                                              "cursor-pointer":
                                                                _vm.isAdministrator,
                                                            },
                                                            attrs: {
                                                              href: "#icon-talent_locked",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      scope.row.resumeListItem
                                                        .contactStatus
                                                        .mobileStatus === 3
                                                        ? _c("call-phone-btn", {
                                                            ref: scope.row
                                                              .resumeListItem
                                                              .contactStatus
                                                              ? "callPhoneBtn_" +
                                                                scope.$index
                                                              : "",
                                                            refInFor: true,
                                                            attrs: {
                                                              item: scope.row
                                                                .resumeListItem,
                                                            },
                                                            on: {
                                                              handleCall:
                                                                _vm.handleCallPhoneDialog,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      scope.row.resumeListItem
                                                        .contactStatus
                                                        .mobileStatus === 4
                                                        ? _c("font-icon", {
                                                            staticClass:
                                                              "table-body-icon-big",
                                                            attrs: {
                                                              href: "#icon-talent_shixiao",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      scope.row.resumeListItem
                                                        .contactStatus
                                                        .mobileStatus === 1
                                                        ? _c("font-icon", {
                                                            staticClass:
                                                              "table-body-icon-big cursor-pointer",
                                                            attrs: {
                                                              href: "#icon-talent_closeeye",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  : [
                                                      _c("call-phone-btn", {
                                                        ref: scope.row
                                                          .resumeListItem
                                                          .contactStatus
                                                          ? ""
                                                          : "callPhoneBtn_" +
                                                            scope.$index,
                                                        refInFor: true,
                                                        attrs: {
                                                          item: scope.row
                                                            .resumeListItem,
                                                        },
                                                        on: {
                                                          handleCall:
                                                            _vm.handleCallPhoneDialog,
                                                        },
                                                      }),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ],
                                          2
                                        ),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "email"
                                ? [
                                    ((scope.row.resumeListItem.contactStatus &&
                                      scope.row.resumeListItem.contactStatus
                                        .emailStatus === 3) ||
                                      !scope.row.resumeListItem
                                        .contactStatus) &&
                                    scope.row.resumeListItem.email === ""
                                      ? _c(
                                          "span",
                                          { staticClass: "empty-bar" },
                                          [_vm._v("—")]
                                        )
                                      : _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              trigger: "hover",
                                              "visible-arrow": false,
                                              placement: "bottom-start",
                                              "open-delay": _vm.popoverDelay,
                                              "popper-class":
                                                "talentpool-email-popover talentpool-popover",
                                            },
                                          },
                                          [
                                            scope.row.resumeListItem
                                              .contactStatus
                                              ? [
                                                  scope.row.resumeListItem
                                                    .contactStatus
                                                    .emailStatus === 2
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "email-popover-content",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.isAdministrator
                                                                ? "候选人正在入职保证期中，联系方式已被上锁，您可以进行解锁"
                                                                : "候选人正在入职保证期中，联系方式已被上锁"
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  scope.row.resumeListItem
                                                    .contactStatus
                                                    .emailStatus === 3
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "email-popover-content",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .resumeListItem
                                                                .email
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  scope.row.resumeListItem
                                                    .contactStatus
                                                    .emailStatus === 4
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "email-popover-content line-through",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .resumeListItem
                                                                .email
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  scope.row.resumeListItem
                                                    .contactStatus
                                                    .emailStatus === 1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "email-popover-content",
                                                        },
                                                        [_vm._v("点击查看邮箱")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "email-popover-content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row
                                                            .resumeListItem
                                                            .email
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "reference" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleMail(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                slot: "reference",
                                              },
                                              [
                                                scope.row.resumeListItem
                                                  .contactStatus
                                                  ? [
                                                      scope.row.resumeListItem
                                                        .contactStatus
                                                        .emailStatus === 2
                                                        ? _c("font-icon", {
                                                            staticClass:
                                                              "table-body-icon-big",
                                                            class: {
                                                              "cursor-pointer":
                                                                _vm.isAdministrator,
                                                            },
                                                            attrs: {
                                                              href: "#icon-talent_locked",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      scope.row.resumeListItem
                                                        .contactStatus
                                                        .emailStatus === 3
                                                        ? _c("font-icon", {
                                                            staticClass:
                                                              "table-body-icon-big",
                                                            attrs: {
                                                              href: "#icon-talent_mail",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      scope.row.resumeListItem
                                                        .contactStatus
                                                        .emailStatus === 4
                                                        ? _c("font-icon", {
                                                            staticClass:
                                                              "table-body-icon-big",
                                                            attrs: {
                                                              href: "#icon-talent_shixiao",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      scope.row.resumeListItem
                                                        .contactStatus
                                                        .emailStatus === 1
                                                        ? _c("font-icon", {
                                                            staticClass:
                                                              "table-body-icon-big cursor-pointer",
                                                            attrs: {
                                                              href: "#icon-talent_closeeye",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  : [
                                                      _c("font-icon", {
                                                        staticClass:
                                                          "table-body-icon-big",
                                                        attrs: {
                                                          href: "#icon-talent_mail",
                                                        },
                                                      }),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ],
                                          2
                                        ),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "inquiryInfo"
                                ? [
                                    scope.row.resumeListItem.inquiryLogsCount >
                                    0
                                      ? _c("inquiry-log", {
                                          attrs: { resume: scope.row },
                                          on: {
                                            "update-item": _vm.handleUpdateItem,
                                          },
                                        })
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "cursor-pointer",
                                            on: {
                                              click: function ($event) {
                                                return _vm.resumeOperation(
                                                  "addVisit",
                                                  { resume: scope.row }
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              staticClass:
                                                "table-body-icon-big icon-inquiry",
                                              attrs: {
                                                href: "#icon-talent_addinquiry",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "color-warn" },
                                              [_vm._v(" 添加")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "label"
                                ? [
                                    scope.row.resumeListItem.tags.length > 0
                                      ? _c("add-itap-popover", {
                                          attrs: {
                                            tagData: scope.row,
                                            isResume: true,
                                          },
                                        })
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "cursor-pointer",
                                            on: {
                                              click: function ($event) {
                                                return _vm.resumeOperation(
                                                  "addItap",
                                                  { resume: scope.row }
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              staticClass:
                                                "table-body-icon-big icon-inquiry",
                                              attrs: {
                                                href: "#icon-talent_lable",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "color-warn" },
                                              [_vm._v(" 添加")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "yearOfExperience"
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-slot table-slot-content",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("yearValue")(
                                                scope.row.resumeListItem
                                                  .yearOfExperience
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "degree"
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("degreeValue")(
                                            scope.row.resumeListItem.degree
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "recommendInfo"
                                ? [
                                    _c("recommend-info", {
                                      attrs: {
                                        isMyRecommend: false,
                                        count:
                                          scope.row.resumeListItem
                                            .recomendationCount,
                                        isFirm:
                                          scope.row.resumeListItem.isFirmResume,
                                        "candidate-id":
                                          scope.row.resumeListItem.candidateId,
                                        "resume-id":
                                          scope.row.resumeListItem.resumeId,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "source"
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.setSourceFrom(
                                            scope.row.resumeListItem
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "uploader"
                                ? [
                                    _c("avatar", {
                                      attrs: {
                                        userId: scope.row.resumeListItem
                                          .creatorId
                                          ? scope.row.resumeListItem.creatorId
                                          : _vm.defaultCreatorId,
                                        src: scope.row.resumeListItem.avatarUrl
                                          ? scope.row.resumeListItem.avatarUrl
                                          : _vm.defaultCreatorAvatar,
                                        enableLink: false,
                                        isTalentPool: true,
                                        size: "sm",
                                        shareData: _vm.getShareData(
                                          scope.row.resumeListItem
                                        ),
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "attachments"
                                ? [
                                    _c("attachments", {
                                      attrs: { resume: scope.row },
                                      on: {
                                        "update-item": _vm.handleUpdateItem,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "addFrom"
                                ? [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "light",
                                          placement: "top",
                                          "open-delay": _vm.popoverDelay,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  scope.row.resumeListItem
                                                    .addTrackingListSourceDetail
                                                ) +
                                                _vm._s(
                                                  scope.row.resumeListItem.addTrackingListSourceDetail.indexOf(
                                                    "FloatingList"
                                                  ) === -1
                                                    ? "手动加入"
                                                    : "自动加入"
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.resumeListItem
                                                .addTrackingListSource
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.tableTitleMap[item].name == "recommendReason"
                                ? [
                                    scope.row.resumeListItem.tempRecommendReason
                                      ? _c("recommend-reason-popover", {
                                          attrs: { resume: scope.row },
                                          on: {
                                            "update-item": _vm.handleUpdateItem,
                                          },
                                        })
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "cursor-pointer",
                                            on: {
                                              click: function ($event) {
                                                return _vm.resumeOperation(
                                                  "editRecommendReason",
                                                  { resume: scope.row }
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              staticClass:
                                                "table-body-icon-big icon-inquiry",
                                              attrs: {
                                                href: "#icon-talent_honer_red",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "color-warn" },
                                              [_vm._v(" 添加")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                : _vm._e(),
                            ],
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("template", { slot: "empty" }, [
            _c("div", [
              _c("span", { staticClass: "empty-img" }),
              _c("span", { staticClass: "empty-data" }, [
                _vm._v(
                  "\n                    暂无搜索数据，可尝试扩大搜索时间范围\n                    "
                ),
              ]),
            ]),
          ]),
          _vm.timeLimitShow
            ? _c("template", { slot: "append" }, [
                _c("span", { staticClass: "empty-data" }, [
                  _vm._v(
                    "\n                搜索数据太少，可尝试扩大搜索时间范围\n                "
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "work-table-footer" },
        [
          _c(
            "div",
            { staticClass: "footer-left" },
            [
              _c("div", { staticClass: "footer-select" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.allSelect,
                      expression: "allSelect",
                    },
                  ],
                  attrs: { hidden: "", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.allSelect)
                      ? _vm._i(_vm.allSelect, null) > -1
                      : _vm.allSelect,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.allSelect,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.allSelect = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.allSelect = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.allSelect = $$c
                      }
                    },
                  },
                }),
                _c("span", {
                  staticClass: "all-select-checkbox",
                  on: { click: _vm.handleAllSelect },
                }),
                _c("span", [_vm._v("全选")]),
                _c("span", { staticClass: "select-count" }, [
                  _vm._v("已选择 "),
                  _c("span", { staticClass: "color-orange" }, [
                    _vm._v(_vm._s(_vm.multipleSelection.length)),
                  ]),
                  _vm._v(" 位候选人"),
                ]),
              ]),
              _c("batch-operation", {
                attrs: { selectList: _vm.multipleSelection },
                on: { "batch-operation-done": _vm.handleOperationDone },
              }),
            ],
            1
          ),
          _c(
            "el-pagination",
            {
              staticClass: "el-pagination-workTable",
              attrs: {
                "current-page": _vm.page.current,
                "page-sizes": [20, 30, 50],
                "page-size": _vm.page.size,
                layout: "total, sizes, prev, pager, next, slot",
                total: _vm.page.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("前往"),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("页"),
                  ],
                  1
                ),
                _c("span", { on: { click: _vm.handlePagerJump } }, [
                  _vm._v("跳转"),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("phone-call", {
        ref: "phoneCallDialog",
        attrs: {
          "call-to": _vm.phoneCall.callTo,
          "object-id": _vm.phoneCall.candidateId,
          tbdResumeId: _vm.phoneCall.resumeId,
          sourceFrom: "talentPool",
        },
        on: { cancelPhoneCall: _vm.handleCancelPhoneCall },
      }),
      _c("mail-dialog", { ref: "mailDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }