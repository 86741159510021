<template>
    <div class="search-label">
        <div class="float-left">
            <span>共 <span class="color-orange">{{ count }}</span> 位候选人</span>
        </div>

        <div class="float-right">
            <label class="search-radio" for="isJobHunting">
                <input type="checkbox" class="search-radio__input" hidden v-model="searchParams.isJobHunting" id="isJobHunting">
                <span class="search-radio__container">
                    <span class="search-radio__inner">

                    </span>
                    <span class="search-radio__content">
                        求职中的人 {{ jobHuntingCount }}
                    </span>
                </span>
            </label>

            <label class="search-radio grab-radio" for="isGrab">
                <input type="checkbox" class="search-radio__input" hidden v-model="searchParams.isGrab" id="isGrab">
                <span class="search-radio__container">
                    <span class="search-radio__inner">

                    </span>
                    <span class="search-radio__content">
                        可抢占 {{ canGrabCount }}
                    </span>
                </span>
            </label>

            <table-title
                class="talent-table-title"
                :title-store="titleStore"
                :table-title="tableTitle"
                @table-title="handleTableTitle"
            ></table-title>
        </div>

        <div class="float-center">
            <div class="select-tag-list">
                <!-- :class="{'hot-light': isLatest(item)}" -->
                <div class="select-tag"  
                    v-for="( item,tag ) in orderLabelShowList" 
                    :key="tag" 
                    v-show="orderLabelShowList.length > 0">
                    <el-tooltip placement="top-start" effect="light" popper-class="tooltip-label">
                        <div slot="content">{{ labelList[item] | labelsTip }}</div>
                        <div class="text-ellipsis">
                            <span>{{ searchParamTitle[item] ? `${searchParamTitle[item]}：` : '' }} </span>
                            <span class="content" v-for="(param, index) in labelList[item]" :key="index">
                                {{ param.text }}
                                <span v-if="labelList[item].length > 0 && index !== (labelList[item].length - 1)"> , </span>
                            </span>
                        </div>
                    </el-tooltip>
                    <span class="btn-close" @click="handleCleanLabel(item, true)"></span>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import TableTitle from '#/component/common/tableTitle.vue';
    const _aggregationType = {
        1: 'workExperienceCompaniesName',
        2: 'citys',
        5: 'degree',
        6: 'school',
        8: 'yearOfExperience',
        15: 'latestOperate',
        16: 'addTrackingListSource',
    };

    export default {
        name: "searchLabel",
        componentName: "searchLabel",
        data (){
            return {
                searchParamTitle: {
                    bubble: '',
                    city: '城市',
                    title: '职位',
                    companyName: '全部公司',
                    mobile: '手机',
                    yearOfExperience: '工作年限',
                    isJobHunting: '求职中的人',
                    isGrab: '可抢占',
                    school: '学校',
                    inquiryLogContent: '寻访内容',
                    keyWords: '关键词',
                    realName: '姓名',
                    degree: '学历',
                    email: '邮箱',
                    label: '标签',
                    workExperienceCompaniesName: '公司（筛选）',
                    latestOperate: '最近操作',
                    citys: '城市（筛选）',
                    addTrackingListSource: '加入渠道'
                },
            }
        },
        components:{
            TableTitle
        },
        props: {
            tableType: String,
            searchParams: {},
            orderLabelShowList: {
                default: [],
                type: Array,
            },
            count: {
                default: 0,
                type: Number,
            },
            jobHuntingCount: {
                default: 0,
                type: Number
            },
            canGrabCount: {
                default: 0,
                type: Number,
            },
            labelList : {
                // labelList 内标准格式为{
                //     text: 'xxxx',
                //     .......
                // }
                bubble: [],
                title: [],
                city: [],
                companyName: [],
                yearOfExperience: [],
                isJobHunting: [],
                isGrab: [],
                school: [],
                degree: [],
                keyWords: [],
                label: [],
                email: [],
                mobile: [],
                realName: [],
                inquiryLogContent: [],
                workExperienceCompaniesName: [],
                latestOperate: [],
                addTrackingListSource: []
            },
            titleStore: {
                type: Array,
                default: []
            },
            tableTitle: {
                type: Array,
                default: []
            },
            // currentAggregationType: {
            //     type: Number,
            //     default: -1,
            // }
        },
        created() {
            window.searchLabel = this;
        },
        filters: {
            labelsTip(labels) {
                let tip =[];
                labels.forEach(item => {
                    tip.push(item.text)
                });
                return tip.join(' , ');
            }
        },
        methods: {
            /**
             * 清除标签
             * @param label 标签名
             * @param isEmit 是否广播
             */
            handleCleanLabel (label, isEmit) {
                isEmit = isEmit || false;
                this.$emit('handleCleanLabel', label, isEmit);
            },
            handleAddLabel (label, params, isCover) {
                isCover = isCover || false;
                this.$emit('handleAddLabel',label,params,isCover);
            },
            handleTableTitle(val){
                this.$emit('table-title',val);
            },
            // isLatest(label) {
            //     if(this.currentAggregationType <0) {
            //         return false;
            //     }
            //     return _aggregationType[this.currentAggregationType] == label;
            // }
        },
        watch: {
            'searchParams.isJobHunting' : function (value) {
                if(value) {
                    this.handleAddLabel ('isJobHunting', {
                        text: '是'
                    })
                    _tracker.track(this.tableType === 'talentPool' ? 'TalentPoolIsJobHuntingButtonClick' : 'TrackingListIsJobHuntingButtonClick');
                } else {
                    this.handleCleanLabel('isJobHunting', true);
                    this.isJobHunting = false;
                }
            },
            'searchParams.isGrab' : function (value) {
                if(value) {
                    this.handleAddLabel ('isGrab', {
                        text: '是'
                    })

                    _tracker.track(this.tableType === 'talentPool' ? 'TalentPoolCanOccupyButtonClick' : 'TrackingListCanOccupyButtonClick');
                } else {
                    this.handleCleanLabel('isGrab', true);
                    this.isJobHunting = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .color-orange{
        color: $orange;
    }

    .search-label{
        background: white;
        border-radius: 4px;

        margin: 6px 20px;
        padding: 0 16px;
        line-height: 44px;
        .float-left{
            float: left;
        }

        .float-right{
            float: right;

            /deep/ label{
                margin-right: 20px;
                color: #999;
            }
        }

        .search-radio{
            transition: .3s;
            user-select: none;
            .search-radio__inner {
                position: relative;
                display: inline-block;
                margin-right: 6px;
                width: 14px;
                height: 14px;
                vertical-align: middle;
                border: 1px solid #999999;
                border-radius: 7px;
            }

            .search-radio__content{
                display: inline-block;
            }

            &:hover, .search-radio__input:checked + .search-radio__container{
                .search-radio__inner{
                    border-color: $primary;
                }

                .search-radio__content{
                    color: $primary;
                }
            }

            .search-radio__input:checked + .search-radio__container {
                .search-radio__inner:before{
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: $primary;
                }
            }
        }
    }

    .select-tag-list {
        display: block;
        padding: 10px 0 5px 10px;
        overflow: hidden;
        .select-tag {
            display: inline-block;
            position: relative;
            float: left;
            margin-right: 10px;
            margin-bottom: 5px;
            padding: 0 28px 0 8px;
            max-width: 486px;
            height: 24px;
            line-height: 24px;
            color: $primary;
            background: white;
            border: 1px solid $primary;
            border-radius: 4px;
            font-size: 14px;

            &:last-child {
                margin-right: 0;
            }

            .btn-close {
                float: right;
                position: absolute;
                right: 10px;
                top: 0;

                &:after {

                    display: inline-block;
                    content: '+';
                    float: right;
                    width: 14px;
                    height: 24px;
                    transform: rotate(-45deg);
                    font-size: 20px;

                    text-align: center;
                    line-height: 22px;
                    cursor: pointer;
                    color: $primary;
                }

                &:hover {
                    color: $primary;
                }
            }
        }
        .hot-light{
            border: 1px solid #38BC9D;
        }
    }
</style>

<style lang="scss">
    .el-tooltip__popper.tooltip-label.is-light{
        max-width: 480px;
    }
</style>
