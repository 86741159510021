var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "call-phone-btn", on: { click: _vm.handleCall } },
    [
      _vm.isCalling
        ? _c("span", { staticClass: "calling-gif" })
        : _c("font-icon", {
            staticClass: "table-body-icon-big",
            class: { disabled: _vm.disabled },
            attrs: { href: "#icon-talent_phone" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }